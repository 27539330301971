import * as React from "react";
import Table from "../../../components/table/table";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { setProductTransactions } from "../../../store/productTransactionSlice";
import moment from "moment";

const columnVisibilityModel = {
};

const columns = [
  {
    field: `sku`,
    headerName: `SKU`,
    flex: 1.2,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: false,
    filterable: true,
  },
  {
    field: `column_altered`,
    headerName: `Field Updated`,
    flex: 1.1,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: false,
    filterable: true,
  },
  {
    field: `district`,
    headerName: `District`,
    flex: 1,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: false,
    filterable: true,
  },
  {
    field: `franchise_name`,
    headerName: `Franshise`,
    flex: 1,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: false,
    filterable: true,
  },
  {
    field: `previous_value`,
    headerName: `Previous Value`,
    flex: 1,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: false,
    filterable: true,
  },
  {
    field: `current_value`,
    headerName: `Updated Value`,
    flex: 1,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: false,
    filterable: true,
  },
  {
    field: `group_id`,
    type : 'number',
    headerName: `Group ID`,
    flex: 0.5,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: `alter_by`,
    headerName: `Updated By`,
    flex: 0.8,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: false,
    filterable: true,
  },
  {
    field: `alter_at`,
    headerName: `Updated At`,
    flex: 1.2,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: true,
    filterable: true,
    renderCell: (params) =>
      moment(params?.row?.alter_at).format("MMM DD, YYYY h:mm:ss A"),
  },
];

const ProductTransactions = () => {
  const productTransactions = useSelector((state) => state.productTransactions);

  return (
    <Fragment>

      <div>
        <Table
          rowId={"id"}
          columns={columns}
          dataPosition={"fetchedProductTransactions"}
          endpoint={`/product/fetchProductsHistory`}
          checkBoxSelection={false}
          service={"productTransactions"}
          columnVisibilityModel={columnVisibilityModel}
          tabName={"Product Transactions"}
          setData={setProductTransactions}
          data={productTransactions}
          version="version-3"
          mobileResponsive={true}
          exportEndpoint={'/exports/productTransactions'}
          exportFileName={`Product Transactions - ${new Date()}`}
          delayedExport={true}
          redirectUrlOnSnackBarClick={'/dashboard/reportAnalytics'}
        />
      </div>
    </Fragment>
  );
};

export default ProductTransactions;
