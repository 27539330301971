import {Fragment,useState} from "react";
import Classes from "../css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import { RemoveGstNoPopupProps } from "@/types/component/createAndEditOrder/removeGstNoPopup";
import { CustomerUpdateResponse } from "@/types";


const RemoveGstPopup = (props: RemoveGstNoPopupProps)=> {
    const [selectedGstNo, setSelectedGstNo] = useState<{ id: string }[]>([]);
    const {
        removeGstModal,
        hideRemoveGstPopup,
        showCustomerDetail,
        setCustomerDetail,
        gstNumber,
        setGstNumber
    } = props;
    
    const handleSubmit = (event: { preventDefault: () => void; }) =>{
        event.preventDefault();
        if(selectedGstNo.length === 0) {
            (document.getElementById('selectGstMsg') as HTMLElement).style.display = 'block';
        } else {
            const GstArray = [];
            for (let gst of selectedGstNo) {
                if (gst.id === gstNumber) {
                    setGstNumber(gstNumber);
                }
                GstArray.push(gst.id)
            }


            axiosInstance
                .post<CustomerUpdateResponse>(`/user/admin/${showCustomerDetail?.id}`,{
                    gstNumbers: {remove: GstArray}
                })
                .then((res) => {
                    setCustomerDetail(res.data.data);
                    setSelectedGstNo([])
                    hideRemoveGstPopup();
                });
        }
    }

    const handleGstNo = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            (document.getElementById('selectGstMsg') as HTMLElement).style.display = 'none';
            const array = selectedGstNo;
            array.push({id:event.target.id})
            setSelectedGstNo(array)
        } else {
            const array = selectedGstNo;
            const store = array.filter((gstNo) => gstNo.id !== event.target.id);
            setSelectedGstNo(store)
        }
    }

    return (
        <Fragment>
            {removeGstModal && (
                <div className={Classes.modal} style={{bottom:'40%',top:'16%'}}>
                    <div className={Classes.BoxContent}>
                        <p>GST Number</p>
                    </div>
                    
                    <form method='post' onSubmit={handleSubmit}>
                        <div style={{padding:'10px 20px',overflowY:'auto',height:'168px'}}>
                            {showCustomerDetail && (
                                <div>
                                    {showCustomerDetail?.gst_numbers?.length !== 0 && (
                                        <>
                                            <p 
                                                className={Classes.selectGstNoMsg}
                                                id='selectGstMsg'
                                            >
                                                Please Select GST Number
                                            </p>
                                            {showCustomerDetail?.gst_numbers?.map((gst,index) => (
                                                <div key={index} style={{display:'flex'}}>
                                                    <input 
                                                        type="checkbox" 
                                                        name="" 
                                                        id={gst}
                                                        onChange={handleGstNo} 
                                                    />
                                                    <p style={{marginLeft:5}}>{gst}</p>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className={Classes.DialogBtnBox} style={{bottom:'40%'}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideRemoveGstPopup}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                DELETE
                            </button>
                        </div>
                    </form>

                </div>
            )}
        </Fragment>
    );
}


export default RemoveGstPopup;