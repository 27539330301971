import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    total: 0,
    page: 1,
    maxPage: 1,
    estimates: [],
};

const estimatesSlice = createSlice({
    name: 'estimates',
    initialState: initialState,
    reducers: {
        setEstimates: (state, action) => action.payload,
        reset: (state, action) => initialState,
    },
});

export const { setEstimates, reset } = estimatesSlice.actions;

export default estimatesSlice.reducer;