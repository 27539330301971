const toTitleCase = (string: string): string => {
    string = string?.trim() || "";
    var sentence = string?.toLowerCase().split(" ");

    for (var i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0]?.toUpperCase() + sentence[i].slice(1);
    }

    return sentence.join(" ");
}

const snakeToCamelCase = (snakeCaseString: string): string => {
    snakeCaseString = snakeCaseString?.trim() || "";
    const camelCaseString = snakeCaseString?.toLowerCase().replace(
        /([-_][a-z])/g,
        (group) => {
            return group
                .toUpperCase()
                .replace('-', '')
                .replace('_', '')
        }
    );
    return camelCaseString
}

const snakeToTitleCase = (snakeCaseString: string): string => {
    snakeCaseString = snakeCaseString?.trim() || "";
    var arrayOfLowerCaseStrings = snakeCaseString?.toLowerCase()?.split("_");
    for (var i = 0; i < arrayOfLowerCaseStrings?.length; i++) {
        const word = arrayOfLowerCaseStrings[i]
        const firstLetterOfWord = word?.[0]
        const restPartOfWord = word?.slice(1)
        arrayOfLowerCaseStrings[i] = firstLetterOfWord?.toUpperCase() + restPartOfWord;
    }
    return arrayOfLowerCaseStrings.join(" ");
}

const years: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const dn: Date = new Date();

const createdAt = (createdAt: string | Date): string | null => {
    if (!createdAt) return null;

    const date = new Date(createdAt);
    let createdAtDate =
        date.getFullYear() === dn.getFullYear()
            ? date.getMonth() === dn.getMonth()
                ? date.getDate() === dn.getDate()
                    ? `Today at ${date.toLocaleTimeString()}`
                    : date.getDate() === dn.getDate() - 1
                        ? `Yesterday at ${date.toLocaleTimeString()}`
                        : years[date.getMonth()] +
                        ' ' +
                        date.getDate() +
                        ' at ' +
                        date.toLocaleTimeString()
                : years[date.getMonth()] +
                ' ' +
                date.getDate() +
                ' at ' +
                date.toLocaleTimeString()
            : years[date.getMonth()] + ' ' + date.getFullYear();

    return createdAtDate;
}

export { toTitleCase, snakeToCamelCase, snakeToTitleCase, createdAt }
