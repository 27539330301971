import React, { Fragment } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LocationDialog = (props) => {
    const {
        handleClose,
        open,
        rider
    } = props;

    return (
        <Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{'& .MuiDialog-paper': {maxWidth:'90%'}}}
            >
                <div style={{ display:'flex', padding:'20px 20px 0px 20px', justifyContent:'space-between' }}>
                    <h5 style={{ margin:0 }}>{rider?.deliveryRider}{' '} Current Location</h5>
                    <Button onClick={handleClose}>Close</Button>
                </div>
                <DialogContent style={{width:'90vw'}}>
                    <h4>Location on Map:</h4>
                    <iframe
                        title="Map"
                        src={`https://www.google.com/maps?q=${parseFloat(rider?.current_latitude)},${parseFloat(rider?.current_longitude)}&output=embed`}
                        width="100%"
                        height="600"
                        style={{ boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.15)' }}
                        allowFullScreen
                        loading="lazy"
                    ></iframe>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}

export default LocationDialog;