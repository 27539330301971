
import { Fragment, useState } from 'react';
import Classes from './css/addProduct.module.css';

const isImgUrl = async (url, timeout = 5000) => {
    // Validate the URL format
    try {
        new URL(url);
    } catch {
        return false; // Invalid URL format
    }
  
    const img = new Image();
    img.src = url;
  
    return new Promise((resolve) => {
        const timer = setTimeout(() => {
            img.src = ''; // Stop loading
            resolve(false); // Resolve as false after timeout
        }, timeout);
    
        img.onload = () => {
            clearTimeout(timer); // Clear the timeout on success
            resolve(true); // Image loaded successfully
        };
    
        img.onerror = () => {
            clearTimeout(timer); // Clear the timeout on error
            resolve(false); // Failed to load image
        };
    });
};

const handleUploadImage = async (event, dimension, payloadToUpload, setPayloadToUpload, setIsLoading) => {
    if (event?.target?.value) { 
        setIsLoading((prevState) => ({ ...prevState, [dimension]: true })); // Start loading for this dimension

        try {
            const imageUrl = event.target.value.trim();
            const isValidImage = await isImgUrl(imageUrl);

            if (!event.target.checkValidity() || !isValidImage) {
                alert("Please enter a valid Image URL");
                return;
            }

            const keyName = Object.keys(payloadToUpload?.images?.[dimension] || {}).find(
                (key) => !payloadToUpload.images[dimension][key]
            );

            if (!keyName) {
                alert("No empty slot available to upload the image.");
                return;
            }

            setPayloadToUpload((prevPayload) => ({
                ...prevPayload,
                images: {
                    ...prevPayload?.images,
                    [dimension]: {
                    ...prevPayload?.images?.[dimension],
                    [keyName]: imageUrl,
                    },
                },
            }));

            event.target.value = ''; // Clear the input field
        } catch (error) {
            console.error("Error uploading image:", error);
            alert("An error occurred while uploading the image. Please try again.");
        } finally {
            // setIsLoading(false); // Stop loading
            setIsLoading((prevState) => ({ ...prevState, [dimension]: false })); // Stop loading for this dimension
        }
    }
};
  

const UploadImages = ({dimension, payloadToUpload, setPayloadToUpload, isLoading, setIsLoading}) => {
    return (
        <div>
            <p style={{ fontWeight:400 }}>Upload Url {dimension}</p>
            <input
                type="url"
                id="img"
                name='url'
                autoComplete='off'
                placeholder='Add File Url'
                className={[Classes.imgInput, Classes.ImageUrl].join(' ')}
                onBlur={(event) => handleUploadImage(event, dimension, payloadToUpload, setPayloadToUpload, setIsLoading)}
            />
            {isLoading[dimension] && (
                <div className={Classes.SpinnerContainer}>
                    <div className={Classes.Spinner}></div>
                </div>
            )}
        </div>     
    )
}

const  Media = (props) => {
    const { payloadToUpload, setPayloadToUpload }= props;
    const [isLoading, setIsLoading] = useState({});
    const dimensions = ["400x400", "1200x1200", "150x150"];
    const dimensionColor = {
        '400x400' : 'bisque',
        '1200x1200' : '#d0ffc4',
        '150x150': 'khaki'
    }

	return (
		<Fragment>
            <div className={Classes.Box}>
                <h6 className="mb-3">Media</h6>
                        
                <div>
                    <div className={Classes.ProductImg} id='product' style={{display:'flex',flexWrap:'wrap'}}>
                        {dimensions?.map((dimension,index) => (
                            <div id='smallImage'style={{ display:'flex',flexWrap:'wrap' }} key={index}>
                                {payloadToUpload?.images?.[dimension] && Object.values(payloadToUpload?.images?.[dimension])?.length !== 0 && 
                                Object.entries(payloadToUpload?.images?.[dimension])?.filter(([key, value]) => value)?.map(([key, value]) => (
                                    <div id={key} key={key} className='smallImageDiv'>
                                        <img 
                                            src={value ? value : process.env.REACT_APP_IMAGE_COMMING_SOON}
                                            style={{width:'100%',height:'100%'}}
                                            alt=""
                                        />
                                        <p className={Classes.dimension} style={{ backgroundColor: dimensionColor[dimension]}}>
                                            {dimension} {`- ${value?.split("/")?.slice(-1)[0]?.split(".")?.[0]}`}
                                        </p>
                                        <img 
                                            src="https://cdn-icons-png.flaticon.com/512/1828/1828613.png" 
                                            alt="" className='crossIcon'
                                            onClick={() => {
                                                setPayloadToUpload((prevPayloadToUpload) => {
                                                    return { 
                                                        ...prevPayloadToUpload,
                                                        images: { 
                                                            ...prevPayloadToUpload?.images,
                                                            [dimension]: { ...prevPayloadToUpload?.images?.[dimension], [key]: '' }
                                                        } 
                                                    };
                                                });
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>

                    {dimensions?.map((dimension,index) => (
                        <div key={index}>
                            {payloadToUpload?.images?.[dimension] && Object.entries(payloadToUpload?.images?.[dimension])?.filter(([key, value]) => value)?.length < 8 && (
                                <UploadImages 
                                    dimension={dimension} 
                                    payloadToUpload={payloadToUpload} 
                                    setPayloadToUpload={setPayloadToUpload} 
                                    setIsLoading={setIsLoading} 
                                    isLoading={isLoading} 
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
		</Fragment>
	);
};

export default Media;