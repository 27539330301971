import { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Classes from './css/orders.module.css';
import axiosInstance from '../../../utility/axios-instance';
import { AiFillCloseCircle, AiFillCheckCircle, AiFillStar } from 'react-icons/ai';
import CancelPopup from './cancelPopUp';
import ReturnPopup from './returnPopUp';
import { TbTruckReturn } from 'react-icons/tb';
import TaxRatesPopup from './TaxRates_popup';
import CustomerDetails from './customerDetails';
import ProductContainer from './productContainer';
import { Spinner } from 'react-bootstrap';
import Permission from '../../auth/permissions';
import Button from '@mui/material/Button';
import BulkReturnPopup from './bulkReturnPopup';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import NewCustomerDetails from './newCustomerDetails';
import NewSellerDetails from './newSellerDetails';
import OtherDetails from './otherDetailsPage';
import LocationChangePopup from './locationChangePopup';
import { services } from '../../../utility/checkRoleBasedPermission';
import DocumentsSection from './documentsSection';
import CloseSoPopup from './closeSoPopup';
import SnackBar from '../../../components/SnackBar';
import CapsuleChip from '../../../components/CapsuleChip';
import moment from 'moment';
import Transactions from './transactionsDetails'
import CollectPayment from './collectPayment'
import socket from '../../../socket'
import ChildProductsList from '../createOrders/childProductsList';
import { roundOfUptoXDecimal } from '../../../utility/calculation';
import GenerateOrderDetailLink from './generateOrderDetailLink';
import AlteredAt from '../../../components/AlteredAt';
import EventBusyIcon from '@mui/icons-material/EventBusy';

const OrderDetail = (props) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showTaxRate, setTaxRates] = useState(false);
    const [draftOrder, setDraftOrder] = useState([]);
    const [totalItems, setTotalItems] = useState(0)
    // store deliveryRemark & paymentRemark in State
    const [paymentRemark, setPaymentRemark] = useState('');
    const [deliveryRemark, setDeliveryRemark] = useState('');
    const [cancelPopup, setCancelPopup] = useState(false);
    const [returnPopup, setReturnPopup] = useState(false);
    const [reason, setReason] = useState('Other');
    const [type, setType] = useState('Replacement');
    const [description, setDescription] = useState('');

    // sendExistOrder
    const [updateToggle, setUpdateToggle] = useState(true);
    const [completeExistOrder, setCompleteExistOrder] = useState([]);
    const [groupedFulfilledLineItems, setGroupedFulfilledLineItems] = useState([]);
    const [lineItem, setLineItem] = useState({});
    const [returnPopupLineItem, setReturnPopupLineItem] = useState(false);
    const [bulkReturnPopup, setBulkReturnPopup] = useState(false);
    const [isCloseSoPopupOpen, setIsCloseSoPopupOpen] = useState(false);
    const [alignment, setAlignment] = useState('orderDetails')
    const [locationChangePopup, setLocationChangePopup] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedProductsSO, setSelectedProductsSO] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })


    const showBulkReturnPopup = () => {
        setBulkReturnPopup(true);
    }

    const hideBulkReturnPopup = () => {
        setBulkReturnPopup(false);
    }

    const showCloseSoPopup = () => {
        setIsCloseSoPopupOpen(true);
    }

    const hideCloseSoPopup = () => {
        setSelectedProductsSO([]);
        selectedProductsSO.splice(0, selectedProductsSO?.length);
        setIsCloseSoPopupOpen(false);
    }

    const showLocationChangePopup = () => {
        let products = draftOrder?.OLineItems?.unfulfilledLineItems?.filter((product) => ((product?.closedQty + product?.openQty === product?.orderedQuantity) && (product?.openQty > 0)))
        setSelectedProducts(products.map((product) => ({
            oLineItemId: product.oLineItemId,
            locationCode: product.locationCode,
            checked: false
        })))
        setLocationChangePopup(true);
    }

    const hideLocationChangePopup = () => {
        setLocationChangePopup(false);
    }

    let count = 0;
    const countNumber = (number) => {
        count += 1
        return count;
    }

    const navigate = useNavigate();

    const { id } = useParams();

    const showCancelPopup = () => {
        setCancelPopup(true);
    }

    const hideReturnPopup = () => {
        setReason('Other');
        setType('Replacement');
        setDescription('');
        setLineItem({});
        setReturnPopup(false);
        setReturnPopupLineItem(false);
    }

    const hideCancelPopup = () => {
        setReason('Other');
        setDescription('');
        setCancelPopup(false);
    }

    const countPlaces = (num) => {
        var sep = String(23.32).match(/\D/)[0];
        var b = String(num).split(sep);
        const result =  b[1] ? b[1].length : 0;
        return result === 1 || result === 0 || result === 2 ? num : Math.trunc(num*100)/100;
    }

    const handleReturnOrder = (e, reason, desc, type) => {
        setLoading(true);
        e.target.disabled = true;
        e.target.innerHTML = 'Requesting...';
        const data = {
            "type": type,
            "reason": reason,
            "description": desc
        }
        axiosInstance
            .post(`/pgOrder/admin/returnRequest/${draftOrder.orderName}`, data)
            .then((response) => {
                navigate('/ordersNew')
                setLoading(false);
            })
            .catch((error) => {
                setError(error?.response?.data?.message);
                e.target.innerHTML = 'Confirm';
                e.target.disabled = false;
                setLoading(false);
            })
    }
    const handleCancelOrder = (e, reason, desc) => {
        setLoading(true);
        e.target.disabled = true;
        e.target.innerHTML = 'Cancelling...';
        const data = {
            reason: reason,
            description: desc
        }
        axiosInstance
            .post(`/pgOrder/admin/cancelOrder/${draftOrder.orderName}`, data)
            .then((response) => {
                navigate('/ordersNew')
                setLoading(false);
            })
            .catch((error) => {
                setError(error?.response?.data?.message);
                e.target.innerHTML = 'Confirm';
                e.target.disabled = false;
                setLoading(false);
            })
    }

    const handleReturnLineItem = (e, reason, desc, type) => {
        setLoading(true);
        e.target.disabled = true;
        e.target.innerHTML = 'Requesting...';
        const data = {
            "oLineItemId": lineItem.lineItemId,
            "quantity": lineItem.quantity,
            "fulfillmentName": lineItem.fulfillmentName,
            "type": type,
            "reason": reason,
            "description": desc
        }
        axiosInstance
            .post(`/pgOrder/admin/returnReqLineItemWise/${draftOrder.orderName}`, [data])
            .then((response) => {
                navigate('/ordersNew')
                setLoading(false);
            })
            .catch((error) => {
                setError(error?.response?.data?.message);
                e.target.innerHTML = 'Confirm';
                e.target.disabled = false;
                setLoading(false);
            })
    }

    // Open Window Print
    const handleSendPI = (event) => {
        setLoading(true);
		axiosInstance
			.post(`/pgOrder/admin/downloadPi/${draftOrder?.draftOrderName}`)
			.then((res) => {
				const piPdfUrl = res?.data?.data?.piPdfUrl;
				window.open(piPdfUrl, '_blank');
				// alert('PI Downloaded Successfully');
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			})
    }

    const handleTaxRates = () => {
        setTaxRates(true);
    };

    const hideTaxPopup = () => {
        setTaxRates(false);
    }

    const fetchOrder = async () => {
        if (id) {
            setLoading(true);
            axiosInstance
                .get(`/pgOrder/admin/order/${id}`)
                .then((response) => {
                    let ti = 0;
                    for (
                        let i = 0;
                        i < response?.data?.data?.OLineItems?.fulfilledLineItems?.length;
                        i++
                    ) {
                        ti += response?.data?.data?.OLineItems?.fulfilledLineItems[i]?.orderedQuantity;
                    }
                    for (
                        let i = 0;
                        i < response?.data?.data?.OLineItems?.unfulfilledLineItems?.length;
                        i++
                    ) {
                        ti += response?.data?.data?.OLineItems?.unfulfilledLineItems[i]?.orderedQuantity;
                    }
                    setTotalItems(ti);
                    setDraftOrder(response.data.data)
                    setDeliveryRemark(response.data.data.deliveryRemark);
                    setPaymentRemark(response.data.data.paymentRemark)
                    setCompleteExistOrder([response.data.data.purchaseOrderName]);
                    setGroupedFulfilledLineItems(response.data.data.OLineItems?.fulfilledLineItems.reduce((groups, item) => {
                        const fulfillmentName = item.fulfillmentName;
                        if (!groups[fulfillmentName]) {
                            groups[fulfillmentName] = [];
                        }
                        groups[fulfillmentName].push(item);
                        return groups;
                    }, {}))
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    setError(error?.response?.data?.message);
                });
        }
    }

    useEffect(() => {
        fetchOrder();
    }, [id, updateToggle])

    const handleExport = async (event) => {
        event.preventDefault();
        setLoading(true);

        axiosInstance
            .get(`/exports/order/${id}`)
            .then((response) => {
                const link = document.createElement("a");
                link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(response.data)}`;
                link.download = `${id}.csv`;
                link.click();
                setLoading(false);

            })
            .catch((error) => {
                console.log(error)
                setLoading(false);
            })
    }
    const handleXmlInvoice = async (event) => {
        event.preventDefault();
        setLoading(true);
        axiosInstance
            .get(`/pdf/xmlInvoice/${id}`)
            .then((response) => {
                console.log("response.data ",response.data);
                const link = document.createElement("a");
                link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(response.data)}`;
                link.download = `${id}-Invoice.xml`;
                link.click();
                setLoading(false);

            })
            .catch((error) => {
                console.log(error)
                setLoading(false);
            })
    }

    const handleChange = (event) => {
        setAlignment(event.target.value);
    };

    const lineItemStatusAndQtyTypeMap = {
        "Confirmed": "Open Qty",
        "Allocated": "Committed Qty",
        "Closed": "Closed Qty",
        "Picklist": "Picked Qty",
        "Packed": "Packed Qty",
        "Dispatched": "Shipped Qty",
        "Delivered": "Delivered Qty",
        "On Hold": "On Hold Qty",
        "Cancelled": "Cancelled Qty",
    };

    const onButtonClick = () => {
        // using Java Script method to get PDF file
        axiosInstance
            .get(`/pdf/orderReceipt/${id}`,{
                responseType: 'blob',
                headers: {
                    Accept: 'application/pdf',
                },
            })
            .then((response) => {
                const blob = response.data;
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = `${id}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            });
    };

    useEffect(() => {
        socket?.on(draftOrder?.orderName, (data) => {
            fetchOrder()
        })
    }, [draftOrder])

    return (
        <Fragment>
            {loading ? (
                <div className={Classes.Spinner}>
                    <Spinner animation="border" variant="light" />
                </div>
            ) : null}

            <div className={Classes.container} style={{ maxWidth: '99%' }} id="color">
                <div className={Classes.headerButtons} id='hideHeader'>
                    {draftOrder?.status !== 'Cancelled' && draftOrder?.status !== 'Returned' && (
                        <div style={{ display: 'flex' }}>
                              {(!["Closed", "Cancelled", "Part Delivered & Cancelled","Dispatched", "Delivered"]?.includes(draftOrder?.status)) ? (
                                <div style={{ marginBottom: 5 }}>
                                    <Permission service={services?.orders} permission="delete">
                                        <Button
                                            id='closeSo'
                                            variant="contained"
                                            size='small'
                                            onClick={showCloseSoPopup}
                                            style={{ textTransform: 'capitalize', marginLeft: 5, padding: '5px 8px', fontSize: 13 }}
                                        >
                                            Close So
                                        </Button>
                                    </Permission>
                                </div>
                            ) : null}
                            {(draftOrder?.OPaymentDetail?.paymentStatus !== "Paid" ||
                                draftOrder?.sampleOrder === true) &&
                                ![
                                    "Part Delivered & Cancelled",
                                    "Delivered",
                                    "Closed",
                                ].includes(draftOrder?.status) ?  (
                                <div style={{marginBottom:5}}>
                                    <Permission service={services?.cancels} permission="create">
                                        <Button
                                            id='exportBtn'
                                            color='error'
                                            variant="contained"
                                            size='small'
                                            onClick={showCancelPopup}
                                            style={{ textTransform: 'capitalize', marginLeft: 5, padding: '5px 8px', fontSize: 13 }}
                                        >
                                            Cancel Order
                                        </Button>
                                    </Permission>
                                </div>
                            ) : null}

                            {draftOrder?.status !== "Delivered"
                                && draftOrder?.OLineItems?.unfulfilledLineItems?.filter((product) => {
                                    return ((product?.closedQty + product?.openQty === product?.orderedQuantity) && (product?.openQty > 0))
                                }
                                ).length > 0 && (
                                <div style={{marginBottom:5}}>
                                    <Permission service={services?.orderAllocation} permission="create">
                                        <Button
                                            id='exportBtn'
                                            variant="contained"
                                            size='small'
                                            onClick={showLocationChangePopup}
                                            style={{ textTransform: 'capitalize', marginLeft: 5, padding: '5px 8px', fontSize: 13 }}
                                        >
                                            Update Locations
                                        </Button>
                                    </Permission>
                                </div>
                            )}

                            {draftOrder?.OLineItems?.fulfilledLineItems?.filter((product) => {
                                return ((product?.deliveredQty > 0))
                            }).length > 0 ? (
                                <div style={{marginBottom:5}}>
                                    <Permission service={services?.returns} permission="create">
                                        <Button
                                            id='exportBtn'
                                            variant="contained"
                                            size='small'
                                            onClick={showBulkReturnPopup}
                                            style={{ textTransform: 'capitalize', marginLeft: 5, padding: '5px 8px', fontSize: 13 }}
                                        >
                                            Return Order
                                        </Button>
                                    </Permission>
                                </div>
                            ) : null}

                        </div>
                    )}
                    {['Part Delivered & Cancelled', 'Cancelled', 'Closed'].includes(draftOrder?.status) === false ? (
                        <Permission service={services?.orderAllocation} permission={"create"}>
                            <div style={{marginBottom:5}}>
                                {(draftOrder?.OLineItems?.unfulfilledLineItems?.filter((lineItem)=>{return lineItem?.openQty > 0})?.length > 0) && 
                                (draftOrder?.OLineItems?.fulfilledLineItems?.some(lineItem => lineItem.committedQty > 0)) ?  (
                                    <Button
                                        variant="contained"
                                        size='small'
                                        onClick={() => navigate(`/ordersNew/${id}/Allocate-Un-Allocate`)}
                                        style={{ textTransform: 'capitalize', marginLeft: 5, padding: '5px 8px', fontSize: 13 }}
                                    >
                                        Allocate-Un-Allocate
                                    </Button>
                                ) : (
                                    <>
                                        {(draftOrder?.OLineItems?.unfulfilledLineItems?.filter((lineItem)=>{return lineItem?.openQty > 0})?.length > 0)  ? (
                                            <Button
                                                variant="contained"
                                                size='small'
                                                onClick={() => navigate(`/ordersNew/${id}/Allocate`)}
                                                style={{ textTransform: 'capitalize', marginLeft: 5, padding: '5px 8px', fontSize: 13 }}
                                            >
                                                Allocate
                                            </Button>
                                        ) : (
                                            <>
                                                {(draftOrder?.OLineItems?.fulfilledLineItems?.some(lineItem => lineItem.committedQty > 0)) && (
                                                    <Button
                                                        variant="contained"
                                                        size='small'
                                                        onClick={() => navigate(`/ordersNew/${id}/Un-Allocate`)}
                                                        style={{ textTransform: 'capitalize', marginLeft: 5, padding: '5px 8px', fontSize: 13 }}
                                                    >
                                                        Un-Allocate
                                                    </Button>
                                                )}    
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </Permission>    
                    ) : null}
                    <div style={{marginBottom:5}}>
                        <Button
                            id='exportBtn'
                            variant="outlined"
                            size='small'
                            onClick={handleExport}
                            style={{ textTransform:'capitalize',marginLeft:5,padding:'5px 8px',fontSize:13 }}
                        >
                            Export
                        </Button>
                    </div>
                    <div style={{marginBottom:5}}>
                        <Button
                            id='exportBtn'
                            variant="outlined"
                            size='small'
                            onClick={(e) => handleSendPI(e)}
                            style={{ textTransform:'capitalize',marginLeft:5,padding:'5px 8px',fontSize:13 }}
                        >
                            Print Invoice
                        </Button>
                    </div>
                    <div style={{marginBottom:5}}>
                        <Button
                            id='exportBtn'
                            variant="outlined"
                            size='small'
                            onClick={(e) => onButtonClick(e)}
                            style={{ textTransform:'capitalize',marginLeft:5,padding:'5px 8px',fontSize:13 }}
                        >
                            Print Receipt
                        </Button>
                    </div>
                    <div style={{marginBottom:5}}>
                        <Button
                            id='orderSummary'
                            variant="outlined"
                            size='small'
                            onClick={handleXmlInvoice}
                            style={{ textTransform:'capitalize',marginLeft:5,padding:'5px 8px',fontSize:13 }}
                        >
                            XML INVOICE
                        </Button>
                    </div>
                </div>

                {error ? (
                    <Alert variant="danger" onClose={() => setError(false)} dismissible>
                        <Alert.Heading>{error}</Alert.Heading>
                    </Alert>
                ) : null}

                {draftOrder && (
                    <div className={Classes.draftOrderName} id='orderNamePrint'>
                        <p style={{ textAlign: 'center' }}>Order Summary</p>
                        <div style={{ display: 'flex' }}>
                            <h6 style={{ marginRight: 5 }}>Order Name:-</h6>
                            <p>{draftOrder?.orderName}</p>
                        </div>
                    </div>
                )}

                <CustomerDetails draftOrder={draftOrder} />

                <div className={Classes.mainContainer} style={{ marginTop: 18 }}>
                    <div style={{ display:'flex',justifyContent:'space-between',width:'100%',flexWrap:'wrap' }}>
                        <div style={{ backgroundColor: '#9e9e9e1f', borderRadius: 4, padding: 4 }} id='toggleBtn'>
                            <ToggleButtonGroup
                                color="primary"
                                value={alignment}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                                sx={{
                                    "& .MuiToggleButtonGroup-grouped": { border: '0px', color: 'black', textTransform: 'capitalize', padding: '2px 11px' },
                                    "& .MuiToggleButton-root.Mui-selected": { backgroundColor: '#fff' }
                                }}
                            >
                                <ToggleButton value="orderDetails">Order Details</ToggleButton>
                                <ToggleButton value="customerDetails">Customer Details</ToggleButton>
                                {draftOrder?.OSellerDetails?.length !== 0 && (
                                    <ToggleButton value="sellerDetails">Seller Details</ToggleButton>
                                )}

                                {(draftOrder?.Location?.locationName || draftOrder?.deliveryRemark ||
                                draftOrder?.paymentRemark || draftOrder?.OAffiliateUser?.name ||
                                draftOrder?.OSalesUser?.name) && (
                                    <ToggleButton value="otherDetails">Other Details</ToggleButton>
                                )}
                                
                                {draftOrder.length !== 0 && draftOrder?.documents && ( 
                                    <ToggleButton value="documents">Documents</ToggleButton> 
                                )}

                                <ToggleButton value="transactions">Transactions</ToggleButton> 
                            </ToggleButtonGroup>
                        </div>

                        <div style={{ display: 'flex' }} id='hideHeader'>
                            <AlteredAt 
                                style={{ marginRight: '10px' }}
                                alteredAt={draftOrder?.createdAt}
                                alterType='created'
                            />
                            {
                                draftOrder?.cancelledAt ? 
                                <AlteredAt 
                                    style={{ marginRight: '10px' }}
                                    alteredAt={draftOrder?.cancelledAt} 
                                    alterType='cancelled'
                                    alterIcon={<EventBusyIcon sx={{ color: "#d32f2f", fontSize: "15px" }} />}
                                /> 
                                : null
                            }
                            <p
                                style={{
                                    height: 'fit-content', padding: '1px 10px',
                                    borderRadius: 4, fontWeight: 500, fontSize: 15, margin: 'auto 0px',
                                    backgroundColor: draftOrder.status === 'Completed' ? '#d4edda' : '#FFEA8A'
                                }}
                            >
                                {draftOrder?.status}
                            </p>
                        </div>
                    </div>

                    <div className={Classes.containBoxes} style={{ width: '100%', marginTop: 10 }} id='productContainer'>
                        <div id="showContainer" style={{ display: 'none' }} >
                            <ProductContainer draftOrder={draftOrder} />
                        </div>
                        {alignment === 'orderDetails' && (
                            <>
                                <div className={[Classes.Box, 'box'].join(' ')} id="hideContainer">
                                    {draftOrder.length !== 0 ? (
                                        <div style={{ fontSize: 14, padding: 0 }}>
                                            <div className={Classes.Thead}>
                                                <div
                                                    style={{ padding: '10px 0px 10px 20px', fontWeight: 600 }}
                                                    className={Classes.ProductHeading}
                                                    id='productHeading'
                                                >
                                                    <p>Product</p>
                                                </div>

                                                <div className={Classes.hideResponsive} style={{ width: '8%', padding: '10px 0px' }}>
                                                    <p style={{ fontWeight: 600 }}>Status</p>
                                                </div>

                                                <div
                                                    className={[Classes.hideResponsive, 'discount'].join(' ')}
                                                    style={{ width: '10%', padding: '10px 0px', textAlign: 'center' }}
                                                >
                                                    <p style={{ fontWeight: 600 }}>Discount</p>
                                                    <p style={{ fontSize: 13 }} id='hideInclGst'>(Incl. GST)</p>
                                                </div>

                                                <div className={Classes.hideResponsive} style={{ width: '11%', padding: '10px 0px' }}>
                                                    <p style={{ fontWeight: 600 }}>Order Qty</p>
                                                </div>

                                                <div className={Classes.hideResponsive} style={{ width: '12%', padding: '10px 0px' }}>
                                                    <p style={{ fontWeight: 600 }}>In Process Qty</p>
                                                </div>

                                                <div className={Classes.hideResponsive} style={{ width: '12%', padding: '10px 0px' }}>
                                                    <p style={{ fontWeight: 600 }}>Completed Qty</p>
                                                </div>

                                                <div
                                                    className={Classes.hideResponsive}
                                                    style={{ padding: '10px 0px 10px 0px', width: '9%' }}
                                                >
                                                    <p style={{ fontWeight: 600, paddingLeft: 5 }}>Subtotal</p>
                                                    <p>(Excl. GST)</p>
                                                </div>

                                                <div className={Classes.hideResponsive} style={{ width: '9%', padding: '10px 0px' }}>
                                                    <p style={{ fontWeight: 600, paddingLeft: 5 }}>Total</p>
                                                    <p>(Incl. GST)</p>
                                                </div>
                                            </div>

                                            {draftOrder?.OLineItems?.unfulfilledLineItems.length !== 0 && (
                                                <div
                                                    className={Classes.Thead}
                                                    style={{ padding: '10px 0px 10px 20px', fontWeight: 600, alignItems: 'center', gap: 10, color: 'red' }}
                                                >
                                                    <AiFillCloseCircle />
                                                    <p style={{}}>UnFulfilled Items</p>
                                                </div>
                                            )}

                                            <div id='productContainer'>
                                                {draftOrder?.OLineItems?.unfulfilledLineItems?.map((item, index) => (
                                                    <div
                                                        className={[Classes.containProduct, 'variantProduct'].join(' ')}
                                                        id={item.oLineItemId}
                                                        key={index}
                                                        style={{ padding: '5px 10px', fontSize: 13 }}
                                                    >
                                                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px', color: '#6d7175' }}>#{countNumber(index)}.
                                                        </div>

                                                        <div className={[Classes.productDetail, 'products'].join(' ')} id={index}>
                                                            <div style={{ display: 'flex' }}>
                                                                <img
                                                                    src={item?.mainImageUrl ? item?.mainImageUrl : 'https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png'}
                                                                    alt="icon"
                                                                    className={Classes.productImage}
                                                                />
                                                            </div>

                                                            <div className={Classes.VariantDetail}>
                                                                <div>
                                                                    <p 
                                                                        style={{cursor:'pointer',color: '#0d6efd'}}
                                                                        onClick={() => navigate(`/products/${item?.pgDbProductId}`)}
                                                                        onMouseEnter={(e) => { e.target.style.color = '#ff6200'; }}
                                                                        onMouseLeave={(e) => { e.target.style.color = '#0d6efd'; }}
                                                                    >{item?.variantTitle || item?.productTitle}</p>
                                                                </div>

                                                                <div style={{ display:'flex', color:'#6d7175' }}>
                                                                    <p>{item?.variantType} :</p>
                                                                    <p style={{ marginLeft:5 }}>{item?.variantValue}</p>
                                                                </div>

                                                                <p style={{ color: '#6d7175' }}>{item.sku}</p>

                                                                <div style={{ display: 'flex' }} className={Classes.rateFontSize}>
                                                                    <p className={Classes.newRateExclGst}>Rate Excl. GST</p>
                                                                    <div className={Classes.DiscountedUnitPrice}>
                                                                        <p>&#8377;</p>
                                                                        <p>{item.discountValue !== "0.00" ? item.discountValue : item.originalUnitPrice}</p>
                                                                    </div>
                                                                    <p className={Classes.newCompareAtPrice}>&#8377;{item.mrp}</p>
                                                                </div>

                                                                <div style={{ display: 'flex' }} className={Classes.rateFontSize}>
                                                                    <p className={Classes.newRateExclGst}>Rate Incl. GST</p>
                                                                    <div className={Classes.DiscountedUnitPrice}>
                                                                        <p>&#8377;</p>
                                                                        <p>
                                                                            {roundOfUptoXDecimal(
                                                                                item?.discountedUnitPrice || item?.discountValue * (1 + item?.gstPercentage / 100)
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    <p className={Classes.newCompareAtPrice}>&#8377;{item.mrp}</p>
                                                                </div>

                                                                <div style={{ display: 'flex' }}>
                                                                    <p className={Classes.newRateExclGst}>Location:</p>
                                                                    <p
                                                                        className={Classes.fulfillmentLevelStatus}
                                                                        style={{ width: 'fit-content', fontWeight: "bold", fontSize: 12 }}
                                                                    >{item?.locationCode}</p>
                                                                </div>

                                                                {item?.districtForPrices && <CapsuleChip capsulePart1="District For Prices" capsulePart2={`${item?.districtForPrices}`} chipColor="#243750" />}
                                                                {item?.parentSKU && <CapsuleChip capsulePart1={`Virtual Bundle : ${item?.parentSKU}`} capsulePart2={`${item?.sku} X ${item?.childQtyPerBundle} Quantity`} chipColor={"#ff6200"} />}
                                                                {item?.isParentProduct === true && <ChildProductsList capsulePart1={"Bundle Product"} capsulePart2={`${item?.childProducts?.length} x SKUs`} colorChip={"#ff6200"} childProducts={item?.childProducts}/>}
                                                                
                                                                <div className={Classes.ShowResponsive}>
                                                                    <div className='percent' style={{ fontSize: 14, display: 'flex' }}>
                                                                        <p className={Classes.Text}>Discount Incl. GST:</p>

                                                                        <p>{item?.discountPercentage}%</p>

                                                                        {item.discountTitle !== null && (
                                                                            <div className={[Classes.hideDiscountTitle, 'hideDiscountTitle'].join(' ')}>
                                                                                {item.discountTitle}
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    <div className='qty' style={{ display:'flex' }}>
                                                                        <p className={Classes.Text}>Status:</p>
                                                                        <p 
                                                                            className={Classes.fulfillmentLevelStatus} 
                                                                            style={{ width:'fit-content',fontSize:12 }}
                                                                        >{item?.status}</p>
                                                                    </div>

                                                                    <div className='qty' style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <p><span className={Classes.Text}>Total Qty: </span>{item?.orderedQuantity}</p>
                                                                        {item?.quantity > 0 && <p><span className={Classes.Text}>Open Qty: </span>{item?.quantity}</p>}
                                                                    </div>

                                                                    <div className='total' id='discTotalPrint' style={{ display: 'flex' }}>
                                                                        <p className={Classes.Text}>Total:</p>
                                                                        &#8377; {countPlaces(item?.discountedTotal)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className={[Classes.percent, Classes.Quantity,Classes.Total, 'qty'].join(' ')}
                                                            style={{ width: '8%', justifyContent: 'space-between', paddingRight: 15 }}
                                                        >
                                                            <p
                                                                style={{
                                                                    width: "fit-content", height: "fit-content", padding: "2px 6px",
                                                                    borderRadius: 42, backgroundColor: "#627D98", color: "#fff", fontSize: 13
                                                                }}
                                                            >{item?.status}</p>
                                                        </div>

                                                        <div className={[Classes.percent, Classes.PercentBox, 'percent'].join(' ')} style={{ marginRight: 10, width: '8%' }}>
                                                            <p style={{ display: 'flex', justifyContent: 'center' }}>{item?.discountPercentage}%</p>

                                                            {item.discountTitle !== null && (
                                                                <div
                                                                    className={[Classes.hideDiscountTitle, 'hideDiscountTitle'].join(' ')}
                                                                    style={{ margin: '0px auto' }}
                                                                >
                                                                    {item.discountTitle}
                                                                </div>
                                                            )}

                                                            {item.node?.appliedDiscount !== null && (
                                                                <p className='exclGSTPrint' style={{ fontSize: 12, display: 'none' }}>(Incl. GST)</p>
                                                            )}
                                                        </div>

                                                        <div
                                                            className={[Classes.percent, Classes.Quantity, Classes.Total, 'qty'].join(' ')}
                                                            style={{ width: '9%', flexDirection: 'column', paddingRight: 25 }}
                                                        >
                                                            <p><span style={{ opacity: '0.9', fontSize: 13 }}>Total Qty: </span>{item?.orderedQuantity}</p>
                                                            {item?.quantity > 0 && <p><span className={Classes.Text}>Open Qty: </span>{item?.quantity}</p>}
                                                        </div>

                                                        <div
                                                            className={[Classes.percent, Classes.Quantity, 'qty'].join(' ')}
                                                            style={{ textAlign: 'start', width: '10%', paddingRight: 15 }}
                                                        >
                                                            {[
                                                                { label: 'Open', value: item?.openQty },
                                                                { label: 'Committed', value: item?.committedQty },
                                                                { label: 'Picked', value: item?.pickedQty },
                                                                { label: 'Packed', value: item?.packedQty }
                                                            ]?.map(({ label, value }) => (
                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <p style={{ opacity: '0.9', fontSize: 13 }}>{label}:</p>
                                                                    <p>{value ?? 0}</p>
                                                                </div>
                                                            ))}
                                                        </div>

                                                        <div
                                                            className={[Classes.percent, Classes.Quantity, 'qty'].join(' ')}
                                                            style={{ textAlign: 'start', width: '10%', paddingRight: 15 }}
                                                        >
                                                            {[
                                                                { label: 'Shipped', value: item?.shippedQty },
                                                                { label: 'Delivered', value: item?.deliveredQty },
                                                                { label: 'Returned', value: item?.returnQty },
                                                                { label: 'Closed', value: item?.closedQty }
                                                            ]?.map(({ label, value }) => (
                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <p style={{ opacity: '0.9', fontSize: 13 }}>{label}:</p>
                                                                    <p>{value ?? 0}</p>
                                                                </div>
                                                            ))}
                                                        </div>

                                                        <div
                                                            className={[Classes.percent, 'total'].join(' ')}
                                                            style={{ padding: '10px 8px', width: 80 }}
                                                            id='discTotalPrint'
                                                        >
                                                            &#8377;{parseFloat(item?.subTotal).toFixed(2)}
                                                        </div>

                                                        <div className={[Classes.percent, Classes.Quantity2, 'qty'].join(' ')} style={{ width: 80 }}>
                                                            &#8377;{countPlaces(item?.discountedTotal)}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            {draftOrder?.OLineItems?.fulfilledLineItems.length !== 0 && (
                                                <div
                                                    className={Classes.Thead}
                                                    style={{ padding: '10px 20px', fontWeight: 600, alignItems: 'center', gap: 10, color: 'green' }}
                                                >
                                                    <AiFillCheckCircle />
                                                    <p style={{}}>Fulfilled Items</p>
                                                </div>
                                            )}

                                            <div>
                                                {groupedFulfilledLineItems && Object.keys(groupedFulfilledLineItems)?.map((groupName) => (
                                                    <div key={groupName}>
                                                        <div
                                                            className={Classes.Thead}
                                                            style={{ padding: '10px 20px', fontWeight: 600, justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#243750' }}
                                                        >
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8, color: '#fff' }}>
                                                                <AiFillStar />
                                                                <p>Fulfillment No: {groupName}</p>
                                                            </div>
                                                            <p style={{color:'white'}}>
                                                                Total Price : ₹{groupedFulfilledLineItems[groupName]
                                                                ?.reduce((totalPrice , item ) => {
                                                                    totalPrice += +item?.discountedTotal
                                                                    return +totalPrice
                                                                },[0])?.toFixed(3)
                                                                }
                                                            </p>
                                                        </div>

                                                        <div>
                                                            {groupedFulfilledLineItems[groupName]?.map((item, index) => (
                                                                <div
                                                                    className={[Classes.containProduct, 'variantProduct'].join(' ')}
                                                                    id={item.oLineItemId}
                                                                    key={item.oLineItemId}
                                                                    style={{ padding: '5px 10px', fontSize: 13 }}
                                                                >
                                                                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10, color: '#6d7175' }}>
                                                                        #{countNumber(index)}.
                                                                    </div>

                                                                    <div
                                                                        className={[Classes.productDetail, 'products'].join(' ')}
                                                                        id={item.oLineItemId}
                                                                    >
                                                                        <div>
                                                                            <img
                                                                                src={item?.mainImageUrl ? item?.mainImageUrl : 'https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png'}
                                                                                alt="icon"
                                                                                className={Classes.productImage}
                                                                            />
                                                                        </div>

                                                                        <div className={Classes.VariantDetail}>
                                                                            <div>
                                                                                <p 
                                                                                    style={{cursor:'pointer',color: '#0d6efd'}}
                                                                                    onClick={() => navigate(`/products/${item?.pgDbProductId}`)}
                                                                                    onMouseEnter={(e) => { e.target.style.color = '#ff6200'; }}
                                                                                    onMouseLeave={(e) => { e.target.style.color = '#0d6efd'; }}
                                                                                >{item?.variantTitle || item?.productTitle}</p>
                                                                            </div>

                                                                            <div style={{ display:'flex', color:'#6d7175' }}>
                                                                                <p>{item?.variantType} :</p>
                                                                                <p style={{ marginLeft:5 }}>{item?.variantValue}</p>
                                                                            </div>

                                                                            <p style={{ color: '#6d7175' }}>{item.sku}</p>

                                                                            <div
                                                                                style={{ display: 'flex' }}
                                                                                className={[Classes.rateFontSize, 'rateExcl hidePricePrint'].join(' ')}
                                                                            >
                                                                                <p className={Classes.newRateExclGst}>Rate Excl. GST</p>
                                                                                <p className={Classes.DiscountedUnitPrice}>&#8377;{item.discountValue !== "0.00" ? item.discountValue : item.originalUnitPrice}</p>
                                                                                <p className={Classes.newCompareAtPrice}>&#8377;{item.mrp}</p>
                                                                            </div>

                                                                            <div
                                                                                style={{ display: 'flex' }}
                                                                                className={[Classes.rateFontSize, 'rateExcl hidePricePrint'].join(' ')}
                                                                            >
                                                                                <p className={Classes.newRateExclGst}>Rate Incl. GST</p>
                                                                                <p className={Classes.DiscountedUnitPrice}>&#8377;{roundOfUptoXDecimal(item?.discountedUnitPrice || item?.discountValue * (1 + item?.gstPercentage / 100))}</p>
                                                                                <p className={Classes.newCompareAtPrice}>&#8377;{item.mrp}</p>
                                                                            </div>

                                                                            <div style={{ display: 'flex' }}>
                                                                                <p className={Classes.newRateExclGst}>Location:</p>
                                                                                <p
                                                                                    className={Classes.fulfillmentLevelStatus}
                                                                                    style={{ width: 'fit-content', fontWeight: "bold", fontSize: 12 }}
                                                                                >{item?.locationCode}</p>
                                                                            </div>

                                                                            {item?.districtForPrices && <CapsuleChip capsulePart1="District For Prices" capsulePart2={`${item?.districtForPrices}`} chipColor="#243750" />}
                                                                            {item?.parentSKU && <CapsuleChip capsulePart1={`Virtual Bundle : ${item?.parentSKU}`} capsulePart2={`${item?.sku} X ${item?.childQtyPerBundle} Quantity`} chipColor={"#ff6200"} />}
                                                                            {item?.isParentProduct === true && <ChildProductsList capsulePart1={"Bundle Product"} capsulePart2={`${item?.childProducts?.length} x SKUs`} colorChip={"#ff6200"} childProducts={item?.childProducts}/>}

                                                                            <div style={{ display: 'flex', alignItems: 'center', gap: 5, color: '#6d7175' }}>
                                                                                {item?.totalReturnRequestedQuantity > 0 && (
                                                                                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                                                                        <TbTruckReturn
                                                                                            size={28}
                                                                                            style={{ color: 'gray' }}
                                                                                        />
                                                                                        <p style={{ color: 'gray', fontSize: 11 }}>
                                                                                            {item?.returnClosed ? "Returned" : "Return Requested"}
                                                                                        </p>
                                                                                        <span>: {item?.returnClosed ? item?.returnQty : item?.totalReturnRequestedQuantity - item?.returnQty} Qty</span>
                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                            {/* In screen is smaller than 650px then show this box */}
                                                                            <div className={Classes.ShowResponsive}>
                                                                                <div className='percent' style={{ fontSize: 14, display: 'flex' }}>
                                                                                    <p className={Classes.Text}>Discount Incl. GST:</p>
                                                                                    <p style={{ display: 'flex' }}>%{item?.discountPercentage}</p>

                                                                                    {item.discountTitle !== null && (
                                                                                        <div className={[Classes.hideDiscountTitle, 'hideDiscountTitle'].join(' ')}>
                                                                                            {item.discountTitle}
                                                                                        </div>
                                                                                    )}
                                                                                </div>

                                                                                <div className='qty' style={{ display:'flex' }}>
                                                                                    <p className={Classes.Text}>Status:</p>
                                                                                    <p 
                                                                                        className={Classes.fulfillmentLevelStatus} 
                                                                                        style={{ width:'fit-content',fontSize:12 }}
                                                                                    >{item?.status}</p>
                                                                                </div>

                                                                                <div className='qty' style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                    <p><span className={Classes.Text}>Total Qty: </span>{item?.orderedQuantity}</p>
                                                                                    <p><span className={Classes.Text}>{
                                                                                    lineItemStatusAndQtyTypeMap?.[
                                                                                            ((item?.status === 'Closed' )
                                                                                            && item?.returnClosed) 
                                                                                            ? 'Cancelled' 
                                                                                            : item?.status
                                                                                        ]}: </span>{item?.quantity}</p>
                                                                                </div>

                                                                                <div className='total' id='discTotalPrint' style={{ display: 'flex' }}>
                                                                                    <p className={Classes.Text}>Total:</p>
                                                                                    &#8377;{countPlaces(item?.discountedTotal)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className={[Classes.percent, Classes.Quantity, Classes.Total,'qty'].join(' ')}
                                                                        style={{ width: '8%',justifyContent: 'space-between', paddingRight: 15 }}
                                                                    >
                                                                        <p
                                                                            style={{
                                                                                width: "fit-content", height: "fit-content", padding: "2px 6px",
                                                                                borderRadius: 42, backgroundColor: "#627D98", color: "#fff", fontSize: 13
                                                                            }}
                                                                        >{item?.status}</p>
                                                                    </div>

                                                                    <div className={[Classes.percent, Classes.PercentBox, 'percent'].join(' ')} style={{ width: '10%' }}>
                                                                        <p style={{ display: 'flex', justifyContent: 'center' }}>{item?.discountPercentage}%</p>

                                                                        {item?.discountTitle !== null && (
                                                                            <div
                                                                                className={[Classes.hideDiscountTitle, 'hideDiscountTitle'].join(' ')}
                                                                                style={{ margin: '0px auto' }}
                                                                            >
                                                                                {item.discountTitle === 'SPECIALRETAILER' ? 'SPECIAL_RETAILER' : item.discountTitle}
                                                                            </div>
                                                                        )}

                                                                        {item.node?.appliedDiscount !== null && (
                                                                            <div className='exclGSTPrint' style={{ fontSize: 12, display: 'none' }}>
                                                                                <p>(Incl. GST)</p>
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    <div
                                                                        className={[Classes.percent, Classes.Quantity, Classes.Total, 'qty'].join(' ')}
                                                                        style={{ width: '9%', flexDirection: 'column', paddingRight: 25 }}
                                                                    >
                                                                        <p><span style={{ opacity: '0.9', fontSize: 13 }}>Total Qty: </span>{item?.orderedQuantity}</p>
                                                                        <p><span className={Classes.Text}>{
                                                                        lineItemStatusAndQtyTypeMap?.[
                                                                            ((item?.status === 'Closed' )
                                                                            && item?.returnClosed) 
                                                                                ? 'Cancelled' 
                                                                                : item?.status
                                                                            ]
                                                                        }: </span>{item?.quantity}</p>
                                                                    </div>

                                                                    <div
                                                                        className={[Classes.percent, Classes.Quantity, 'qty'].join(' ')}
                                                                        style={{ textAlign: 'start', width: '10%', paddingRight: 15 }}
                                                                    >
                                                                        {[
                                                                            { label: 'Open', value: item?.openQty },
                                                                            { label: 'Committed', value: item?.committedQty },
                                                                            { label: 'Picked', value: item?.pickedQty },
                                                                            { label: 'Packed', value: item?.packedQty }
                                                                        ]?.map(({ label, value }) => (
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                <p style={{ opacity: '0.9', fontSize: 13 }}>{label}:</p>
                                                                                <p>{value ?? 0}</p>
                                                                            </div>
                                                                        ))}
                                                                    </div>

                                                                    <div
                                                                        className={[Classes.percent, Classes.Quantity, 'qty'].join(' ')}
                                                                        style={{ textAlign: 'start', width: '10%', paddingRight: 15 }}
                                                                    >
                                                                        {[
                                                                            { label: 'Shipped', value: item?.shippedQty },
                                                                            { label: 'Delivered', value: item?.deliveredQty },
                                                                            { label: 'Returned', value: item?.returnQty },
                                                                            { label: 'Closed', value: item?.closedQty }
                                                                        ]?.map(({ label, value }) => (
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                <p style={{ opacity: '0.9', fontSize: 13 }}>{label}:</p>
                                                                                <p>{value ?? 0}</p>
                                                                            </div>
                                                                        ))}
                                                                    </div>

                                                                    <div
                                                                        className={[Classes.percent, 'total'].join(' ')}
                                                                        style={{ padding: '10px 8px', width: 80 }}
                                                                        id='discTotalPrint'
                                                                    >
                                                                        &#8377;{parseFloat(item?.subTotal).toFixed(2)}
                                                                    </div>

                                                                    <div className={[Classes.percent, Classes.Quantity2, 'qty'].join(' ')} style={{ width: 80 }}>
                                                                        &#8377;{countPlaces(item?.discountedTotal)}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>

                                {draftOrder?.length !== 0 && (
                                    <div className={[Classes.Box, 'box'].join(' ')} id='paymentBox'>
                                        <div className={Classes.containerBox}>
                                            <div className="d-flex justify-content-between mb-3">
                                                <h6 style={{ margin: 'auto 0px' }}>
                                                    Payment
                                                </h6>
                                            </div>

                                            <div className={Classes.fontSize}>
                                                <div style={{ marginBottom: '1rem', width: '100%', display: 'flex' }}>
                                                    <p style={{ width: '25%' }}>Payment Mode</p>

                                                    <p style={{ width: '75%', textAlign: 'end' }}>
                                                        {
                                                            draftOrder?.createdVia === "POS" || draftOrder?.createdVia === "Portal"
                                                            ? draftOrder?.Transactions?.map((transaction) => transaction?.payment_mode).join(', ')
                                                            : draftOrder?.OPaymentDetail?.paymentMode
                                                        }
                                                    </p>
                                                </div>

                                                <div style={{ marginBottom: '1rem', width: '100%', display: 'flex' }}>
                                                    <p style={{ width: '25%' }}>Subtotal</p>
                                                    <p style={{ width: '50%' }}>{totalItems}{' '}Items</p>
                                                    <p style={{ width: '25%', textAlign: 'end' }}>
                                                        &#8377;{parseFloat(draftOrder?.OPaymentDetail?.subTotalPrice).toFixed(2)}
                                                    </p>
                                                </div>

                                                <div>
                                                    {draftOrder?.length !== 0 && draftOrder?.OPaymentDetail?.overallDiscountValue && (
                                                        <div style={{ display: 'flex', width: '100%', marginBottom: '1rem' }}>
                                                            <p style={{ width: '25%' }}>Discount</p>
                                                            <p style={{ width:'50%' }}>
                                                                {draftOrder?.OPaymentDetail?.overallDiscountTitle}
                                                            </p>

                                                            <div style={{ width:"25%", display:"flex", justifyContent:"flex-end", gap:"8px" }}>
                                                                <p style={{ width: '25%', textAlign: 'end' }}>
                                                                    {draftOrder?.OPaymentDetail?.overallDiscountPercentage}%
                                                                </p>
                                                                <p style={{ width: '32%', textAlign: 'end' }}>
                                                                    -&#8377;{draftOrder?.OPaymentDetail?.overallDiscountAmount}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {draftOrder?.length !== 0 && draftOrder.shippingCost !== 0 && (
                                                        <div style={{ width: '100%', display: 'flex', marginBottom: '1rem' }}>
                                                            <p style={{ width: '25%' }}>Shipping</p>
                                                            <p style={{ width: '50%' }}>{/* {draftOrder?.shippingLine?.title} */}</p>
                                                            <p style={{ width: '25%', textAlign: 'end' }}>&#8377;{draftOrder?.shippingCost}</p>
                                                        </div>
                                                    )}

                                                    <div style={{ marginBottom: '1rem', display: 'flex' }}>
                                                        <p style={{ width: '25%' }} id='tax'>Tax</p>

                                                        {draftOrder.OTaxLines ? (
                                                            <p
                                                                id='taxRate'
                                                                style={{ color: '#0d6efd', cursor: 'pointer', width: '50%' }}
                                                                onClick={handleTaxRates}
                                                            >
                                                                Show tax rates
                                                            </p>
                                                        ) : (
                                                            <p style={{ width: '50%' }}></p>
                                                        )}

                                                        <p
                                                            id='taxRateAmount'
                                                            style={{ textAlign: 'end', color: '#000', width: '25%' }}
                                                        >
                                                            &#8377;{parseFloat(draftOrder?.OPaymentDetail?.totalTaxPrice).toFixed(2)}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-between" style={{ fontSize: 14, fontWeight: 600 }}>
                                                    <p>Total</p>
                                                    <p>&#8377;{draftOrder?.OPaymentDetail ? Math.round(draftOrder.OPaymentDetail?.totalPrice) : 0.00}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {draftOrder?.length !== 0 && draftOrder.OTaxLines && (
                                    <div id='TaxRatesPrint' className={showTaxRate ? Classes.UpdateSalesDialog : undefined}>
                                        <TaxRatesPopup
                                            showTaxRate={showTaxRate}
                                            setTaxRates={setTaxRates}
                                            handleTaxRates={handleTaxRates}
                                            hideTaxPopup={hideTaxPopup}
                                            draftOrder={draftOrder}
                                            type={"orderNew"}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    {alignment === 'customerDetails' && (
                        <NewCustomerDetails draftOrder={draftOrder} />
                    )}

                    {alignment === 'sellerDetails' && (
                        <NewSellerDetails draftOrder={draftOrder} />
                    )}

                    {alignment === 'otherDetails' && (
                        <OtherDetails draftOrder={draftOrder} />
                    )}

                    {alignment === 'documents' && (
                        <DocumentsSection documents={draftOrder?.documents} relatedTo={draftOrder?.orderName} />
                    )}

                    {alignment === 'transactions' && (
                        <Transactions draftOrder={draftOrder} />
                    )}
                </div>

                <div className={returnPopupLineItem ? Classes.UpdateSalesDialog : undefined}>
                    <ReturnPopup
                        hidePopup={hideReturnPopup}
                        showPopup={returnPopupLineItem}
                        handleReturnOrder={handleReturnLineItem}
                        reason={reason}
                        setReason={setReason}
                        description={description}
                        setDescription={setDescription}
                        type={type}
                        setType={setType}
                    />
                </div>

                <div className={cancelPopup ? Classes.UpdateSalesDialog : undefined}>
                    <CancelPopup
                        hidePopup={hideCancelPopup}
                        showPopup={cancelPopup}
                        handleCancelOrder={handleCancelOrder}
                        reason={reason}
                        setReason={setReason}
                        description={description}
                        setDescription={setDescription}
                    />
                </div>
                <ReturnPopup
                    hidePopup={hideReturnPopup}
                    showPopup={returnPopup}
                    handleReturnOrder={handleReturnOrder}
                    reason={reason}
                    setReason={setReason}
                    description={description}
                    setDescription={setDescription}
                    type={type}
                    setType={setType}
                />
                {draftOrder?.OLineItems?.fulfilledLineItems?.length > 0 && (
                    <BulkReturnPopup
                        onClose={hideBulkReturnPopup}
                        isOpen={bulkReturnPopup}
                        products={draftOrder?.OLineItems?.fulfilledLineItems?.filter((product) => (product?.returnableQty > 0 && product?.status === 'Delivered'))}
                        navigate={navigate}
                        order={draftOrder}
                        setLoading={setLoading}
                    />
                )}
                {
                    draftOrder?.OLineItems && (
                        <CloseSoPopup
                            onClose={hideCloseSoPopup}
                            isOpen={isCloseSoPopupOpen}
                            products={[
                                ...draftOrder?.OLineItems?.fulfilledLineItems,
                                ...draftOrder?.OLineItems?.unfulfilledLineItems,
                            ].filter((product) => {
                                return !((product?.closedQty
                                    + product?.deliveredQty
                                    + product?.returnQty
                                    + product?.shippedQty) === product?.orderedQuantity)
                                    && !["Dispatched", "Delivered", "Cancelled", "On Hold"].includes(product?.status)
                            })}
                            navigate={navigate}
                            order={draftOrder}
                            setLoading={setLoading}
                            setSnackBar={setSnackBar}
                            setReRender={setUpdateToggle}
                        />
                    )}

                    <LocationChangePopup
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        onClose={hideLocationChangePopup}
                        isOpen={locationChangePopup}
                        products={draftOrder?.OLineItems?.unfulfilledLineItems?.filter((product) => ((product?.closedQty + product?.openQty === product?.orderedQuantity) && (product?.openQty > 0)))}
                        order={draftOrder}
                        setLoading={setLoading}
                        setReRender={setUpdateToggle}
                    />

                {draftOrder?.length !== 0 ? <CollectPayment draftOrder={draftOrder} setSnackBar={setSnackBar} /> : null}
                {draftOrder?.length !== 0 ? <GenerateOrderDetailLink draftOrder={draftOrder} setSnackBar={setSnackBar} /> : null}
            </div>

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
};

export default OrderDetail;