import React, {Fragment,useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../css/Modal.module.css";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import ShippingPopUp from "./defaultAddresPopup/shippingAddressPopup";
import axiosInstance from '../../../../utility/axios-instance';


const DefaultAdressPopUp = (props)=> {
    const [shippingAddressModal,setShippingAddressModal] = useState(false);
    const {
        defaultAddressModal,
        hideDefaultAddressPopUp,
        addressArray,
        setAddressArray,
        showCustomerProfile
    } = props;

    const showShippingAddressPopup = () => {
        hideDefaultAddressPopUp();
        setShippingAddressModal(true)
    }

    const hideShippingAddressPopup = () => {
        setShippingAddressModal(false)
    }

    const handleDefaultAddress = (Id) => {

        axiosInstance
            .post(`/user/admin/${showCustomerProfile?.id}/address/${Id}`,{
                default: true
            })
            .then((res) =>{
                axiosInstance
                    .get(`/user/admin/${showCustomerProfile?.id}/address`)
                    .then((response) =>{
                        let addressArr = [];
                        for (let address of response.data.data) {
                            if (address.default === true) {
                                addressArr.unshift(address);
                            } else {
                                addressArr.push(address);
                                setAddressArray(addressArr);
                            }
                        }
                    });
            });
    }

    return (
        <Fragment>
            {defaultAddressModal && (
                <div className={Classes.modal} style={{bottom:'6%'}}>
                    <div className={Classes.Box}>
                        <div className={Classes.BoxContent}>
                            <p>Manage address</p>
                            <div>
                                <FontAwesomeIcon 
                                    icon={faXmark} 
                                    style={{
                                        fontSize: '25px',
                                        color: 'rgb(129, 138, 155)',
                                        cursor: "pointer"
                                    }}
                                    onClick={hideDefaultAddressPopUp}
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div style={{ overflowY:'auto',height:'84%' }}>
                        <div className={Classes.DefaultAddressBox}>
                            <div>
                                <p style={{fontWeight:'600',fontSize:'13px'}}>
                                    DEFAULT ADDRESS 
                                </p>
                            </div>

                            {addressArray.length !== 0 ?   
                                <div style={{ fontSize:'14px' }}>
                                    <div style={{ display:'flex',marginTop:'10px'}}>
                                        <p>{addressArray[0]?.firstName}</p>
                                        {addressArray[0]?.lastName !== 'undefined' && (
                                            <p style={{marginLeft:'5px'}}>
                                                {addressArray[0]?.lastName}
                                            </p>
                                        )}
                                    </div>

                                    <div>
                                        <p>{addressArray[0]?.address1}</p>
                                    </div>

                                    <div>
                                        <p>{addressArray[0]?.address2}</p>
                                    </div>

                                    <div style={{display:'flex'}}>
                                        <p style={{marginRight:'5px'}}>
                                            {addressArray[0]?.zip},
                                        </p>
                                        <p style={{marginRight:'5px'}}>
                                            {addressArray[0]?.city},
                                        </p>
                                        <p>{addressArray[0]?.province}</p>
                                    </div>

                                    <div>
                                        <p>{addressArray[0]?.country}</p>
                                    </div>

                                    <div>
                                        <p>{addressArray[0]?.phone}</p>
                                    </div>

                                </div>
                            : null}
                        </div>

                        {addressArray?.length !== 0 ? 
                            <div>
                                {addressArray?.map((address) => (
                                    <div key={address?._id}>
                                        {address?.default !== true && (
                                            <div className={Classes.AddressBox}>
                                                <div>
                                                    <div style={{display:'flex'}}>
                                                        <p style={{marginRight:'5px'}}>
                                                            {address?.firstName}
                                                        </p>

                                                        {address?.lastName !== 'undefined' 
                                                            ? <p>{address?.lastName}</p>
                                                        : null}
                                                    </div>

                                                    <div>
                                                        <p>{address?.address1}</p>
                                                        {address?.address2 !== null 
                                                            ? <p>{address?.address2}</p>
                                                        : null}
                                                    </div>

                                                    <div style={{display:'flex'}}>
                                                        <p style={{marginRight:'5px'}}>
                                                            {address?.zip},
                                                        </p>
                                                        <p style={{marginRight:'5px'}}>
                                                            {address?.city},
                                                        </p>
                                                        <p>{address?.province}</p>
                                                    </div>

                                                    <div>
                                                        <p>{address?.country}</p>
                                                    </div>

                                                    <div>
                                                        <p>{address?.phone}</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <button
                                                        className={Classes.DefaultBtn}
                                                        onClick={() => handleDefaultAddress(address.id)}
                                                    >
                                                        Make Default
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        : null}
                    </div>
                </div>
            )}
            <div>
                <ShippingPopUp 
                    shippingAddressModal={shippingAddressModal}
                    setShippingAddressModal={setShippingAddressModal}
                    showShippingAddressPopup={showShippingAddressPopup}
                    hideShippingAddressPopup={hideShippingAddressPopup}
                />
            </div>
        </Fragment>
    );
}


export default DefaultAdressPopUp;