import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Classes from "./css/Modal.module.css";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../../utility/axios-instance";
import Classes from "../css/asideModal.module.css";
import CustomerModal from "./newCustomerModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { CustomerAddress, CustomerFetchResponse, CustomersResponse } from "@/types";
import { AsideModalProps } from "@/types/component/createAndEditOrder/asideModal";

const AsideModal = (props:AsideModalProps) => {
  const [customers, setCustomers] = useState<CustomersResponse["data"]["users"]>([]);
  const [showModal, setShowModal] = useState(false);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [toggleBtn, setToggleBtn] = useState(true);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");

  // newCustomer Model
  const [displayName, setDisplayName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [gstNO, setGstNO] = useState<string>("");
  const [num, setNum] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");

  const {
    setDetail,
    searchCustomer,
    toggleButton,
    setToggleButton,
    setPreviousTarget,
    toggle,
    setToggle,
    setAddressArray,
    setSalesUser,
    setPreSalesUser,
    setAffiliateUser,
    estimateUserId,
    setGstNumber,
    fetchCustomerAddresses,
    setSelectedShippingAddress,
    setSelectedBillingAddress
  } = props;

  const getModal = () => {
    setShowModal(true);
    props.onHideModal();
  };

  const hideModal = () => {
    props.onHideModal();
    setDisplayName("");
    setFirstName("");
    setLastName("");
    setBusinessName("");
    setNum("");
    setGstNO("");
    setErrorMessage("");
    setShowModal(false);
    setSelectedState("");
    setCity("");
    setArea("");
  };

  document.addEventListener("mouseup", function (e) {
    let container = document.getElementById("modal");
    if (container !== null) {
      if (!container.contains(e.target as Node)) {
        props.onHideModal();
      }
    }
  });

  const handleCustomer = (customerId:string) => {
    props.onHideModal();
    setToggleBtn(true);
    setCustomerId(customerId);
  };

  useEffect(() => {
    if (estimateUserId) {
      handleCustomer(estimateUserId);
    }
  }, [estimateUserId]);

  useEffect(() => {
    // Runs only once
    if (toggleButton) {
      const debounce = 500
      const timer = setTimeout(() => {
      axiosInstance
        .get<CustomersResponse>(`/user/admin?text=${searchCustomer}&page=${page}&type=createOrder`)
        .then((res) => {
          setMaxPage(res.data.data.maxPage);
          setCustomers(res.data.data.users);
          setToggleButton(false);
        });
      }, debounce)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [toggleButton, searchCustomer, setToggleButton, page]);

  useEffect(() => {
    // Runs only once
    if (toggle) {
      axiosInstance.get(`/user/admin`).then((res) => {
        setCustomers(res.data.data.users);
        setMaxPage(res.data.data.maxPage);
        setToggle(false);
      });
    }
  }, [toggle, setToggle]);

  useEffect(() => {
    // reset page number when search changes
    setPage(1);
  }, [searchCustomer]);

  useEffect(() => {
    if (toggleBtn) {
      if (customerId) {
        axiosInstance.get<CustomerFetchResponse>(`/user/admin/${customerId}`).then((res) => {
          const selectedCustomerData = res?.data?.data;
          setDetail(selectedCustomerData);
          setToggleBtn(false);
          if(selectedCustomerData?.gst_numbers?.length !== 0) {
            setGstNumber(selectedCustomerData?.gst_numbers?.[0])
          }

          if (selectedCustomerData?.affiliate_user) {
            const affiliateUser = {
              label: selectedCustomerData?.affiliate_user_name,
              Id: selectedCustomerData?.affiliate_user,
            };
            setAffiliateUser(affiliateUser);
          }
          if (selectedCustomerData?.field_sales_user) {
            const fieldSalesUser = {
              label: selectedCustomerData?.field_sales_user_name,
              Id: selectedCustomerData?.field_sales_user,
            };
            setSalesUser(fieldSalesUser);
          }
          if (selectedCustomerData?.pre_sales_user) {
            const preSalesUser = {
              label: selectedCustomerData?.pre_sales_user_name,
              Id: selectedCustomerData?.pre_sales_user,
            };
            setPreSalesUser(preSalesUser);
          }
          fetchCustomerAddresses(customerId, "")
          .then((addresses: CustomerAddress[] | undefined)=>{
            if (addresses && addresses?.length !== 0) {
              // setAddressArray(addresses);
              const shippingAddressArrays = [];
              const BillingAddressArrays = [];
      
              for (let address of addresses) {
                if (address.addressType === "shipping") {
                  shippingAddressArrays.push(address);
                } else {
                  BillingAddressArrays.push(address);
                }
              }
      
              if (shippingAddressArrays.length !== 0) {
                const selectedShippingAddress = shippingAddressArrays.find(
                  (item) => item?.default === true
                ) || shippingAddressArrays?.[0];
                const selectedShippingAddressModified = {
                  ...selectedShippingAddress,
                  addressLine1: selectedShippingAddress?.address1,
                  addressLine2: selectedShippingAddress?.address2,
                  pincode: selectedShippingAddress?.zip, 
                  state: selectedShippingAddress?.province, 
                  phoneNumber: selectedShippingAddress?.phone
                }
                setSelectedShippingAddress(selectedShippingAddressModified);
              }
      
              if (BillingAddressArrays.length !== 0) {
                const selectedBillingAddress = BillingAddressArrays.find(
                  (item) => item?.default === true
                ) || BillingAddressArrays[0];
                const selectedBillingAddressModified = {
                  ...selectedBillingAddress,
                  addressLine1: selectedBillingAddress?.address1,
                  addressLine2: selectedBillingAddress?.address2,
                  pincode: selectedBillingAddress?.zip, 
                  state: selectedBillingAddress?.province, 
                  phoneNumber: selectedBillingAddress?.phone
                }
                setSelectedBillingAddress(selectedBillingAddressModified);
              }
            } else {
              setSelectedBillingAddress(null);
              setSelectedShippingAddress(null);
              setAddressArray([]);
            }
            setPreviousTarget([]);
            setToggleBtn(false);
          });
        });
      }
    }
  }, [
    toggleBtn,
    setDetail,
    customerId,
    setSelectedBillingAddress,
    setSelectedShippingAddress,
    setPreviousTarget,
    setAddressArray,
  ]);

  const fetchMoreData = () => {
    if (maxPage > page) {
      setTimeout(() => {
        const newPage = page + 1;
        axiosInstance
          .get<CustomersResponse>(`/user/admin?page=${newPage}&text=${searchCustomer}`)
          .then((res) => {
            setPage(newPage);
            setCustomers((prevCustomers) => [...prevCustomers, ...res.data.data.users]);
          });
      }, 1500);
    }
  };

  return (
    <Fragment>
      {props.showModal && (
        <div className={Classes.modal} id='modal'>
          <div className={Classes.ContactSearch} onClick={() => getModal()}>
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{
                fontSize: "17px",
                paddingRight: "10px",
                color: "rgb(129, 138, 155)",
                paddingTop: "3px",
              }}
            />
            <p>Create a new customer</p>
          </div>
          <div style={{ height: "350px" }}>
            {customers?.length !== 0 && (
              <div>
                <InfiniteScroll
                  dataLength={customers?.length}
                  next={fetchMoreData}
                  hasMore={maxPage > page ? true : false}
                  loader={<p className={Classes.infinitescrollMessage}>Page: {page + 1}, Loading...</p>}
                  endMessage={
                    <p className={Classes.infinitescrollMessage}>
                      Yay! You have seen it all 🎉
                    </p>
                  }
                  scrollableTarget="modal"
                >
                  {customers?.map((customer, index) => (
                    <div
                      key={index}
                      className={Classes.styles}
                      onClick={() => handleCustomer(`${customer?.id}`)}
                    >
                      <div>
                        <div style={{ display: "flex" }}>
                          {customer?.firstName !== "undefined" ? (
                            <p style={{ marginRight: "5px" }}>
                              {customer?.firstName}
                            </p>
                          ) : null}

                          {customer?.lastName && <p>{customer?.lastName}</p>}
                        </div>
                      </div>
                      <div>
                        <p style={{ color: "gray" }}>{customer?.phone}</p>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              </div>
            )}
          </div>
        </div>
      )}

      <div className={showModal ? Classes.UpdateSalesDialog : undefined}>
        <CustomerModal
          onShowModal={showModal}
          onHideModal={hideModal}
          setDetail={setDetail}
          num={num}
          setNum={setNum}
          gstNO={gstNO}
          setGstNO={setGstNO}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          setDisplayName={setDisplayName}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          businessName={businessName}
          setBusinessName={setBusinessName}
          endpoint={`/user/admin`}
          setSelectedState={setSelectedState}
          selectedState={selectedState}
          setCity={setCity}
          city={city}
          setArea={setArea}
          area={area}
        />
      </div>
    </Fragment>
  );
};

export default AsideModal;
