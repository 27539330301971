import { ChangeEvent, SetStateAction, useEffect,useState } from "react"
import Classes from "./css/create.module.css";
import SnackBar from '../../../components/SnackBar';
import axiosInstance from '../../../utility/axios-instance';
import Button from '@mui/material/Button';
import AddNewSeller from './aside/addNewSeller';
import UpdateSeller from './aside/updateSeller';
import AddNewSellerAddress from './aside/addNewSellerBillingAddress';
import UpdateSellerAddress from "./aside/updateSellerBillingAddress";
import { AddNewSellerDetailsProps, SellersResponse } from "@/types";

const NewSellerDetails = (props: AddNewSellerDetailsProps) => {
  const { setSellerBillingAddress, setSeller, seller, sellerBillingAddress } = props;

  const [sellers, setSellers] = useState<SellersResponse["data"] | null>(null);
  const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
  const [open,setOpen] = useState(false);
  const [updateSellerDialog,setUpdateSellerDialog] = useState(false);
  const [sellerAddressDialog,setSellerAddressDialog] = useState(false);
  const [updateSellerAddressDialog,setUpdateSellerAddressDialog] = useState(false);


  const handleSellerSelection = (event: ChangeEvent<HTMLSelectElement>) => {
    const id = event?.currentTarget?.selectedOptions?.[0]?.dataset?.id || event
    if (id === "None") {
      setSeller(null);
      setSellerBillingAddress(null);
    } else {
      const selectedSeller = sellers?.find((seller) => {
        return seller?.id === id
      })
      if(selectedSeller){
        setSeller(selectedSeller)
      }
    }
  }

  const fetchData = async (
    endpoint: string,
    setData: React.Dispatch<SetStateAction<any>>,
    key?: string
  ) => {
    axiosInstance
      .get(endpoint)
      .then((res) => {
        const successMessage = res?.data?.message;
        const responseData = res?.data?.data;
        if (key && key === "address") {
          setData(responseData?.[0]);
        } else {
          setData(responseData);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message === "Seller Address Not Found!") {
          setData(null);
        }
        const errorMessage = error?.response?.data?.message;
        setSnackBar((prevSnackBar) => {
          return {
            ...prevSnackBar,
            display: true,
            message: errorMessage,
            type: "error",
          };
        });
      });
  };


  useEffect(() => {
    fetchData('/seller/fetchAllSellers', setSellers)
  }, [])

  useEffect(() => {
    if (seller) {
      console.log(seller, "Seller abcdefgh");
      fetchData(`/seller/fetchSellerAddresses/${seller?.id}`, setSellerBillingAddress, "address" )
    }
  }, [seller])


  const handleNewSellerDialog = () => {
    setOpen(true);
  }

  const handleCloseNewSellerDialog = () => {
    setOpen(false);
  }

  const handleUpdateSellerDialog = () => {
    setUpdateSellerDialog(true);
  }

  const handleCloseUpdateSellerDialog = () => {
    setUpdateSellerDialog(false);
  }

  const handleNewSellerAddressDialog = () => {
    setSellerAddressDialog(true);
  }

  const handleCloseNewSellerAddressDialog = () => {
    setSellerAddressDialog(false);
  }

  const handleUpdateSellerAddressDialog = () => {
    setUpdateSellerAddressDialog(true);
  }

  const handleCloseUpdateSellerAddressDialog = () => {
    setUpdateSellerAddressDialog(false);
  }


  return <>
    <div className={[Classes.Box, "box"].join(" ")} id='removeSelectAddress'>
      <div className={Classes.containerBox}>
        <div className='mb-2' style={{display:'flex',justifyContent:'space-between'}}>
          <h6 style={{ margin: "auto 0px", marginLeft: 5 }}>Seller</h6>
          <div>
            <Button sx={{textTransform:"capitalize"}} onClick={handleNewSellerDialog}>Add New</Button>
            {seller && (
              <Button sx={{textTransform:"capitalize"}} onClick={handleUpdateSellerDialog}>Edit Seller</Button>
            )}
          </div>
        </div>
        <div className={Classes.fontSize}>
          <select 
            name='seller' 
            key={seller?.sellerName || "None"} 
            defaultValue={seller?.sellerName || "None"} 
            id='seller'
            className={Classes.select} 
            onChange={(e) => handleSellerSelection(e)}
          >
            <option value='DEFAULT' disabled={true}>Select Seller</option>
            {sellers?.map((seller, index) => {
              const { sellerName, id } = seller
              return (
                <option value={sellerName} key={index} data-id={id}>{sellerName}</option>
              )
            })}
            <option value='None' data-id={"None"}>None</option>
          </select>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ margin: "10px 5px" }}>
              <p>{seller?.phoneNumber}</p>
              <p>{seller?.email}</p>
            </div>
          </div>

          {seller && !sellerBillingAddress && (
            <Button sx={{textTransform:"capitalize"}} onClick={handleNewSellerAddressDialog}>Add Address</Button>
          )}
        </div>

        {sellerBillingAddress &&
          <div style={{ borderTop: '1px solid rgb(219, 221, 224)' }}>
            <div style={{ display: "flex", marginTop: '10px', justifyContent: 'space-between' }}>
              <h6 style={{ margin: "0px 5px" }}>{sellerBillingAddress?.addressType} Address</h6>
              <Button sx={{textTransform:"capitalize",padding:'0px 8px'}} onClick={handleUpdateSellerAddressDialog}>Edit Address</Button>
            </div>
            <div style={{ margin: "10px 5px 10px 5px", fontSize: '14px' }}>
              <p>{sellerBillingAddress?.firstName}{" "}{sellerBillingAddress?.lastName}</p>
              <p>{sellerBillingAddress?.addressLine1}</p>
              <p>{sellerBillingAddress?.addressLine2}</p>
              <p>{sellerBillingAddress?.city}</p>
              <p>{sellerBillingAddress?.state}</p>
              <p>{sellerBillingAddress?.pincode}{"  "}{sellerBillingAddress?.country}</p>
              <p>{sellerBillingAddress?.phoneNumber}</p>
            </div>
          </div>
        }

        {seller &&
          <div style={{ borderTop: '1px solid rgb(219, 221, 224)' }}>
            <h6 style={{ margin: "10px 5px" }}>GST Number</h6>
            <div style={{ margin: "10px 5px", fontSize:14 }}>
              {seller?.sellerGstNumbers?.[0] || seller?.sellerGstNumber}
            </div>
          </div>
        }
        <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
      </div>

      <AddNewSeller 
        open={open} 
        handleClose={handleCloseNewSellerDialog} 
        setSeller={setSeller} 
        setSellers={setSellers} 
        fetchData={fetchData}
      />

      <UpdateSeller 
        open={updateSellerDialog} 
        handleClose={handleCloseUpdateSellerDialog} 
        setSeller={setSeller} 
        setSellers={setSellers} 
        fetchData={fetchData}
        seller={seller}
      />

      <AddNewSellerAddress 
        open={sellerAddressDialog} 
        handleClose={handleCloseNewSellerAddressDialog} 
        setSellers={setSellers} 
        fetchData={fetchData}
        seller={seller}
        setSellerBillingAddress={setSellerBillingAddress}
      />

      <UpdateSellerAddress 
        open={updateSellerAddressDialog} 
        handleClose={handleCloseUpdateSellerAddressDialog} 
        setSeller={setSeller} 
        setSellers={setSellers} 
        fetchData={fetchData}
        seller={seller}
        setSellerBillingAddress={setSellerBillingAddress}
        sellerBillingAddress={sellerBillingAddress}
      />
    </div>
  </>
}

export default NewSellerDetails;