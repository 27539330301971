import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide, { SlideProps } from '@mui/material/Slide';
import SnackBar from '../../../../components/SnackBar';
import { TextField } from '@mui/material';
import axiosInstance from '../../../../utility/axios-instance';
import Classes from "../css/Modal.module.css";
import Spinner from '../../../../components/spinner';
import { AddNewSellerAddressProps, CreateSellerAddressResponse, HandleBlurEvent } from '@/types';
import { JSX } from 'react/jsx-runtime';

const Transition = function Transition(props: JSX.IntrinsicAttributes & SlideProps, ref: React.Ref<unknown> | undefined) {
    return <Slide direction="up" ref={ref} {...props}>
        <></>
    </Slide>;
};

const AddNewSellerAddress = (props: AddNewSellerAddressProps)  => {
    const { open, handleClose, fetchData, setSellers,seller, setSellerBillingAddress } = props;


    const [loading,setLoading] = useState(false);
    const [phoneNo,setPhoneNo] = useState('');
    const [pincode,setPincode] = useState('');
    const [selectedState,setSelectedState] = useState('');
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>)=> {
        event.preventDefault();
        setLoading(true);

        const form = event.target as HTMLFormElement;
        const data = {
            addressLine1: form.address1.value,
            addressLine2: form.address2.value,
            city: form.city.value,
            state: form.state.value,
            pincode: form.pincode.value,
            firstName: form.first.value,
            lastName: form.last.value ? form.last.value : undefined,
            phoneNumber: form.phone.value,
            addressType: "Billing",
        }

        axiosInstance
            .post<CreateSellerAddressResponse>(`/seller/sellerAddress/${seller?.id}`, {...data})
            .then((res) => {
                const form = document.getElementById('form') as HTMLFormElement;
                if(form){
                    form.reset();
                }
                fetchData('/seller/fetchAllSellers', setSellers)
                setPhoneNo('');
                handleClose();
                setLoading(false);
                console.log(res.data.data)
                setSellerBillingAddress(res.data.data);
                setSnackBar({ display: true, type: "success", message: res?.data?.message })
            }).catch((err) => {
                setLoading(false);
                setSnackBar({ display: true, type: "error", message: err?.response?.data?.message })
            })
    }

    const handleMinLength = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element> ) => {
        if (event.target.value) {
            const phoneno = /^\d{10}$/;
            if(event.target.value.match(phoneno)){
                (document.getElementById('phoneErrMsg') as HTMLElement).style.display = 'none';
                (document.getElementById('btn') as HTMLButtonElement).disabled = false;
            }else{
                (document.getElementById('btn') as HTMLButtonElement).disabled = true;
                (document.getElementById('phoneErrMsg') as HTMLElement).style.display = 'block';
            }
        } else {
            (document.getElementById('phoneErrMsg') as HTMLElement).style.display = 'none';
            (document.getElementById('btn') as HTMLButtonElement).disabled = false;
        }
    }


    const handleMinLengthPinCode = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        if (event.target.value) {
            const pincode = /^\d{6}$/;
            if (event.target.value.match(pincode)) {
                (document.getElementById('pinErrMsg') as HTMLElement).style.display = 'none';
                if(document.getElementById('addAddress')){
                    (document.getElementById('addAddress') as HTMLButtonElement).disabled = false;
                }
            } else {
                (document.getElementById('addAddress') as HTMLButtonElement).disabled = true;
                if(document.getElementById('pinErrMsg')){
                    ((document.getElementById('pinErrMsg') as HTMLElement) as HTMLElement).style.display = 'block';
                }
            }
        } else {
            (document.getElementById('pinErrMsg') as HTMLElement).style.display = 'none';
            (document.getElementById('addAddress') as HTMLButtonElement).disabled = false;
        }
    }

    async function handlePincodeChange(event: React.ChangeEvent<HTMLInputElement>) {
        const limit = 6;
        const enteredPincode = event.target.value.slice(0, limit)
        setPincode(enteredPincode);
        try {
            const response = await fetch(`https://api.postalpincode.in/pincode/${enteredPincode}`);
            const data = await response.json();

            if (data[0]?.Status === 'Success') {
                const extractedState = data[0]?.PostOffice[0]?.State;
                setSelectedState(extractedState);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const preventNegativeValues = (e:React.KeyboardEvent<HTMLElement>) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
            >
                <DialogTitle>Create Seller Billing Address</DialogTitle>
                <form method="post" onSubmit={handleSubmit} id='form' >
                    <div style={{padding:20}}>
                        <div style={{ display:'flex',justifyContent:'space-between' }}>
                            <div style={{ width:'49%' }}>
                                <TextField 
                                    required={true}
                                    id='first'
                                    name='first'
                                    label='First Name'
                                    autoComplete='off'
                                    size='small'
                                    placeholder='Enter Your First Name'
                                    sx={{ width: '100%'}}
                                />
                            </div>

                            <div style={{ width: '49%' }}>
                                <TextField 
                                    id='last'
                                    name='last'
                                    label='Last Name'
                                    autoComplete='off'
                                    size='small'
                                    placeholder='Enter Your Last Name'
                                    sx={{ width: '100%'}}
                                />
                            </div>
                        </div>

                        <div style={{marginTop:10,display:'flex',justifyContent:'space-between'}}>
                            <div style={{ width:'49%' }}>
                                <TextField 
                                    required={true}
                                    id='address1'
                                    name='address1'
                                    label='Address 1'
                                    autoComplete='off'
                                    size='small'
                                    placeholder='Enter Your Address'
                                    sx={{ width: '100%'}}
                                />
                            </div>

                            <div style={{ width:'49%' }}>
                                <TextField
                                    id='address2'
                                    name='address2'
                                    label='Address 2'
                                    autoComplete='off'
                                    size='small'
                                    placeholder='Apartment,Suite,etc'
                                    sx={{ width: '100%'}}
                                />
                            </div>
                        </div>

                        <div style={{ display:'flex',justifyContent:'space-between',marginTop:10 }}>
                            <div style={{ width: '49%'}}>
                                <TextField 
                                    required={true}
                                    id='city'
                                    name='city'
                                    label='City'
                                    autoComplete='off'
                                    size='small'
                                    placeholder='Enter Your City'
                                    sx={{ width: '100%'}}
                                />
                            </div>

                            <div style={{ width: '49%' }}>
                                <TextField 
                                    type="number"
                                    required={true}
                                    id='pincode'
                                    name='pincode'
                                    label='Pincode'
                                    autoComplete='off'
                                    placeholder='Enter Your Pincode'
                                    // maxLength={6}
                                    sx={{ width: '100%'}}
                                    value={pincode}
                                    size='small'
                                    onBlur={(e) => handleMinLengthPinCode(e)}
                                    onChange={handlePincodeChange}
                                    onKeyDown={preventNegativeValues}
                                />

                                <div className={Classes.PhoneErrorMsg} id='pinErrMsg' style={{ fontSize:14 }}>
                                    <p>Please enter a valid 6 digit pincode</p>
                                </div>
                            </div>
                        </div>

                        <div style={{ display:'flex',justifyContent:'space-between',marginTop:10 }}>
                            <div style={{ width: '49%' }}>
                                <TextField 
                                    required={true}
                                    id='state'
                                    name='state'
                                    label='State'
                                    autoComplete='off'
                                    size='small'
                                    placeholder='Enter Your State'
                                    sx={{ width: '100%'}}
                                />
                            </div>

                            <div style={{ width: '49%' }}>
                                <TextField 
                                    type="number"
                                    id='phone'
                                    name='phone'
                                    label='Phone'
                                    autoComplete='off'
                                    placeholder='Enter Your Phone Number'
                                    // maxLength={10}
                                    sx={{ width: '100%'}}
                                    value={phoneNo}
                                    size='small'
                                    onKeyDown={preventNegativeValues}
                                    onBlur={(e) => handleMinLength(e)}
                                    onChange={(event) => setPhoneNo(event.target.value.slice(0, 10))}
                                />

                                <div
                                    className={Classes.PhoneErrorMsg}
                                    id='phoneErrMsg'
                                >
                                    <p>Please enter a valid 10 digit mobile number</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <DialogActions>
                        <Button onClick={handleClose}>CLOSE</Button>
                        <Button type='submit' id='btn'>CREATE</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

            {loading ? <Spinner /> : null}
        </div>
    );
}

export default AddNewSellerAddress;