import { useState, Fragment, useEffect } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import { Button, Typography } from "@mui/material";
import RiderPopup from './riderPopup';
import RiderUpdatePopup from './updateRider';
import AddIcon from '@mui/icons-material/Add';
import Classes from '../customers/css/customer.module.css';
import FormDialog from '../../../components/FormDialog/FormDialog';
import { useSelector } from 'react-redux';
import { setRiders } from '../../../store/deliveryRidersSlice';
import SnackBar from '../../../components/SnackBar';
import { Chip } from '@mui/material';
import Table from "../../../components/table/table";
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import LocationDialog from './userLocationDialog';

const useAddressFromCoords = (latitude, longitude) => {
    const [address, setAddress] = useState(null);

    useEffect(() => {
        if (latitude && longitude) {
            const fetchAddress = async () => {
                try {
                    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}&accept-language=en`;
                    const response = await fetch(url);
                    const data = await response.json();
                    setAddress(data.display_name || "Address not found");
                } catch (error) {
                    console.error("Error fetching address:", error);
                    setAddress("Error fetching address");
                }
            };
            fetchAddress();
        } else {
            setAddress("N/A");
        }
    }, [latitude, longitude]);

    return address;
};

const DeliveryRiders = (props) => {
    const [showPopup, setShowPopup] = useState(false);
    const [updatePopup, setUpdatePopup] = useState(false);
    const [riderId, setRiderId] = useState(null);
    const [reRender, setReRender] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [open, setOpen] = useState(false);
    const [rider, setRider] = useState(null);

    const deliveryRiders = useSelector((state) => state?.deliveryRiders);

    const showUpdatePopup = (id, row) => {
        setRiderId(id)
        setRider(row);
        setUpdatePopup(true)
    }

    const hideUpdatePopup = () => {
        setUpdatePopup(false)
        setRiderId(null)
        setRider(null);
    }

    const UpdateBtnRenderCell = (row) => {
        return (
            <Button 
                variant='contained' 
                size="small" 
                onClick={(e) => showUpdatePopup(row?.deliveryBoyId, row)}
            >Update</Button>
        )
    }

    const DeleteRenderCell = (row) => {
        return (
            <FormDialog
                dialogTitle="Confirm Delivery Rider Deletion?"
                dialogActionType="confirmation"
                openDialogButtonName="Delete"
                submitDialogButtonName="Delete"
                onConfirmation={() => handleDelete(row?.deliveryBoyId)}
            >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    This action would permanently delete Delivery Rider?
                </Typography>
            </FormDialog>
        )
    }

    const handleClick = (event, latitude, longitude, rider) => {
        if (latitude && longitude) {
            event.stopPropagation();
            setOpen(true);
            setRider(rider);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const CurrentLocationRenderCell = (row) => {
        const latitude = row?.current_latitude ? parseFloat(row?.current_latitude) : '';
        const longitude = row?.current_longitude ? parseFloat(row?.current_longitude) : '';
        const address = useAddressFromCoords(latitude, longitude);
        return (
            <span 
                style={{
                    backgroundColor: '#273952',
                    color: 'white',
                    padding: '2px 10px',
                    fontSize: 13,
                    borderRadius: 14,
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                    cursor: address && address !== 'N/A' && address !== 'Error fetching address' && address !== 'Address not found' ? 'pointer' : 'default'
                }}
                onClick={(e) => handleClick(e,latitude, longitude, row)}
            >
                {address && address !== 'N/A' && address !== 'Error fetching address' && address !== 'Address not found' && (
                    <PersonPinCircleIcon />
                )}
                {address || "Loading..."}
            </span>
        )
    };

    const columns = [
        {
            field: `deliveryBoyId`,
            headerName: `ID`,
            flex: 0.4,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `deliveryRider`,
            headerName: `RIDER`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `currentLocation`,
            headerName: `Current Location`,
            flex: 2,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
            renderCell: (params) => ( <CurrentLocationRenderCell {...params?.row} />),
            customRenderCell: CurrentLocationRenderCell
        },
        {
            field: `phone`,
            headerName: `PHONE`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `email`,
            headerName: `EMAIL`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `locationCode`,
            headerName: `Location Code`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
            renderCell: (params) => (
                params?.value ? <Chip label={params.value} /> : 'N/A'
            )
        },
        {
            field: `createdAt`,
            headerName: `Created At`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: 'Update',
            headerName: 'Update',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <UpdateBtnRenderCell {...params?.row} />),
            customRenderCell: UpdateBtnRenderCell
        },
        {
            field: 'Delete',
            headerName: 'Delete',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <DeleteRenderCell {...params?.row} />),
            customRenderCell: DeleteRenderCell
        }
    ];

    const hidePopup = () => {
        setShowPopup(false);
    }

    const handleDelete = async (id) => {
        await axiosInstance
            .delete(`pgOrder/admin/deleteDeliveryRider/${id}`)
            .then((res) => {
                const successMessage = 'Delivery Rider Deleted Successfully';
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                setReRender(!reRender);
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    return (
        <Fragment>
            <div style={{ display:'flex',width:'99%',justifyContent:'end',flexWrap:'wrap',marginTop:-25, marginBottom:10 }}>
                <Button startIcon={<AddIcon />} variant="contained" onClick={() => setShowPopup(true)}>Add</Button>
            </div>

            <div>
                <Table
                    columns={columns}
                    dataPosition={"fetchedRiders"}
                    endpoint={`/pgOrder/admin/deliveryRiders`}
                    rowId={'deliveryBoyId'}
                    checkBoxSelection={false}
                    version="version-1"
                    reRender={reRender}
                    navigateOnRowClickEndpoint={`/deliveryRiders`}
                    service={'deliveryRiders'}
                    tabName={'Delivery Riders'}
                    setData={setRiders}
                    data={deliveryRiders}
                    mobileResponsive={true}
                    exportEndpoint={'/exports/riders'}
                    exportFileName={`Riders - ${new Date()}`}
                />
            </div>

            <div className={showPopup ? Classes.UpdateSalesDialog : undefined}>
                <RiderPopup
                    showPopup={showPopup}
                    hidePopup={hidePopup}
                    reRender={reRender}
                    setReRender={setReRender}
                    setSnackBar={setSnackBar}
                />
            </div>

            <div className={updatePopup ? Classes.UpdateSalesDialog : undefined}>
                <RiderUpdatePopup
                    showPopup={updatePopup}
                    hidePopup={hideUpdatePopup}
                    reRender={reRender}
                    setReRender={setReRender}
                    riderId={riderId}
                    rider={rider}
                    setRider={setRider}

                />
            </div>

            <LocationDialog 
                open={open}
                handleClose={handleClose}
                rider={rider}
            />

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
};

export default DeliveryRiders;