import React, { useEffect, useState } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField, Button } from '@mui/material';
import axiosInstance from "../../../utility/axios-instance";
import dayjs from "dayjs";
import { useParams } from 'react-router-dom';
import Spinner from '../../../components/spinner';
import SnackBar from '../../../components/SnackBar';

const CreditAgreement = () => {
    const getFormattedDate = (date) => `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    const [date, setDate] = useState(() => getFormattedDate(new Date()));
    const [clientName,setClientName] = useState(null);
    const [registeredOffice,setRegisteredOffice] = useState(null);
    const [creditLimit,setCreditLimit] = useState(null);
    const [creditDays,setCreditDays] = useState(null);
    const [forCustomer,setForCustomer] = useState(null);
    const [signature,setSignature] = useState(null);
    const [customer,setCustomer] = useState(null);
    const [approve, setApprove] = useState(false);
    const [loading,setLoading] = useState(false);
    const [latestPublishedFormResponseData, setLatestPublishedFormResponseData] = useState(null);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const {customerId} = useParams();
    const [publishedFormId, setPublishedFormId] = useState(null);
    const [name, setName] = useState(null);
    const [designation,setDesignation] = useState(null);
    const [arpitSignature,setArpitSignature] = useState(null);

    function insert(str, index, value) {
        if(str && str?.split('-')[1]?.length === 1) {
            return str?.substr(0, index) + value + str.substr(index);
        }
    }

    useEffect(() => {
        if (customerId) {
            axiosInstance
                .get(`/user/admin/${customerId}/pgUser`)
                .then((response) => {
                    setCustomer(response?.data?.data);
                    console.log(response?.data);
                    axiosInstance
                        .get(`/forms/getForm?formName=Credit%20Agreement&issuedToUserId=${response?.data?.data?.id}`)
                        .then(async (res)=> {
                            setPublishedFormId(res?.data?.data?.form?.publishedForm?.formIssuedId)
                            setLatestPublishedFormResponseData(res?.data?.data?.form);
                            const formExist = res?.data?.data?.form?.latestPublishedFormResponseData?.form_response_data;
                            if (formExist) {
                                const date = insert(formExist?.filter((form) => form?.fieldId === 25)?.[0]?.fieldValue, 5, '0');
                                setDate(date);
                                setClientName(formExist?.filter((form) => form?.fieldId === 26)?.[0]?.fieldValue);
                                setRegisteredOffice(formExist?.filter((form) => form?.fieldId === 27)?.[0]?.fieldValue);
                                setCreditLimit(formExist?.filter((form) => form?.fieldId === 28)?.[0]?.fieldValue);
                                setCreditDays(formExist?.filter((form) => form?.fieldId === 29)?.[0]?.fieldValue);
                                setForCustomer(formExist?.filter((form) => form?.fieldId === 31)?.[0]?.fieldValue);
                                setSignature(formExist?.filter((form) => form?.fieldId === 30)?.[0]?.fieldValue);
                                setName(formExist?.filter((form) => form?.fieldId === 32)?.[0]?.fieldValue);
                                setDesignation(formExist?.filter((form) => form?.fieldId === 33)?.[0]?.fieldValue);
                                setApprove(res?.data?.data?.form?.latestPublishedFormResponseData?.form_response_data?.some((item) => item.fieldValue));
                            }
                        })
                })
        }   
    },[customerId])

    const filterId = async (arr, column) => {
        return arr?.filter((field) => field?.fieldName === column)?.[0]?.id;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        await axiosInstance
            .get(`/forms/getForm?formName=Credit%20Agreement&issuedToUserId=${customer?.id}`)
            .then(async (res)=> {
                const agreementId = await filterId(res?.data?.data?.form?.form_fields, 'Agreement Date');
                const clientNameId = await filterId(res?.data?.data?.form?.form_fields, 'Client Name');
                const addressId = await filterId(res?.data?.data?.form?.form_fields, 'Address');
                const creditLimitAmountId = await filterId(res?.data?.data?.form?.form_fields, 'Credit Limit Amount');
                const creditDaysId = await filterId(res?.data?.data?.form?.form_fields, 'Credit Days');
                const forCustomerId =  await filterId(res?.data?.data?.form?.form_fields, 'For Customer');
                const nameId =  await filterId(res?.data?.data?.form?.form_fields, 'Name');
                const designationId =  await filterId(res?.data?.data?.form?.form_fields, 'Designation');
                const data = {
                    formResponseData: {
                        [agreementId]: date ? date : getFormattedDate(new Date()),
                        [clientNameId]: clientName,
                        [addressId]: registeredOffice,
                        [creditLimitAmountId]: creditLimit,
                        [creditDaysId]: creditDays,
                        [forCustomerId]: forCustomer,
                        [nameId]: name,
                        [designationId]: designation
                    },
                    publishedFormId: parseInt(res?.data?.data?.form?.publishedForm?.id),
                    formId: parseInt(res?.data?.data?.form?.id),
                    issuedToUserId: parseInt(customer?.id)
                }

                const formExist = latestPublishedFormResponseData?.latestPublishedFormResponseData?.form_response_data?.some((item) => item.fieldValue)
                const url = formExist ? `/forms/updateCreditAgreement/${publishedFormId}` : `/forms/submitFormResponse`;
                await axiosInstance
                    .post(url, data)
                    .then((response) => {
                        setPublishedFormId(response?.data?.data?.publishedForm?.formIssuedId);
                        setLoading(false);
                        setApprove(true);
                        const successMessage = 'Credit Agreement Created Successfully'
                        setSnackBar((prevSnackBar) => {
                            return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                        });
                    }).catch((error) => {
                        const errorMessage = error?.response?.data?.message
                        setSnackBar((prevSnackBar) => {
                            return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                        });
                        setLoading(false);
                    })
            })
    }
    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    const handleApprove = (event) => {
        // updatePublishedFormStatus
        setLoading(true);
        const data = {        
            publishedFormId: latestPublishedFormResponseData?.publishedForm?.id,
            status: 'Published'
        }
        axiosInstance
            .put('/forms/updatePublishedFormStatus', data)
            .then((response) => {
                latestPublishedFormResponseData.publishedForm.status = 'Published';
                setLoading(false);
                const successMessage = 'Credit Agreement Approved Successfully'
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                })
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
            })
    }

    const handlePdf = () => {
        setLoading(true);
        const data ={
            date,
            clientName,
            address: registeredOffice,
            creditLimit,
            creditDays,
            forCustomer,
            signature,
            name,
            designation
        }
        axiosInstance
            .post(`/pdf/creditAgreement`, data, {
                responseType: 'blob',
                headers: {
                    Accept: 'application/pdf',
                }
            })
            .then((response) => {
                const blob = response.data;
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = `creditAgreement-${clientName}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                setLoading(false);
            })  
    }

    const handleSendCustomer = async () => {
        setLoading(true);
		axiosInstance
			.post(`/forms/sendCreditLink/${customer?.id}?publishedFormId=${publishedFormId}`)
			.then((res) => {
				alert('Send Link Successfully');
			})
			.catch((err) => {
				alert(err?.response?.data?.message || 'Error in Sending PI.. Please try again');
			})
			.finally(() => {
				setLoading(false);
			})
    }

    const handleReject = () => {
        setLoading(true);
        const fieldId = latestPublishedFormResponseData?.latestPublishedFormResponseData?.form_response_data?.filter((fieldData) => fieldData?.fieldId === 30)?.[0]?.id;
        axiosInstance
            .put(`/forms/rejectCreditAgreement/${fieldId}`)
            .then((response) => {
                setLoading(false);
                setSignature(null);
                latestPublishedFormResponseData?.form_response_data?.map((formData) => {
                    if (formData?.fieldId === 30) { formData.fieldValue = null; }
                    return formData;
                })
                const successMessage = 'Credit Agreement Reject Successfully'
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                })
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
            })
    }

    useEffect(() => {
        axiosInstance
            .get(`/forms/signature`)
            .then((res) => {
                setArpitSignature(res?.data?.data);
            })
    }, [])

    return (
        <div style={{padding:40, boxShadow:'0 0 .6152rem rgba(0,0,0,.281)'}}>
            <form action="" method="post" onSubmit={handleSubmit}>
                <div style={{ display:'flex', justifyContent:'space-between',marginTop:10 }}>
                    <h5>CREDIT AGREEMENT</h5>
                    <div style={{display:'flex'}}>
                        {approve ? (
                            <div>
                                <Button variant="contained" type='button' onClick={handlePdf} style={{marginRight:"10px"}}>
                                    PDF
                                </Button>
                                {latestPublishedFormResponseData?.publishedForm?.status === 'Published' && (
                                    <Button 
                                        variant="contained" type='button' 
                                        style={{marginRight:"10px", backgroundColor:'green', color:'#fff'}} 
                                        disabled={true}
                                    >
                                        Approved
                                    </Button>
                                )}
                                {signature && latestPublishedFormResponseData?.publishedForm?.status !== 'Published' && (
                                    <Button variant="contained" type='button' onClick={handleApprove} style={{marginRight:"10px"}}>
                                        Approve
                                    </Button>
                                )}
                                {signature && latestPublishedFormResponseData?.publishedForm?.status !== "Published" &&  (
                                    <Button 
                                        variant="contained" type='button' color="error"  onClick={handleReject}
                                    >
                                        Reject
                                    </Button>
                                )}
                                {!signature && (
                                    <Button variant="contained" type='button' onClick={handleSendCustomer}>
                                        Send Customer
                                    </Button>
                                )}
                            </div>
                        ) : (
                            <Button variant="contained" type='submit'>
                                Generate
                            </Button>
                        )}
                        
                        {
                            (latestPublishedFormResponseData?.latestPublishedFormResponseData
                            ?.form_response_data?.some((item) => item.fieldValue) 
                            && latestPublishedFormResponseData?.publishedForm?.status !== 'Published') && (
                            <Button variant="contained" type='submit' style={{marginLeft:10}}>
                                Update
                            </Button>
                        )}
                    </div>
                </div>
                <div style={{ fontSize:14, marginTop:20, display:'flex' }}>
                    <p style= {{marginTop:10, marginRight:1}}>This Credit Agreement ("Agreement") is entered into on this</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            required
                            label="Date"
                            value={dayjs(date) || ' '}
                            sx={{
                                "& .MuiOutlinedInput-input": { padding: "9.5px 6px", fontSize: '14px' },
                                "& .MuiSvgIcon-root": { fontSize: "18px" },
                                "& .MuiInputLabel-root": { top: date ? "0%" :"-18%" }
                            }}
                            inputFormat="YYYY-MM-DD"
                            size="small"
                            format="YYYY-MM-DD"
                            slotProps={{ textField: { error: false } }}
                            onChange={(newValue) => {setDate(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`)}} 
                            minDate={dayjs(Date.now())}
                        />
                    </LocalizationProvider>
                    <p style={{ marginTop:10, marginLeft:10 }}>between:</p>
                </div>
                <p style={{fontSize:14, marginTop:15 }}>
                    Depo24 Solutions Private Limited, a company providing goods and services, with its
                    registered office at 34D, Pocket-1, Mayur Vihar Phase-1, Delhi-110091 (hereinafter
                    referred to as "Depo24"),
                </p>
                <p style={{ fontSize:14, margin:"15px auto" }}>AND</p>
                <p style={{ fontSize:14 }}>
                    <TextField
                        variant="standard"
                        required
                        id='clientName'
                        name='clientName'
                        type='text'
                        label=''
                        onChange={(e) => setClientName(e.target.value)}
                        value={clientName ? clientName : ""}
                        autoComplete='off'
                        size='small'
                        sx={{
                        width: "20%",
                        "& .MuiInput-input": {fontSize: '14px'},
                        "& .MuiInputLabel-root": {fontSize:'14px'},
                        "& .MuiInput-root": {marginTop:'-8px'},
                        "& .MuiOutlinedInput-input": { padding: "7.5px 14px" },
                        }}
                    />
                    [Client Name], with its registered office at
                    <TextField
                        variant="standard"
                        required
                        id='clientName'
                        name='clientName'
                        type='text'
                        label=''
                        onChange={(e) => setRegisteredOffice(e.target.value)}
                        value={registeredOffice ? registeredOffice : ""}
                        autoComplete='off'
                        size='small'
                        sx={{
                        width: "30%",
                        marginLeft:'10px',
                        "& .MuiInput-input": {fontSize: '14px'},
                        "& .MuiInputLabel-root": {fontSize:'14px'},
                        "& .MuiInput-root": {marginTop:'-8px'},
                        "& .MuiOutlinedInput-input": { padding: "7.5px 14px" },
                        }}
                    /> [address]
                    (hereinafter referred to as the "Client").
                </p>
                <p style={{ fontSize:14, margin:"20px auto" }}>WHEREAS:</p>
                <ol style={{ fontSize:14, margin:"15px auto" }}>
                    <li>Depo24 is engaged in providing goods and services to its clients.</li>
                    <li>The Client desires to obtain credit facilities from Depo24 for the procurement
                        of goods and services.</li>
                </ol>
                <p style={{ fontSize:14, margin:"20px auto" }}>NOW, THEREFORE, IT IS HEREBY AGREED AS FOLLOWS:</p>
                <ol style={{fontSize:14, margin: "15px auto", pageBreakAfter:"auto", pageBreakInside:"avoid"}}>
                    <li style={{ marginBottom: 15 }}>
                        <b>Credit Facility:</b> Depo24 hereby extends credit facilities to the Client for the
                        procurement of goods and services, subject to the terms and conditions of
                        this Agreement.
                    </li>
                    <li style={{ marginBottom:15, pageBreakAfter:"auto", pageBreakInside:"avoid" }}>
                        <b>Credit Limit</b>: The maximum credit limit extended to the Client shall be 
                        <TextField
                            variant="standard"
                            required
                            id='creditLimit'
                            name='creditLimit'
                            type='number'
                            onKeyDown={preventNegativeValues}
                            label=''
                            onChange={(e) => setCreditLimit(e.target.value)}
                            value={creditLimit ? creditLimit : ""}
                            autoComplete='off'
                            size='small'
                            sx={{
                                width: "30%",
                                marginLeft:'10px',
                                "& .MuiInput-input": {fontSize: '14px'},
                                "& .MuiInputLabel-root": {fontSize:'14px'},
                                "& .MuiInput-root": {marginTop:'-8px'},
                                "& .MuiOutlinedInput-input": { padding: "7.5px 14px" },
                            }}
                        />[credit limit amount].
                    </li>
                    <li style={{marginBottom:15, pageBreakAfter:"auto", pageBreakInside:"avoid" }}>
                        <b>Credit Period:</b> The credit period shall be
                        <TextField
                            variant="standard"
                            required
                            id='creditDays'
                            name='creditDays'
                            type='number'
                            onKeyDown={preventNegativeValues}
                            label=''
                            onChange={(e) => setCreditDays(e.target.value)}
                            value={creditDays ? creditDays : ""}
                            autoComplete='off'
                            size='small'
                            sx={{
                                width: "30%",
                                marginLeft:'10px',
                                "& .MuiInput-input": {fontSize: '14px'},
                                "& .MuiInputLabel-root": {fontSize:'14px'},
                                "& .MuiInput-root": {marginTop:'-8px'},
                                "& .MuiOutlinedInput-input": { padding: "7.5px 14px" },
                            }}
                        />[credit days] days from the
                        date of invoice.
                    </li>
                    <li style={{ marginBottom:15, pageBreakAfter:"auto", pageBreakInside:"avoid" }}>
                        <b>Security:</b> As security for the credit extended, the Client shall provide three
                        signed blank cheques in the name of Depo24 Solutions Private Limited.
                        Depo24 shall only utilise these cheques to collect payments due from the
                        Client if payments against the credit extended are not made within the set
                        credit days. Depo24 shall have the right to claim a maximum amount equal to
                        the total outstanding bills due by the Client.
                    </li>
                    <li style={{ marginBottom:15, pageBreakAfter:"auto", pageBreakInside:"avoid" }}>
                        <b>Payment: </b>The Client shall make payment for goods and services provided by
                        Depo24 within the agreed credit period. Failure to make payment within the
                        stipulated time shall authorize Depo24 to utilize the provided cheques for the
                        purpose of recovering the outstanding amount.
                    </li>
                    <li style={{ marginBottom:15, pageBreakAfter:"auto", pageBreakInside:"avoid" }}>
                        <b>Limitation on Cheque Usage: </b>Depo24 shall only use the provided cheques for
                        the purpose of recovering outstanding payments due by the Client. The total
                        amount claimed through these cheques shall not exceed the total outstanding
                        bills due by the Client.                        
                    </li>
                    <li style={{ marginBottom:15, pageBreakAfter:"auto", pageBreakInside:"avoid" }}>
                        <b>Interest: </b>In case of late payment, the Client shall be liable to pay interest at a
                        rate of 18% per annum on the overdue amount.                        
                    </li>
                    <li style={{ marginBottom:15, pageBreakAfter:"auto", pageBreakInside:"avoid" }}>
                        <b>Termination: </b>This Agreement may be terminated by either party upon 45 days'
                        written notice to the other party, provided Client has cleared all the dues of
                        Depo24                                                
                    </li>
                    <li style={{ marginBottom:15, pageBreakAfter:"auto", pageBreakInside:"avoid" }}>
                        <b>Governing Law:</b> This Agreement shall be governed by and construed in
                        accordance with the laws of Delhi,India.
                    </li>
                </ol>
                <p style={{ marginBottom:15,fontSize:14, pageBreakAfter:"auto", pageBreakInside:"avoid" }}>
                    IN WITNESS WHEREOF, the Parties hereto have executed this Agreement as of the
                    date first above written.
                </p>
                <h5 style={{ pageBreakAfter:"auto", pageBreakInside:"avoid" }}>
                    For Depo24 Solutions Private Limited:
                </h5>
                <p style={{ fontSize:14, margin:"20px auto" }}>
                    <img 
                        src={arpitSignature}
                        alt="" srcset=""
                        width='200px'
                    />
                </p>
                <div style={{margin: "30px auto", pageBreakAfter:"auto", pageBreakInside:"avoid" }}>
                    <h5 style={{ margin:0 }}>Arpit Saraf</h5>
                    <h5 style={{ margin:0}}>Director</h5>
                </div>
                <div style={{ pageBreakAfter:"auto", pageBreakInside:"avoid" }}>
                    <div style={{display:'flex'}}>
                        <p style={{ fontSize:14, margin:"15px 0px" }}>For :</p>
                        <TextField
                            variant="standard"
                            required
                            id='forCustomer'
                            name='forCustomer'
                            label=''
                            onChange={(e) => setForCustomer(e.target.value)}
                            value={forCustomer ? forCustomer : ""}
                            autoComplete='off'
                            size='small'
                            style={{marginTop:16}}
                            sx={{
                                width: "30%",
                                marginLeft:'10px',
                                "& .MuiInput-input": {fontSize: '14px'},
                                "& .MuiInputLabel-root": {fontSize:'14px'},
                                "& .MuiInput-root": {marginTop:'-8px'},
                                "& .MuiOutlinedInput-input": { padding: "7.5px 14px" },
                            }}
                        />
                    </div>

                    <div style={{display:'flex'}}>
                        <p style={{ fontSize:14, margin:"15px 0px" }}>Signature:</p>
                        <div>
                            {signature ? (
                                <img 
                                    src={signature ? signature : process.env.REACT_APP_IMAGE_COMMING_SOON}
                                    style={{width:'150px',height:'50px'}}
                                    alt=""
                                />
                            ) : null}
                        </div>
                    </div>
                    <div style={{display:'flex'}}>
                        <p style={{ fontSize:14, margin:"15px 0px" }}>Name :</p>
                        <TextField
                            variant="standard"
                            required
                            id='name'
                            name='name'
                            label=''
                            onChange={(e) => setName(e.target.value)}
                            value={name ? name : ""}
                            autoComplete='off'
                            size='small'
                            style={{marginTop:16}}
                            sx={{
                                width: "30%",
                                marginLeft:'10px',
                                "& .MuiInput-input": {fontSize: '14px'},
                                "& .MuiInputLabel-root": {fontSize:'14px'},
                                "& .MuiInput-root": {marginTop:'-8px'},
                                "& .MuiOutlinedInput-input": { padding: "7.5px 14px" },
                            }}
                        />
                    </div>
                    <div style={{display:'flex'}}>
                        <p style={{ fontSize:14, margin:"15px 0px" }}>Designation :</p>
                        <TextField
                            variant="standard"
                            required
                            id='designation'
                            name='designation'
                            label=''
                            onChange={(e) => setDesignation(e.target.value)}
                            value={designation ? designation : ""}
                            autoComplete='off'
                            size='small'
                            style={{marginTop:16}}
                            sx={{
                                width: "30%",
                                marginLeft:'10px',
                                "& .MuiInput-input": {fontSize: '14px'},
                                "& .MuiInputLabel-root": {fontSize:'14px'},
                                "& .MuiInput-root": {marginTop:'-8px'},
                                "& .MuiOutlinedInput-input": { padding: "7.5px 14px" },
                            }}
                        />
                    </div>
                </div>
            </form>

            {loading ? <Spinner /> : null}

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </div>
    )
};

export default CreditAgreement;