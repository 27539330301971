import { Fragment, useState, useEffect } from 'react';
import Classes from "../createOrders/css/Modal.module.css";
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';
import '../createOrders/css/styles.css';
import Button from '@mui/material/Button';
import SnackBar from '../../../components/SnackBar';
import { TextField, Autocomplete } from '@mui/material';
import { useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';

const OrderAllocation = (props) => {
    const [loading, setLoading] = useState(false);
    const [allocateBins, setAllocateBins] = useState([]);
    const [unAllocateBins,setUnAllocateBins] = useState([]);
    const [orders,setOrders] = useState('');
    const [fulfilledLineItems, setFulfilledLineItems] = useState([]);
    const [unfulfilledLineItems,setUnfulfilledLineItems] = useState([]);
    const [sku, setSku] = useState('');
    const [fulfillmentName,setFulfillmentName] = useState('');
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [isActionInProgress, setIsActionInProgress] = useState(false);
    const { id } = useParams();

    const fetchOrder = async () => {
        if (id) {
            setLoading(true);
            await axiosInstance
                .get(`/pgOrder/admin/order/${id}`)
                .then(async (response) => {
                    const lineItems = [...response?.data?.data?.OLineItems?.fulfilledLineItems,...response?.data?.data?.OLineItems?.unfulfilledLineItems];
                    if (lineItems.length !== 0) {
                        const binsAndQty = [];
                        const deliveryNumbers = [];
                        for (let lineItem of lineItems) {
                            const { locationCode: fulfillmentLocationCode, sku } = lineItem
                            const quantityFilter = !lineItem?.fulfillmentName ? { "field": "availableQtyGt", "value": 0 } : { "field": "committedQtyGt", "value": 0 }
                            const filter = [{ "field": "sku", "operator": "equal", "value": sku },{ "field": "isVirtual", "operator": "equal", "value": false }, { "field": "locationCode", "value": fulfillmentLocationCode }, { "field": "binType", "value": 'Saleable' }, quantityFilter]

                            // make array of all unique deliveryNumbers which in Allocated State
                            if(!(deliveryNumbers.includes(lineItem?.deliveryNumber))) 
                                deliveryNumbers.push(lineItem?.deliveryNumber)

                                await axiosInstance
                                    .get(`/inventory/inventory-view?filter=${encodeURIComponent(JSON.stringify(filter))}&limit=30`)
                                    .then((res) => {
                                        const response = res.data?.data?.fetchedSkuBinMappings;
                                        if (response?.length !== 0) {
                                            for (let ele of response) {
                                                let binQty = !lineItem?.fulfillmentName ? `Avl-Qty ${ele?.availableQty}` : `Cmt-Qty ${ele?.committedQty}`
                                                binsAndQty.push({ bin: `${ele?.binCode}, ${binQty}`, sku: sku, type: !lineItem?.fulfillmentName? 'fulfilled' :'unFulfilled', lineItemId: lineItem?.oLineItemId})
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error, 'error')
                                        const errorMessage = error?.response?.data?.message
                                        setSnackBar((prevSnackBar) => {
                                            return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                                        });
                                    })
                        }

                        const binAndQtyNew=[];
                        for(let deliveryNumber of deliveryNumbers){
                            deliveryNumber  && await axiosInstance
                            .get(`/orderAllocation/fetBinWiseAllocatedQty/${deliveryNumber}`)
                            .then((res) => {
                                const response = res.data?.data?.binWiseAllocatedQtyOfSku;
                                if (response?.length !== 0) {
                                    for (let ele of response) {
                                        let binQty = `Cmt-Qty ${ele?.allocatedQty}`
                                        binAndQtyNew.push({ bin: `${ele?.binCode}, ${binQty}`, sku: ele?.sku, type:'unFulfilled', lineItemId: ele?.lineItemId })
                                    }
                                }
                            })
                            .catch((error) => {
                                const errorMessage = error?.response?.data?.message
                                setSnackBar((prevSnackBar) => {
                                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                                });
                            })
                        }
                        setUnAllocateBins(binAndQtyNew);
                        setAllocateBins(binsAndQty);
                    }

                    setOrders(response?.data?.data);
                    setFulfilledLineItems([...response?.data?.data?.OLineItems?.fulfilledLineItems]);
                    setUnfulfilledLineItems([...response?.data?.data?.OLineItems?.unfulfilledLineItems]);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    // setError(error?.response?.data?.message);
                });
        }
    }

    useEffect(() => {
        fetchOrder();
    }, []);

    const handleSkuBin = (sku, fulfillType, lineItemId) => {
        if (allocateBins?.length !== 0) {
            let filtered = allocateBins?.filter(row => row?.type === fulfillType);
            if (filtered?.length !== 0  && filtered?.some(bin => bin?.sku === sku)) {
                const index = filtered.findIndex(bin => bin?.sku === sku && bin?.lineItemId === lineItemId);
                return filtered[index]?.bin;
            } else {
                return ''
            }
        } else {
            return ''
        }
    }

    const handleBins = (sku, type, lineItemId) => {
        const binsData = allocateBins?.filter(bin => bin?.sku === sku && bin?.lineItemId === lineItemId);
        const binsAndQty = [];
        for (let bin of binsData) {
            if (!binsAndQty?.includes(bin?.bin) && bin?.type === type) {
                binsAndQty.push(bin?.bin)
            }
        }
        return binsAndQty;
    }

    const handleAllocate = (event, sku, qty, locationCode, bins, isChildLineItem, oLineItemId) => {
        setLoading(true);
        const binId = event?.target?.innerText === "Allocate" ? `allocateBin-${oLineItemId}` : `unAllocateBin-${oLineItemId}`;
        const qtyId = event?.target?.innerText === "Allocate" ? `allocateQty-${oLineItemId}` : `unAllocateQty-${oLineItemId}`;
        const bin = document.getElementById(binId);
        const Qty = document.getElementById(qtyId).value;
        const allocationMode = event?.target?.innerText === "Allocate" ? "allocate" : "unallocate"
        if (isActionInProgress) {
            return;
        }
        setIsActionInProgress(true);
        if (!bin?.value) {
            const errorMessage = "Please Select Bin"
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
            });
            return;
        }
        if (!Qty) {
            const errorMessage = "Please Enter Quantity"
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
            });
            return;
        }
        const data = {
            "sku": sku,
            "binCode": bin?.value?.split(',')[0],
            "order_id": JSON.stringify(orders?.orderId),
            "locationCode": locationCode,
            "quantity": Qty ? parseInt(Qty) : qty,
            "mode": allocationMode,
            "isChildLineItem" : isChildLineItem
        }

        const createOrderAllocation = (data) => {
            axiosInstance
                .post(`/orderAllocation/createOrderAllocation`, data)
                .then((res) => {
                    fetchOrder();
                    // setLoading(true);
                    const successMessage = `Order ${data.mode} successfully`
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                    setIsActionInProgress(false);
                    setLoading(false);
                })
                .catch((error) => {
                    event.target.innerText = 'Allocate'
                    const errorMessage = error?.response?.data?.message
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                    });
                    setIsActionInProgress(false);
                    setLoading(false);
                })
        }

        // auto un allocate order based on lineItems
        const autoUnAllocateOrderLineItem = (sku, bins, fulfilledLineItems, locationCode, isChildLineItem) => {
            const skuBins = bins
                ?.map((skuBin) => {
                    return {
                        sku: skuBin?.sku,
                        bin: skuBin?.bin?.split(",")?.[0],
                        committedQty: +skuBin?.bin?.split(",")?.[1]?.replace("Cmt-Qty", '')?.trim(),
                    }
                })
                ?.filter((skuBin) => {
                    return skuBin?.sku === sku
                })
            const fulfilledLineItem = fulfilledLineItems
            const matchingFulfilledLineItem = fulfilledLineItem.find((lineItem) => {
                    return lineItem?.sku === sku;
                })
            const quantity = Qty || matchingFulfilledLineItem?.quantity;
            let unAllocatedQty = 0
            for (let skuBin of skuBins) {
                const remainingQuantity = quantity - unAllocatedQty
                let quantityToBeUnAllocated;
                if (skuBin?.committedQty < remainingQuantity) {
                    quantityToBeUnAllocated = skuBin?.committedQty
                } else if (skuBin?.committedQty >= remainingQuantity) {
                    quantityToBeUnAllocated = remainingQuantity
                }
                if ((skuBin?.committedQty > 0) && (remainingQuantity !== 0)) {
                    unAllocatedQty += quantityToBeUnAllocated
                    const data = {
                        "sku": sku,
                        "locationCode": locationCode,
                        "binCode": skuBin?.bin,
                        "order_id": JSON.stringify(orders?.orderId),
                        "quantity": quantityToBeUnAllocated,
                        "mode": allocationMode,
                        "isChildLineItem": isChildLineItem
                    }
                    createOrderAllocation(data)
                }
            }
        }
        if (data?.mode === 'allocate') {
            createOrderAllocation(data)
        } else if (data?.mode === "unallocate") {
            autoUnAllocateOrderLineItem(sku, bins, fulfilledLineItems, locationCode, isChildLineItem)
        }
    }

    const handleQty = (event, index, sku,fulfillmentName) => {
        const warning = document.getElementById('warning');
        const button = document.getElementById(`button-${sku}`)
        const lineItems = !fulfillmentName ? unfulfilledLineItems[index] : fulfilledLineItems[index];
        if ((!fulfillmentName ? lineItems?.openQty : lineItems?.committedQty) < event.target.value) {
            setSku(sku);
            setFulfillmentName(fulfillmentName)
            if (button) { button.removeAttribute("disabled"); button.disabled = true; }
            warning.style.cssText = 'display:flex !important';
        } else {
            setSku('');
            setFulfillmentName(fulfillmentName)
            if (button) { button.removeAttribute("disabled"); button.disabled = false; }
            warning.style.cssText = 'display:none !important';
        }
    }

    const handleAllocateAll = async () => {
        setLoading(true);
        const unfulfilledItems = unfulfilledLineItems
            ?.filter(order => order?.openQty > 0)
            ?.map((lineItem) => {
                const bin = handleSkuBin(lineItem?.sku, 'fulfilled', lineItem?.oLineItemId);
                return {
                    sku: lineItem?.sku,
                    binCode: bin?.split(',')[0], 
                    order_id: JSON.stringify(orders?.orderId),
                    locationCode: lineItem?.locationCode,
                    quantity: lineItem?.quantity,
                    mode: "allocate",
                    isChildLineItem: lineItem?.parentSKU !== null
                };
            })?.filter(lineItem => lineItem?.binCode);

            await axiosInstance
                .post(`/orderAllocation/createOrderAllocationInBulk`, {data: { lineItems: unfulfilledItems }})
                .then((res) => {
                    fetchOrder();
                    const successMessage = `Order Allocated Successfully`
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                    setIsActionInProgress(false);
                    setLoading(false);
                })
                .catch((error) => {
                    const errorMessage = error?.response?.data?.message
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                    });
                    setIsActionInProgress(false);
                    setLoading(false);
                })
    
    }

    const handleUnAllocateAll = async () => {
        setLoading(true);
        const fulfilledItems = fulfilledLineItems?.filter(order => ['Allocated']?.includes(order?.status));
        const data = fulfilledItems?.map((lineItem) => {
            const bin = unAllocateBins?.find(bin => bin?.sku === lineItem?.sku && bin?.lineItemId === lineItem?.oLineItemId)?.bin
            return {
                sku: lineItem?.sku,
                binCode: bin?.split(',')[0], 
                order_id: JSON.stringify(orders?.orderId),
                locationCode: lineItem?.locationCode,
                quantity: lineItem?.committedQty,
                mode: "unallocate",
                isChildLineItem: lineItem?.parentSKU !== null
            };
        })

        await axiosInstance
            .post(`/orderAllocation/createOrderAllocationInBulk`, {data: { lineItems: data }})
            .then((res) => {
                fetchOrder();
                const successMessage = `Order Un-Allocated Successfully`;
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                setIsActionInProgress(false);
                setLoading(false);
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message;
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setIsActionInProgress(false);
                setLoading(false);
            })
    }

    return (
        <Fragment>
            <div>
                <Alert variant="filled" severity="warning" style={{ margin: 12 }} id='warning' className={Classes.Warning}>
                    {!fulfillmentName ? "Fulfillment" : "Un-Fulfillment"} Quantity Must be less than 
                    {!fulfillmentName ? "Open" : "Committed"} Quantity of this Sku "{sku}"
                </Alert>

                <div style={{ display:'flex',justifyContent:'space-between',flexWrap:'wrap',padding:12 }}>
                    <div style={{ width:'100%',height:'fit-content' }}>
                        {unfulfilledLineItems?.length !== 0 && (
                            <>
                                <div style={{ display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10 }}>
                                    <h6>Allocate</h6>
                                    <Button variant='contained' onClick={handleAllocateAll}>Allocate All</Button>
                                </div>

                                <div style={{marginBottom:20,boxShadow:"0 0 0.3152rem rgba(0,0,0,.181), 0 0 0.3152rem rgba(0,0,0,.181)"}}>
                                    <div style={{display:'flex',padding:'10px 0px',backgroundColor:'#243750',color:'#fff',borderRadius:"4px 4px 0px 0px"}}>
                                        <h6 style={{ width:'6%',padding:"0px 10px",marginBottom:0 }}>
                                            S.No
                                        </h6>
                                        <h6 style={{ width:'25%',marginBottom:0 }}>
                                            Sku
                                        </h6>
                                        <h6 style={{ padding:'0px 5px',width:'10%',marginBottom:0 }}>
                                            Location
                                        </h6>
                                        <h6 style={{ padding:'0px 10px',width:'26%',marginBottom:0 }}>
                                            Bin
                                        </h6>
                                        <h6 style={{ width:'11%',padding:"0px 10px",textAlign:'center',marginBottom:0 }}>
                                            Order Qty
                                        </h6>
                                        <h6 style={{ width:'11%',marginBottom:0 }}>
                                            Fulfill Qty
                                        </h6>
                                        <h6 style={{ width:'11%',marginBottom:0,textAlign:'center' }}>
                                            Allocate
                                        </h6>
                                    </div>

                                    {(unfulfilledLineItems?.filter(order => order?.openQty > 0))?.map((order, index) => (
                                        <div 
                                            key={index} 
                                            style={{display:'flex',padding:'10px 0px',borderBottom:'1px solid lightgray',fontSize: 14 }}
                                        >
                                            <p style={{ padding:"0px 10px",width:'6%',margin:'auto 0px' }}>{index + 1}</p>
                                            <p style={{ width:'25%',margin:'auto 0px',fontWeight:600 }}>{order?.sku}</p>
                                            <p style={{ padding:"0px 5px",width:'10%',margin:'auto 0px' }}>{order?.locationCode}</p>
                                            <div style={{ padding:"0px 10px",width:'26%',margin:'auto 0px' }}>
                                                <Autocomplete
                                                    freeSolo
                                                    disablePortal
                                                    id={`allocateBin-${order?.oLineItemId}`}
                                                    name={`allocateBin-${order?.oLineItemId}`}
                                                    getOptionLabel={(option) => option}
                                                    sx={{"& .MuiAutocomplete-input": {padding:'0px 4px 0px 8px'}}}
                                                    ListboxProps={{ sx: { fontSize: 13 }, padding: '0px' }}
                                                    value={handleSkuBin(order?.sku,'fulfilled', order?.oLineItemId)}
                                                    options={allocateBins?.some(bin => bin?.sku === order?.sku) ? handleBins(order?.sku, 'fulfilled', order?.oLineItemId) : []}
                                                    size="small"
                                                    renderInput={(params) =>
                                                        <TextField {...params} label={"Bin"} required={true}
                                                            // onFocus={() => handleOnFocusSkuBin(order?.sku, order?.locationCode, order?.deliveryNumber,order?.fulfillmentName)}
                                                        />
                                                    }
                                                />
                                            </div>
                                            <p style={{ padding:"0px 10px",textAlign:'center',width:'11%',margin:'auto 0px' }}>
                                                {order?.openQty}
                                            </p>

                                            <div style={{width:'11%',margin:'auto 0px'}}>
                                                <TextField
                                                    required={true}
                                                    id={`allocateQty-${order?.oLineItemId}`}
                                                    name={`allocateQty-${order?.oLineItemId}`}
                                                    label='QTY'
                                                    key={order?.openQty}
                                                    defaultValue={order?.openQty}
                                                    autoComplete='off'
                                                    type='number'
                                                    size='small'
                                                    onBlur={(event) => handleQty(event, index, order?.sku,order?.fulfillmentName)}
                                                />
                                            </div>

                                            <div style={{width:'11%',textAlign:'center',margin:'auto 0px'}}>
                                                <Button
                                                    variant="contained"
                                                    id={`button-${order?.sku}`}
                                                    onClick={(event) => allocateBins?.some(bin => bin?.sku === order?.sku) &&
                                                        handleAllocate(
                                                            event,
                                                            order?.sku,
                                                            order?.quantity,
                                                            order?.locationCode,
                                                            allocateBins,
                                                            order?.parentSKU !== null,
                                                            order?.oLineItemId
                                                        )}
                                                    disabled={(allocateBins?.some(bin => bin?.sku === order?.sku) ? false : true) && isActionInProgress}
                                                    style={{
                                                        textTransform: 'capitalize',
                                                        padding: '2px 6px',
                                                        fontSize: 13,
                                                        cursor: allocateBins?.some(bin => bin?.sku === order?.sku) ? 'pointer' : 'default'
                                                    }}
                                                >
                                                    Allocate
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {fulfilledLineItems?.length !== 0 && (
                            <>
                                <div style={{ display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10 }}>
                                    <h6>Un-Allocate</h6>
                                    <Button variant='contained' onClick={handleUnAllocateAll}>Un-Allocate All</Button>
                                </div>

                                <div style={{boxShadow:"0 0 0.3152rem rgba(0,0,0,.181), 0 0 0.3152rem rgba(0,0,0,.181)"}}>
                                    <div style={{display:'flex',padding:'10px 0px',backgroundColor:'#243750',color:'#fff',borderRadius:"4px 4px 0px 0px"}}>
                                        <h6 style={{ width:'6%',padding:"0px 10px",marginBottom:0 }}>
                                            S.No
                                        </h6>
                                        <h6 style={{ width:'25%',marginBottom:0 }}>
                                            Sku
                                        </h6>
                                        <h6 style={{ padding:'0px 5px',width:'10%',marginBottom:0 }}>
                                            Location
                                        </h6>
                                        <h6 style={{ padding:'0px 10px',width:'26%',marginBottom:0 }}>
                                            Bin
                                        </h6>
                                        <h6 style={{ width:'11%',padding:"0px 10px",textAlign:'center',marginBottom:0 }}>
                                            Order Qty
                                        </h6>
                                        <h6 style={{ width:'11%',marginBottom:0 }}>
                                            Un-Fulfill Qty
                                        </h6>
                                        <h6 style={{ width:'11%',marginBottom:0,textAlign:'center' }}>
                                            Un-Allocate
                                        </h6>
                                    </div>

                                    {fulfilledLineItems?.filter(order => ['Allocated']?.includes(order?.status))?.map((order, index) => (
                                        <div 
                                            key={index} 
                                            style={{display:'flex',padding:'10px 0px',borderBottom:'1px solid lightgray',fontSize:14 }}
                                        >
                                            <p style={{ padding:"0px 10px",width:'6%',margin:'auto 0px' }}>{index + 1}</p>
                                            <p style={{ width:'25%',margin:'auto 0px',fontWeight:600 }}>{order?.sku}</p>
                                            <p style={{ padding:"0px 5px",width:'10%',margin:'auto 0px' }}>{order?.locationCode}</p>
                                            <div style={{ padding:"0px 10px",width:'26%',margin:'auto 0px' }}>
                                                <Autocomplete
                                                    freeSolo
                                                    disablePortal
                                                    id={`unAllocateBin-${order?.oLineItemId}`}
                                                    name={`unAllocateBin-${order?.oLineItemId}`}
                                                    getOptionLabel={(option) => option}
                                                    sx={{"& .MuiAutocomplete-input": {padding:'0px 4px 0px 8px'}}}
                                                    ListboxProps={{ sx: { fontSize: 13 }, padding: '0px' }}
                                                    value={unAllocateBins?.find(bin => bin?.sku === order?.sku && bin?.lineItemId === order?.oLineItemId)?.bin}
                                                    options={unAllocateBins?.some(bin => bin?.sku === order?.sku && bin?.lineItemId === order?.oLineItemId) ? [unAllocateBins?.find(bin => bin?.sku === order?.sku && bin?.lineItemId === order?.oLineItemId)?.bin] : []}
                                                    size="small"
                                                    renderInput={(params) =>
                                                        <TextField {...params} label={"Bin"} required={true}
                                                            // onFocus={() => handleOnFocusSkuBin(order?.sku, order?.locationCode, order?.deliveryNumber,order?.fulfillmentName)}
                                                        />
                                                    }
                                                />
                                            </div>
                                            <p style={{ padding:"0px 10px",textAlign:'center',width:'11%',margin:'auto 0px' }}>
                                                {order?.committedQty}
                                            </p>

                                            <div style={{width:'11%',margin:'auto 0px'}}>
                                                <TextField
                                                    required={true}
                                                    id={`unAllocateQty-${order?.oLineItemId}`}
                                                    name={`unAllocateQty-${order?.oLineItemId}`}
                                                    label='QTY'
                                                    key={order?.committedQty}
                                                    defaultValue={order?.committedQty}
                                                    autoComplete='off'
                                                    type='number'
                                                    size='small'
                                                    onBlur={(event) => handleQty(event, index, order?.sku,order?.fulfillmentName)}
                                                />
                                            </div>

                                            <div style={{width:'11%',textAlign:'center',margin:'auto 0px'}}>
                                                <Button
                                                    variant="contained"
                                                    id={`button-${order?.sku}`}
                                                    onClick={(event) => unAllocateBins?.some(bin => bin?.sku === order?.sku) &&
                                                        handleAllocate(
                                                            event,
                                                            order?.sku,
                                                            order?.quantity,
                                                            order?.locationCode,
                                                            unAllocateBins,
                                                            order?.parentSKU !== null,
                                                            order?.oLineItemId
                                                        )}
                                                    disabled={(unAllocateBins?.some(bin => bin?.sku === order?.sku) ? false : true) && isActionInProgress}
                                                    style={{
                                                        textTransform: 'capitalize',
                                                        padding: '2px 6px',
                                                        fontSize: 13,
                                                        cursor: unAllocateBins?.some(bin => bin?.sku === order?.sku) ? 'pointer' : 'default'
                                                    }}
                                                >
                                                    Un-Allocate
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>

                {loading ? <Spinner /> : null}
            </div>

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
}

export default OrderAllocation;