/**
 * Removes duplicate elements from an array.
 * @returns {Array} A new array with only unique elements.
 */
interface Array<T> {
    unique(): T[];
}

/**
 * Converts the first character of each word in a string to uppercase.
 * @returns {string} The string with each word's first character in uppercase.
 */
interface String {
    toTitleCase(): string;
}

Array.prototype.unique = function <T>(): T[] {
    const uniqueArray: T[] = [];
    const seenElements: { [key: string]: boolean } = {};

    for (let i = 0; i < this.length; i++) {
        const element = this[i];
        if (!seenElements[element]) {
            uniqueArray.push(element);
            seenElements[element] = true;
        }
    }

    return uniqueArray;
};

String.prototype.toTitleCase = function (): string {
    return this.replace(/\b\w/g, (char) => char.toUpperCase());
};