import { Fragment, useMemo, useRef, useImperativeHandle } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Autocomplete, TextField } from "@mui/material";
import { services } from "../../../utility/checkRoleBasedPermission";
import Permission from "../../auth/permissions";
import Table from "../../../components/table/table";
import { setCommissions } from "../../../store/commissionsSlice";

const DataInputValue = (props) => {
  const { item, applyValue, focusElementRef } = props;

  const dataRef = useRef(null);
  useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      dataRef.current.focus();
    },
  }));

  const handleFilterChange = (event, newValue) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <Autocomplete
      id='brandName'
      value={item?.value || ""}
      onChange={handleFilterChange}
      options={["Yes", "No"]}
      getOptionLabel={(data) => data && data}
      disabled={item?.operator !== "equal"}
      ref={dataRef}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='standard'
          label='Status'
          InputLabelProps={{ shrink: true }} // Add this line
        />
      )}
    />
  );
};

const YesNoOperators = [
  {
    label: "Equals",
    value: "equal",
    InputComponent: DataInputValue,
    getValueAsString: (value) => `${value}`,
  },
];

const Commission = (props) => {
  const navigate = useNavigate();
  const commissions = useSelector((state) => state.commissions);

  const columns = useMemo(
    () => [
      {
        field: "orderName",
        headerName: "ORDER NAME",
        flex: 1,
        headerClassName: "super-app-theme--header",
        sortable: true,
        filterable: true,
      },
      {
        field: "displayName",
        headerName: "CUSTOMER",
        flex: 1.5,
        headerClassName: "super-app-theme--header",
        sortable: true,
        filterable: true,
      },
      {
        field: "commission_amount",
        headerName: "COMMISSION AMOUNT",
        flex: 1,
        headerClassName: "super-app-theme--header",
        sortable: true,
        filterable: false,
      },
      {
        field: "payment_received",
        headerName: "PAYMENT RECEIVED",
        flex: 1,
        headerClassName: "super-app-theme--header",
        sortable: true,
        filterable: true,
      },
      {
        field: "transferred",
        headerName: "TRANSFERRED",
        flex: 1,
        headerClassName: "super-app-theme--header",
        sortable: true,
        filterable: true,
      },
      {
        field: "approvedBy",
        headerName: "APPROVED BY",
        flex: 1,
        headerClassName: "super-app-theme--header",
        sortable: false,
        filterable: false,
      },
      {
        field: "customerCode",
        headerName: "Customer Code",
        flex: 1,
        headerClassName: "super-app-theme--header",
        sortable: false,
        filterable: false,
      },
      {
        field: "created_at",
        headerName: "CREATED AT",
        flex: 1.6,
        headerClassName: "super-app-theme--header",
        sortable: true,
        filterable: false,
      },
    ],
    []
  );

  const memoizedColumns = useMemo(
    () =>
      columns.map((col) => {
        if (col?.field === "payment_received") {
          return {
            ...col,
            filterOperators: YesNoOperators,
          };
        } else if (col?.field === "transferred") {
          return {
            ...col,
            filterOperators: YesNoOperators,
          };
        }
        return col;
      }),
    [columns]
  );

  return (
    <Fragment>
      <div id='color'>
        <div
          className='alert alert-primary p-2'
          data-file-upload-alert
          role='alert'
          style={{ display: "none" }}
          id='fileDownloading'
        >
          Sheet is exporting...
        </div>

        <div
          className='d-flex'
          style={{
            justifyContent: "flex-end",
            marginBottom: ".5rem",
            marginTop: -25,
          }}
        >
          <Permission service={services?.commissions} permission={"create"}>
            <Button
              startIcon={<PersonAddIcon />}
              variant='contained'
              color='primary'
              sx={{
                fontSize: "14px",
                textTransform: "capitalize",
                marginRight: "5px",
                "& .MuiButton-startIcon>*:nth-of-type(1)": { fontSize: "17px" },
              }}
              onClick={() => {
                navigate("/createCommission");
              }}
            >
              Create
            </Button>
          </Permission>
        </div>

        <Table
          columns={memoizedColumns}
          dataPosition={"commissions"}
          endpoint={"/others/getCommissions"}
          rowId={"id"}
          checkBoxSelection={false}
          version='version-2'
          navigateOnRowClickEndpoint={`/commissions`}
          service={"commissions"}
          tabName={"Commissions"}
          setData={setCommissions}
          data={commissions}
          mobileResponsive={true}
          exportEndpoint={"/exports/commissions"}
          exportFileName={`Commissions - ${new Date()}`}
          clickable={true}
        />
      </div>
    </Fragment>
  );
};

export default Commission;
