import React, { Fragment, useEffect, useState } from "react";
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    Backdrop,
    CircularProgress,
    TextField,
    Slide,
    Autocomplete,
    Grid,
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const RiderUpdatePopup = (props) => {
    const [locationCodes, setLocationCodes] = useState([]);
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState({ phoneError: false, message: '' });
    const [snackBar, setSnackBar] = React.useState({ display: false, type: "success", message: "default message!" });
    
    const {
        showPopup,
        hidePopup,
        reRender,
        setReRender,
        riderId,
        rider,
        setRider
    } = props;

    useEffect(() => {
        const fetchLocationCodes = async () => {
            try {
                const response = await axiosInstance.get(`/location/locations?text=`)
                
                let locations = response?.data?.data;
                let opts = locations?.map((location) => {
                    return location?.locationCode
                })
                setLocationCodes([...opts])
            } catch (error) {
                const errorMsg = error?.response?.data?.message;
                console.error(`Error: ${errorMsg}`);
            }
        };
        fetchLocationCodes();
    }, []);

    const handleRiderUpdate = async (event) => {
        event.preventDefault();
        setLoading(true);
        const riderData = {
            name: event.target.name.value,
            locationCode: event.target.location.value,
            username: event.target.username.value,
            phone: event.target.phone.value,
            email: event.target.email.value
        }

        if (event.target.password.value) {
            riderData['password'] = event.target.password.value;
        }

        await axiosInstance
            .put(`/pgOrder/admin/updateDeliveryRider/${riderId}`, riderData)
            .then((res) => {
                const form = document.getElementById('form');
                form.reset();
                setReRender(!reRender)
                hidePopup();
                const successMessage = 'Rider Created Successfully';
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((err) => {
                console.log(err)
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
        setLoading(false);
    }

    return (
        <Fragment>
            <Dialog
                open={showPopup}
                TransitionComponent={Transition}
                keepMounted
                onClose={hidePopup}
                fullWidth={true}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DialogTitle>Update Delivery Rider</DialogTitle>
                <form method="post" onSubmit={handleRiderUpdate} id='form'>
                    <Grid container spacing={2} sx={{ padding: 2 }}>
                        {console.log(rider,'rider')}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="name"
                                type="text"
                                label="Rider Name"
                                name="name"
                                key={rider?.deliveryRider}
                                defaultValue={rider?.deliveryRider}
                                placeholder="Enter Rider Name"
                                autoComplete="off"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="username"
                                type="text"
                                label="User Name"
                                name="username"
                                placeholder="Enter User Name"
                                autoComplete="off"
                                key={rider?.username}
                                defaultValue={rider?.username}
                                sx={{ '& .MuiFormHelperText-root': { marginLeft:'0px' }}}
                                variant="outlined"
                                helperText="Spaces and capital letters are not allowed."
                                onKeyPress={(event) => {
                                    if ((event.key === ' ' || event.key === event.key.toUpperCase()) && event.key.match(/[A-Za-z]/)) {
                                        event.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    event.target.value = event.target.value.toLowerCase().replace(/\s/g, '');
                                }}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                id='password'
                                type="text"
                                label="Password"
                                name='password'
                                key={rider?.password}
                                defaultValue={rider?.password}
                                placeholder='Enter Password'
                                autoComplete="off"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                freeSolo
                                disablePortal
                                id="location"
                                name="location"
                                options={locationCodes}
                                key={rider?.locationCode}
                                defaultValue={rider?.locationCode}
                                renderInput={(params) => (
                                    <TextField {...params} label="Locations" required fullWidth />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="number"
                                id="phone"
                                name="phone"
                                label="Phone"
                                autoComplete="off"
                                placeholder="Enter Your Phone Number"
                                value={rider?.phone || ""}
                                onChange={(e) => {
                                    const input = e.target.value;
                                    if (/^\d*$/.test(input) && input.length <= 10) {
                                        // Update the phone number in state
                                        setRider((prev) => ({ ...prev, phone: input }));
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (["-", "e", "E"].includes(e.key)) {
                                        e.preventDefault(); // Prevent invalid characters
                                    }
                                }}
                                onBlur={() => {
                                    if (rider?.phone?.length < 10) {
                                        setError({ phoneError: true, message: "Phone number must be 10 digits." });
                                    } else {
                                        setError({ phoneError: false, message: "" });
                                    }
                                }}
                                error={!!error?.phoneError}
                                helperText={error?.phoneError ? error?.message : ""}
                                inputProps={{
                                    maxLength: 10, // Limits input to 10 characters
                                    pattern: "[0-9]*", // Restricts to numeric input
                                }}
                                fullWidth
                            />


                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="email"
                                type="email"
                                label="Email"
                                name="email"
                                key={rider?.email}
                                defaultValue={rider?.email}
                                placeholder="Enter Your Email"
                                autoComplete="off"
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <DialogActions>
                        <Button onClick={hidePopup}>CLOSE</Button>
                        <Button type='submit' id='btn' disabled={error?.phoneError}>UPDATE</Button>
                    </DialogActions>
                </form>
            </Dialog>
             
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
}


export default RiderUpdatePopup;