import Table from "../../../components/table/table";
import { useSelector } from "react-redux";
import { setEstimates } from '../../../store/estimatesSlice';
const Estimates = () => {
  const estimates = useSelector((state) => state.estimates);
  const columns = [
    {
      field: "displayName",
      headerName: "NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "phone",
      headerName: "PHONE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "email",
      headerName: "EMAIL",
      flex: 1.2,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: .7,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "products",
      headerName: "NO. OF PRODUCTS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => params?.row?.products?.length,
    },
    {
      field: "attachments",
      headerName: "NO. OF ATTACHMENTS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => params?.row?.attachments?.length || 0,
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      flex: 1.3,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
  ];

  return (<>
    <Table 
      rowId={'estimateId'} 
      columns={columns} 
      dataPosition={"estimates"} 
      endpoint={`/estimate/admin`} 
      checkBoxSelection={false}
      service={'estimates'}
      navigateOnRowClickEndpoint = {`/estimates`}
      clickable={true}
      tabName={'Request Estimates'}
      setData={setEstimates}
      data={estimates}
      mobileResponsive={true}
    />
  </>);
};

export default Estimates;
