import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../utility/axios-instance';
import { AffiliateState } from '@/types';

const initialState: AffiliateState = {
	total: 0,
	page: 1,
	maxPage: 1,
	affiliateData: [],
	affiliateList: [],
};

export const fetchAffiliateList = createAsyncThunk(
	'affiliates/fetchAffiliateList',
	async () => {
		const response = await axiosInstance.get("/others/getAffiliateData");
		return response.data.data;
	}
);

const affiliatesDataSlice = createSlice({
	name: 'affiliates',
	initialState: initialState,
	reducers: {
		setAffiliates: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAffiliateList.fulfilled, (state, action) => {
				state.affiliateList = action.payload;
			})
	},
});

export const { setAffiliates, reset } = affiliatesDataSlice.actions;

export default affiliatesDataSlice.reducer;