import React, { useEffect, useState } from "react";
import { FaCar } from "react-icons/fa";
import moment from 'moment';
import PlaceIcon from '@mui/icons-material/Place';
import { IconButton, Tooltip } from "@mui/material";
import LocationDialog from "./userLocationDialog";
import { useSelector } from "react-redux";

const TimelineItem = ({ latitude, longitude, rider, totalVisits, index, createdAt }) => {
    const [address, setAddress] = useState("Fetching address...");
    const [open, setOpen] = useState(false);
    const auth = useSelector((state) => state?.auth);

    const fetchAddress = async (latitude, longitude) => {
        if (!latitude || !longitude) {
            console.warn("Invalid coordinates provided.");
            return "Location unavailable";
        }

        try {
            const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}&accept-language=en`;
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`API error: ${response.statusText}`);
            }

            const data = await response.json();
            return data?.display_name || "Address not found";
        } catch (error) {
            console.error("Error fetching address:", error);
            return "Error fetching address";
        }
    };
        

    useEffect(() => {
        const getAddress = async () => {
            const fetchedAddress = await fetchAddress(latitude, longitude);
            setAddress(fetchedAddress);
        };

        getAddress();
    }, [latitude, longitude]); // Re-fetch if latitude or longitude changes

    const handleClose = () => {
        setOpen(false);
    }

    const handleMap = () => {
        setOpen(true);
    }

    return (
        <div style={styles.timelineItem}>
            <div style={styles.iconContainer}>
                <FaCar />
            </div>
            <div style={styles.contentContainer}>
                <div>
                    <h3 style={styles.title}>{address}</h3>
                    <p style={styles.timeRange}>{moment(createdAt).format('MMM DD, YYYY h:mm:ss A')}</p>
                </div>

                <Tooltip title="See Location On Map" onClick={handleMap} arrow>
                    <IconButton>
                        <PlaceIcon style={{ color:'#1976D2' }} />
                    </IconButton>
                </Tooltip>
            </div>

            {index !== totalVisits - 1 && (
                <div style={styles.lineContainer}>
                    <div style={styles.upperArrow}></div>
                    <div style={styles.verticalLine}></div>
                </div>
            )}

            <LocationDialog 
                handleClose={handleClose}
                open={open}
                rider={{
                    deliveryRider: rider,
                    current_latitude: latitude,
                    current_longitude: longitude
                }}
            />
        </div>
    );
};


const styles = {
    timelineItem: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
    },
    iconContainer: {
        width: "40px",
        height: "40px",
        backgroundColor: "#007BFF",
        color: "#fff",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "10px",
    },
    contentContainer: {
        padding: "10px",
        background: "#f9f9f9",
        borderRadius: "8px",
        flex: 1,
        display:'flex',
        justifyContent: 'space-between',
        alignItems: "center",
    },
    title: {
        margin: 0,
        fontSize: "16px",
        fontWeight: "bold",
    },
    details: {
        margin: "5px 0",
        fontSize: "14px",
        color: "#555",
    },
    timeRange: {
        margin: 0,
        marginTop: 5,
        fontSize: "12px",
        color: "#888",
    },
    lineContainer: {
        position: "absolute", // Container for both arrow and line
        marginLeft: "12px", // Align it with the icon
    },
    verticalLine: {
        position: "absolute",
        left: "8px", // Align with the center of the icon
        top: "20px", // Below the icon
        width: "2px",
        height: "50px", // Adjust this based on spacing between items
        backgroundColor: "#ccc",
    },
    upperArrow: {
        position: "absolute",
        left: "4px", // Center with vertical line
        top: "21px", // Slightly above the vertical line
        width: "0",
        height: "0",
        borderLeft: "5px solid transparent",
        borderRight: "5px solid transparent",
        borderBottom: "5px solid #ccc", // Same color as the line
    },
};

export default TimelineItem;