import { useState, Fragment } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../utility/axios-instance';
import { setAuth } from '../../store/authSlice';
import Classes from './auth.module.css';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import {InputAdornment, TextField, IconButton } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { MuiOtpInput } from 'mui-one-time-password-input'
import Snackbar from "../../components/SnackBar";
import LoadingButton from '@mui/lab/LoadingButton';
import { ResendOTP } from 'otp-input-react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


import Auth from './auth';

const SignIn = (props) => {
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState('signInViaPassword');
	const [loginPhone, setLoginPhone] = useState(null);
	const [otp, setOtp] = useState('')
	const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
	const [showPassword, setShowPassword] = useState(false);
	const [loginAsRider, setLoginAsRider] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);


	const handleOtpChange = (newValue) => {
		setOtp(newValue)
	}

	const auth = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const getLoginOTP = async (e) => {
		setLoading(true);
		e.preventDefault();
		const { phone } = e.target.elements;

		if (!phone.value) {
			setSnackBar((prevSnackBar) => {
				return { ...prevSnackBar, display: true, message: 'Please enter phone number', type: "error" }
			});
			return
		}
		else if (phone.value.length !== 10) {
			setSnackBar((prevSnackBar) => {
				return { ...prevSnackBar, display: true, message: 'Enter a Valid Phone No.', type: "error" }
			});
			return
		}

		await axios
			.post('/admin/auth/loginViaOtp', {
				phone: `+91${phone.value}`,
			})
			.then((res) => {
				setLoginPhone(`${phone.value}`);
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: res.data.message, type: "success" }
				});
			})
			.catch((err) => {
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: err.response.data.message, type: "error" }
				});
			});
		setLoading(false);
	};

	const reSendLoginOTP = async () => {
		const phone = loginPhone;

		await axios
			.post('/admin/auth/loginViaOtp', {
				phone: `+91${phone}`,
			})
			.then((res) => {
				setLoginPhone(`${phone}`);
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: res.data.message, type: "success" }
				});
			})
			.catch((err) => {
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: err.response.data.message, type: "error" }
				});
			});
		setLoading(false);
	};

	const login = async (e) => {
		e.preventDefault();
		setLoading(true);
		const { username, password } = e.target.elements;
		const config = loginAsRider ? 
			{ headers: { 'Logged-Medium': 'deliveryRider' }}
		: {};


		axios
			.post(!loginAsRider ? '/admin/auth/login' : '/deliveryBoy/login', 
				{
					username: username.value,
					password: password.value,
				},
				config
			)
			.then((res) => {
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: res.data.message, type: "success" }
				});
				dispatch(
					setAuth({
						authenticated: true,
						user: res.data.data,
					})
				);

				if (localStorage.getItem('token')) {
					axios
						.post('/admin/auth/firebaseTokens', {
							token: localStorage.getItem('token'),
						})
						.then((res) => {
							// console.log(res.data);
							localStorage.removeItem('token');
						})
						.catch((err) => {
							// console.log(err);
						});
				}

				setTimeout(() => {
					navigate(
						res.data.data.role === 'delivery-boy' ? '/deliveryRiders' : loginAsRider ? `/deliveryRiders/${res?.data?.data?.deliveryBoyId}` : '/'
					);
				}, 1000);
			})
			.catch((err) => {
				const errorMessage = err?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const submit = async () => {
		if (!otp) {
			setSnackBar((prevSnackBar) => {
				return { ...prevSnackBar, display: true, message: "Please Enter OTP", type: "error" }
			});
			return;
		} else if (otp.length !== 4) {
			setSnackBar((prevSnackBar) => {
				return { ...prevSnackBar, display: true, message: "Please Enter Valid OTP", type: "error" }
			});
			return;
		}

		setLoading(true);

		await axios
			.post('/admin/auth/verifyLoginOtp', {
				otp,
			})
			.then((res) => {
				setLoginPhone(null);
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: res.data.message, type: "success" }
				});
				dispatch(
					setAuth({
						authenticated: true,
						user: res.data.data,
					})
				);
			})
			.catch((err) => {
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: err.response.data.message, type: "error" }
				});
			});

		setLoading(false);
	};

	function Copyright(props) {
		return (
			<Typography variant="body2" color="text.secondary" align="center" {...props}>
				{'Copyright © '}
				<Link color="inherit" href="https://depo24.in/">
					Depo24
				</Link>{' '}
				{new Date().getFullYear()}
				{'.'}
			</Typography>
		);
	}

	const renderButton = (button_props) => {
		return (
			<Button
				type="button"
				size='small'
				{...button_props}
			>
				Resend OTP
			</Button>
		);
	};

	const renderTime = (remaining_time) => {
		return remaining_time ? (
			<p className={Classes.Peer}>
				Resend OTP in <br /> {remaining_time > 0 ? `${remaining_time} s` : ''}
			</p>
		) : null;
	};

	return auth.loading ? (
		<Auth />
	) : (
		<Fragment style={{
			display: 'flex',
		}}>
			{auth.authenticated ? <Navigate to="/" /> : null}
			<Snackbar snackBar={snackBar} setSnackBar={setSnackBar} />
			<div style={{
					display: 'flex',
					height: '100vh',
					backgroundImage: `linear-gradient(#a5a5a5, rgb(0 0 0)), url(https://prod-depo24.s3.ap-south-1.amazonaws.com/bg-image/WhatsApp+Image+2024-09-18+at+12.33.58.jpeg)`,
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					backgroundBlendMode: 'multiply',
					backgroundSize: 'cover',
				}}>
				{/* <Container maxWidth="sm">
					image here
				</Container> */}

				<Container component="main" maxWidth="xs" style={{
					position: 'absolute',
					left: '50%',
					top: '50%',
					transform: 'translate(-50%, -50%)',
					boxShadow: '1px 1px 25px -7px black',
					backgroundColor: 'white',
				}}>
					<CssBaseline />
					<Box
						sx={{
							marginTop: 8,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Box sx={{ px: 2.5, py: 3, display: 'inline-flex', width: '100%', justifyContent: 'center' }}>
							<img src='https://depo24.in/static/media/logo.bde3bc246ae23cbc5bc1e5cd4efdaa78.svg' alt='Depo24' style={{ width: '150px' }} />
						</Box>
						<Typography component="h1" variant="h6" style={{
							    color: 'white',
								fontWeight: '600',
								background: 'rgb(255, 98, 0)',
								padding: '2px 30px',
								fontSize: '13px',
								borderRadius: '5px',
								boxShadow: '1px 1px 3px 1px #c1c1c1',
							}}>
							{!loginAsRider ? 'ADMIN PORTAL' : 'Rider Login'}
						</Typography>
						{page === 'signInViaPassword' ? (
							<Box component="form" onSubmit={login} sx={{ mt: 1 }}>
								<TextField
									margin="normal"
									required
									fullWidth
									id="username"
									label="Username"
									name="username"
									autoFocus
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									name="password"
									type={showPassword ? 'text' : 'password'}
									label="Password"
									id="password"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton onClick={handleClickShowPassword}>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<FormControlLabel
									control={<Checkbox checked={true} disabled value="remember" color="primary" />}
									label="Remember me"
								/>
								<LoadingButton
									type="submit"
									fullWidth
									variant="contained"
									loading={loading}
									style={{ backgroundColor: "#FF6200" }}
									sx={{ mt: 3, mb: 2 }}
								>
									Sign In
								</LoadingButton>

								{!loginAsRider && (
									<Button
										fullWidth
										variant="outlined"
										sx={{ mt: 1, mb: 1 }}
										onClick={() => setPage('signInViaOTP')}
									>
										Sign In With OTP
									</Button>
								)}
							</Box>
						) : null}
						{page === 'signInViaOTP' ? (
							<Box component="form" onSubmit={getLoginOTP} sx={{ mt: 1 }}>
								<TextField
									margin="normal"
									required
									fullWidth
									id="phone"
									label="Phone"
									name="phone"
									autoFocus
									disabled={loginPhone ? true : false}
								/>
								{loginPhone ? (
									<MuiOtpInput value={otp} onChange={handleOtpChange} />
								) : null}

								{loginPhone ? (
									<div>
										<LoadingButton
											onClick={submit}
											fullWidth
											variant="contained"
											loading={loading}
											style={{ backgroundColor: "#FF6200" }}
											sx={{ mt: 3, mb: 2 }}
										>
											Verify
										</LoadingButton>
										<ResendOTP
											renderButton={renderButton}
											renderTime={renderTime}
											className="mt-2"
											onResendClick={reSendLoginOTP}
											maxTime={30}
										/>
									</div>)
									:
									(<LoadingButton
										type="submit"
										fullWidth
										variant="contained"
										sx={{ mt: 3, mb: 2 }}
										loading={loading}
										style={{ backgroundColor: "#FF6200" }}

									>
										Get OTP
									</LoadingButton>
									)
								}

								<Button
									fullWidth
									variant="outlined"
									sx={{ mt: 1, mb: 1 }}
									onClick={() => {
										setOtp('')
										setLoginPhone(null)
										setPage('signInViaPassword')
									}}
								>
									Sign In With Password
								</Button>
							</Box>
						) : null}

						{!loginAsRider && (
							<Button
								fullWidth
								variant="outlined"
								sx={{ mt: 1, mb: 1 }}
								onClick={() => setLoginAsRider(true)}
							>
								Sign In As Rider
							</Button>
						)}

						{loginAsRider && (
							<Button
								fullWidth
								variant="outlined"
								sx={{ mt: 1, mb: 1 }}
								onClick={() => setLoginAsRider(false)}
							>
								Sign In As Admin
							</Button>
						)}
					</Box>
					<Copyright sx={{ mt: 8, mb: 4 }} />
				</Container>

			</div>

		</Fragment>
	);
};

export default SignIn;
