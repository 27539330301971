import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ExtensionIcon from "@mui/icons-material/Extension";
import axiosInstance from "../../../utility/axios-instance";
import SnackBar from "../../../components/SnackBar";
import { debounce } from "../../../utility/utilityFunctions";
import { AddCouponProps } from "@/types";

export default function AddCoupon({
  overAllSubTotal,
  selectedProducts,
  customer,
  couponCode,
  setCouponCode,
  afterCouponCodeApplyHook,
  removeOverAllDiscount,
}: AddCouponProps) {
  const [snackBar, setSnackBar] = useState({
    display: false,
    message: "",
    type: "success",
  });

  const [open, setOpen] = useState(false);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e && e.preventDefault();
    if (!couponCode) {
      alert("Please enter coupon code");
      return;
    }

    await axiosInstance
      .post("/orders/admin/applyCoupon", {
        couponCode: `${couponCode}`,
        lineItems: selectedProducts?.map((product) => {
            return {
                ...product,
                productId: product?.productId,
            }
        }),
        user: customer,
        subTotal: overAllSubTotal,
      })
      .then(async (res) => {
        afterCouponCodeApplyHook(res?.data?.data);
        setSnackBar({
          display: true,
          message: "Coupon applied successfully",
          type: "success",
        });
        setOpen(false);
      })
      .catch((err) => {
        removeOverAllDiscount();
        setSnackBar({
          display: true,
          message: err?.response?.data?.message || "Something went wrong",
          type: "error",
        });
      });
  };

  
  // Debounced handleSubmit function
  const debouncedHandleSubmit = debounce(handleSubmit, 500);

  // Modified form onSubmit event handler
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    debouncedHandleSubmit(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCouponCode(e?.target?.value);
  };

  useEffect(() => {
    if(customer && couponCode) debouncedHandleSubmit();
  }, [customer]);

  return (
    <div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Apply Coupon Code</DialogTitle>
        <DialogContent>
          <form onSubmit={handleFormSubmit}>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <TextField
                  id="code"
                  name="code"
                  className="col-span-4"
                  label="Coupon Code"
                  placeholder="Coupon Code.."
                  value={couponCode}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex w-full">
              <Button type="submit">Apply</Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Button
        size="small"
        sx={{
          fontSize: 14,
          color: "#0d6efd",
          padding: "0.5em 0.5em 0.5em 0em",
          textTransform: "none",
          margin: "0.2em 0em",
          "&:hover": { backgroundColor: "transparent" },
        }}
        onClick={() => setOpen(true)}
      >
        Apply Coupon
        <ExtensionIcon sx={{ fontSize: 16, marginLeft: 1 }} />{" "}
      </Button>
      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
    </div>
  );
}
