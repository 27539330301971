import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../../utility/axios-instance";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Tooltip, Box, Typography } from "@mui/material";
import TimelineItem from "./riderVisitDetails";
import SnackBar from "../../../components/SnackBar";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";

const RiderDetails = () => {
    const [coords, setCoords] = useState(null);
    const [address, setAddress] = useState("");
    const [visits, setVisits] = useState([]);
    const [visitVisible, setVisitVisible] = useState(true);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const auth = useSelector((state) => state?.auth);
    const { Id } = useParams();
    const getFormattedDate = (date) => `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    const [date, setDate] = useState(() => getFormattedDate(new Date()));

    useEffect(() => {
        const getAddressFromCoords = async (latitude, longitude) => {
            const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}&accept-language=en`;
            try {
                const response = await fetch(url);
                const data = await response.json();
                setAddress(data.display_name || "Address not found");
            } catch (error) {
                console.error("Error fetching address:", error);
                setAddress("Error fetching address");
            }
        };

        if (!auth?.user?._id) {
            const watchId = navigator.geolocation.watchPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCoords({ latitude, longitude });
                    getAddressFromCoords(latitude, longitude);
                },
                (error) => {
                    console.error("Error watching position:", error);
                    alert("Unable to fetch your location. Please enable GPS.");
                },
                {
                    enableHighAccuracy: true,
                    maximumAge: 0,
                    timeout: 5000,
                }
            );
    
            return () => {
                navigator.geolocation.clearWatch(watchId);
            };
        } else {
            const fetchRiderDetails = async () => {
                await axiosInstance
                    .get(`/pgOrder/admin/deliveryRider/${Id}`)
                    .then((res) => {
                        if (res?.data?.data?.current_latitude && res?.data?.data?.current_longitude) {
                            const latitude = res?.data?.data?.current_latitude;
                            const longitude = res?.data?.data?.current_longitude;
                            setCoords({ latitude, longitude });
                            getAddressFromCoords(latitude, longitude);
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        const errorMessage = err?.response?.data?.message
                        setSnackBar((prevSnackBar) => {
                            return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                        });
                    })
            }
            fetchRiderDetails();
        }
    }, []);

    const updateLocationInDB = async (latitude, longitude) => {
        const config = !auth?.user?._id
            ? { headers: { "Logged-Medium": "deliveryRider" } }
            : {};
        try {
            await axiosInstance.put(
                `/deliveryBoy/updateLocation/${Id}`,
                { current_latitude: latitude, current_longitude: longitude },
                config
            );
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: "Location updated successfully.", type: "success" }
            });
        } catch (error) {
            const errorMessage = error?.response?.data?.message
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
            });
        }
    };

    const handleCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCoords({ latitude, longitude }); // Optionally update state
                    updateLocationInDB(latitude, longitude);
                },
                (error) => {
                    console.error("Error fetching location:", error);
                    alert("Unable to fetch your location. Please ensure GPS is enabled.");
                },
                {
                    enableHighAccuracy: true,
                    maximumAge: 0,
                    timeout: 5000,
                }
            );
        } else {
            alert("Geolocation is not supported by your browser.");
        }
    };

    const handleVisits = async (visible) => {
        try {
            if (visible === "See") {
                const response = await axiosInstance.get(`/deliveryBoy/fetchVisits/${Id}?date=${date}`);
                setVisits(response?.data?.data);
                setVisitVisible(false);
            } else {
                setVisitVisible(true);
            }
        } catch (error) {
            console.error("Error fetching visits:", error);
        }
    };

    if (!coords) {
        return <Typography variant="h5" style={{ display:'flex', justifyContent:'center', alignItems:'center'}}>
            Location Not Found
        </Typography>;
    }

    return (
        <Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
            >
                <Box display="flex" alignItems="center">
                    <Typography variant="h6">Current Address:</Typography>
                    <Typography variant="h5" ml={1}>
                        {address}
                    </Typography>
                </Box>
                <Box>
                    {!auth?.user?._id && (
                        <Button
                            variant="contained"
                            onClick={handleCurrentLocation}
                            sx={{ mr: 1, textTransform:'capitalize', borderRadius:'20px' }}
                        >
                            Add Current Location
                        </Button>
                    )}
                
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            label="Date" 
                            sx={{ 
                                "& .MuiOutlinedInput-input": {padding: "9.5px 10px",width: '105px',fontSize:'14px'},
                                "& .MuiOutlinedInput-root": { borderRadius:'20px' },
                                "& .MuiSvgIcon-root": { fontSize:"18px" },
                                "& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
                                "& .Mui-error": { color:'#00000099 !important' }
                            }}
                            inputFormat="YYYY-MM-DD"
                            size="small"
                            format="YYYY-MM-DD"
                            defaultValue={dayjs(date)}
                            value={dayjs(date)}
                            onChange={(newValue) => {
                                setDate(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`);
                            }} 
                        />  
                    </LocalizationProvider>

                    <Tooltip title="See Visits" arrow>
                        <Button 
                            variant='contained' 
                            onClick={() => handleVisits(visitVisible ? "See" : "Hide")}
                            sx={{ textTransform:'capitalize', marginLeft:'10px', borderRadius:'20px' }}
                        >
                            {visitVisible ? "See" : "Hide"} Visits
                        </Button>
                    </Tooltip>
                </Box>
            </Box>

            {!visitVisible && (
                <Box position="relative" p={2}>
                    {visits?.map((visit, index) => (
                        <TimelineItem
                            key={index}
                            index={index}
                            rider={visit?.rider}
                            totalVisits={visits.length}
                            latitude={visit.latitude}
                            longitude={visit.longitude}
                            createdAt={visit.createdAt}
                        />
                    ))}
                </Box>
            )}

            <Typography variant="h4" mt={2}>
                Location on Map:
            </Typography>

            <iframe
                title="Map"
                src={`https://www.google.com/maps?q=${coords.latitude},${coords.longitude}&output=embed`}
                width="100%"
                height="600"
                style={{ boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.15)" }}
                allowFullScreen
                loading="lazy"
            ></iframe>

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Box>
    );
};

export default RiderDetails;