import { Fragment, MouseEvent, SetStateAction, useEffect, useState } from "react";
import Classes from "../css/create.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import AsideModal from "./asideModal";
import ContactPopUp from '../../customers/aside/contactPopUp';
import ShippingPopUp from "./shippingAddressPopup";
import TagsPopup from "./tagsPopup";
import RemoveGstPopup from "./RemoveGstNoPopup";
import UpdateShippingPopUp from "./updateShippingAddress";
import DefaultAddressPopUp from "./defaultAddressPopUp";
import GSTNoPopup from "./newGstNo";
// import BillingAdressPopUp from './BillingAddressPopUp';
import UpdateBillingPopUp from "./updateBillingAddress";
import DefaultBillingAdressPopUp from "./defaultBillingAddressPopUp";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import NewSalesUser from "../../reportDashboard/newSalesUser";
// import SellerDetails from "../sellerDetails";
import Permission from "../../../auth/permissions";
import NewSellerDetails from '../addNewSellerDetails';
import { services } from "../../../../utility/checkRoleBasedPermission";
import { paymentRemarks } from "../../../../constants/paymentRemarks";
import axiosInstance from "../../../../utility/axios-instance";
import { useAffiliate } from "../../../../hooks";
import { CustomerAddressesFetchResponse, AutoCompleteOption, CreateOrderAsideProps, CustomerAddress, CustomerRoles, HandleBlurEvent, HandleEditEvent } from "@/types";

const Aside = (props: CreateOrderAsideProps) => {
  const [showModal, setShowModal] = useState(false);
  const [searchCustomer, setSearchCustomer] = useState<string>("");
  const [toggleButton, setToggleButton] = useState(false);
  const [contactModal, setContactModal] = useState<boolean>(false);
  const [shippingModal, setShippingModal] = useState<boolean>(false);
  const [tagsModal, setTagsModal] = useState(false);
  const [updateShippingModal, setUpdateShippingModal] = useState<boolean>(false);
  const [defaultAddressModal, setDefaultAddressModal] = useState<boolean>(false);
  const [newGstNo, setNewGstNo] = useState(false);
  const [previousTarget, setPreviousTarget] = useState([]);
  const [addressType, setAddressType] = useState<string>("");
  const [updateBillingAddressModal, setUpdateBillingAddressModal] =
    useState<boolean>(false);
  const [defaultBillingAddressModal, setDefaultBillingAddressModal] =
    useState<boolean>(false);
  const [tagInputValue, setTagInputValue] = useState("");
  const [removeGstModal, setRemoveGstModal] = useState(false);

  // Create SalesUser
  const [userName, setUserName] = useState("");
  const [name, setName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [password, setPassword] = useState("");
  const [showSalesModal, setShowSalesModal] = useState(false);
  const { affiliateList } = useAffiliate();
  const hideSalesDialog = () => {
    setShowSalesModal(false);
  };

  const showSalesDialog = () => {
    setShowSalesModal(true);
  };

  // set asideModalToggle
  const [toggle, setToggle] = useState(true);

  // shippingAddressPopUp
  const [num, setNum] = useState<string>("");
  const [pincode, setPincode] = useState<string>("");
  const [state, setState] = useState('');

  const {
    estimateUserId,
    showCustomerDetail: customerDetails,
    setCustomerDetails: setCustomerDetails,
    setGstNumber,
    showSelectedTags,
    gstNumber,
    setSelectedTags,
    addressArray,
    setAddressArray,
    fetchCustomerRoleDB,
    updateExistOrder,
    setPaymentRemark,
    setDeliveryRemark,
    paymentRemark,
    affiliateUser,
    setAffiliateUser,
    setSalesUser,
    salesUser,
    setPreSalesUser,
    preSalesUser,
    salesList,
    handleSalesList,
    setSellerBillingAddress,
    setSeller,
    seller,
    sellerBillingAddress,
    selectedRoleForDiscount,
    setSelectedRoleForDiscount,
    selectedShippingAddress, 
    setSelectedShippingAddress,
    selectedBillingAddress,
    setSelectedBillingAddress
  } = props;

  console.log(customerDetails, "customerDetails")
  const showCustomerModal = () => {
    setShowModal(true);
  };

  const hideCustomerModal = () => {
    setShowModal(false);
  };

  const removeElement = (Id: number) => {
    setAffiliateUser(null);
    setSalesUser(null);
    setPreSalesUser(null);
    setToggle(true);
    setSearchCustomer("");
    setGstNumber('');
    setCustomerDetails(null);
    if (selectedShippingAddress !== null) {
      setSelectedShippingAddress(null);
    }

    if (selectedBillingAddress !== null) {
      setSelectedBillingAddress(null);
    }

    if (previousTarget.length !== 0) {
      setPreviousTarget(previousTarget.splice(0, previousTarget.length));
    }
  };

  const handleSearchCustomer = (customer: SetStateAction<string>) => {
    setToggleButton(true);
    setSearchCustomer(customer);
  };

  const showContactPop = () => {
    setContactModal(true);
  };

  const hideContactPop = () => {
    setContactModal(false);
  };

  const showShippingPop = (event: MouseEvent<HTMLParagraphElement, globalThis.MouseEvent>) => {
    setAddressType((event.target as HTMLParagraphElement).id);
    setShippingModal(true);
  };

  const hideShippingPop = () => {
    setPincode("");
    setNum("");
    setState("");
    setShippingModal(false);
  };

  const showUpdateShippingPop = (event: MouseEvent<HTMLParagraphElement, globalThis.MouseEvent> ) => {
    setAddressType((event.target as HTMLParagraphElement).id);
    setUpdateShippingModal(true);
  };

  const hideUpdateShippingPop = () => {
    setUpdateShippingModal(false);
  };

  const showUpdateBillingPop = (event: React.MouseEvent<HTMLParagraphElement, globalThis.MouseEvent>) => {
    setAddressType((event.target as HTMLParagraphElement).id);
    setUpdateBillingAddressModal(true);
  };

  const hideUpdateBillingPop = () => {
    setUpdateBillingAddressModal(false);
  };

  const showdefaultBillingAddressPop = () => {
    setDefaultBillingAddressModal(true);
    if (customerDetails?.id !== undefined) {
      fetchCustomerAddresses(`${customerDetails.id}`, "");
    }
  };

  const hideDefaultBillingAddressPop = () => {
    setPreviousTarget([]);
    setDefaultBillingAddressModal(false);
  };

  const showDefaultAddressPopUp = () => {
    setDefaultAddressModal(true);
    if(customerDetails?.id){
      fetchCustomerAddresses(`${customerDetails?.id}`, "");
    }
  };

  const hideDefaultAddressPopUp = () => {
    setPreviousTarget([]);
    setDefaultAddressModal(false);
  };

  const showGstNoPopUp = () => {
    setNewGstNo(true);
  };

  const hideGstNoPopUp = () => {
    setNewGstNo(false);
  };

  const handleGst = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    if (event.target.value === "None") {
      setGstNumber("remove");
    } else {
      setGstNumber(event.target.value);
    }
  };

  const showTagsPopup = () => {
    setTagsModal(true);
  };

  const showRemoveGstPopup = () => {
    setRemoveGstModal(true);
  };

  const hideRemoveGstPopup = () => {
    setRemoveGstModal(false);
  };

  const hideTagsPopup = () => {
    setTagsModal(false);
  };

  const handleAddTag = () => {
    if (tagInputValue !== "") {
      setSelectedTags([...showSelectedTags, tagInputValue]);
      setTagInputValue("");
    }
  };

  document.addEventListener("mouseup", function (e) {
    let container = document.getElementById("contactOutline");
    if (container !== null) {
      if (!container.contains(e.target as Node)) {
        container.style.border = "1px solid rgb(206, 212, 218)";
      }
    }
  });

  const handleOutline = () => {
    (document.getElementById("contactOutline") as HTMLElement).style.border =
      "2px solid #458fff";
  };

  const capitalName = (text:string) => {
    const result1 = text.replaceAll("_", " ");
    const arr = result1.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  };


  const handleEdit = (event: HandleEditEvent) => {
    if ((document.activeElement as HTMLElement)?.isContentEditable) {
      if (event.keyCode === 13) {
        event.preventDefault();
        event.target.blur();
        return;
      }
    }
  };

  const handleBlur = (event: HandleBlurEvent) => {
    if (event.target.innerText.length <= 250) {
      if (event.target.id === "paymentRemark") {
        setPaymentRemark(event.target.innerText);
      } else {
        setDeliveryRemark(event.target.innerText);
      }
    }
  };

  const fetchCustomerAddresses = async (customerId:string, searchText:string): Promise<CustomerAddress[] | undefined> => {
    try {
      const response = await axiosInstance.get<CustomerAddressesFetchResponse>(`/user/admin/${customerId}/address`, {
        params: { searchText },
      });
      const addresses = response?.data?.data;
      setAddressArray(addresses);
      return addresses;
    } catch (error) {
      console.error("Error fetching customer addresses:", error);
    }
  };

  console.log("affiliateUser", affiliateUser);
  return (
    <Fragment>
      <div
        className={[Classes.Box, "box"].join(" ")}
        id="createOrderContactBox"
      >
        {customerDetails? (
          <>
            {customerDetails &&
              <div key={customerDetails?.id} id={`{customerDetails?.id}`}>
                <div style={{ borderBottom: "1px solid #DBDDE0" }}>
                  <div className={Classes.containerBox} id="customerBox">
                    <div className={Classes.customerBox} id="customerHeader">
                      <h6 style={{ margin: "auto 0px" }}>Customer</h6>
                      <div className="removeIcon">
                        <FontAwesomeIcon
                          icon={faXmark}
                          className={Classes.xMarkIcon}
                          onClick={() => removeElement(customerDetails?.id)}
                        />
                      </div>
                    </div>

                    <div className={Classes.customerDetailBox}>
                      <div className={Classes.customerName}>
                        {customerDetails?.business_name
                          ? customerDetails?.business_name
                          : `${customerDetails?.first_name} ${
                              customerDetails?.last_name
                            }`}
                      </div>

                      {customerDetails?.customer_role && (
                        <div className={Classes.customerRole}>
                          <p>{capitalName(customerDetails?.customer_role)}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className={Classes.containerBox}
                  id="contactInformationBox"
                >
                  <div
                    className={[
                      Classes.customerBox,
                      Classes.flexBetween,
                    ].join(" ")}
                    id="contactInformationHeader"
                  >
                    <p className={Classes.contactInformation}>
                      CONTACT INFORMATION
                    </p>
                    <p
                      className={["removeEdit", Classes.contactEditBtn].join(
                        " "
                      )}
                      onClick={showContactPop}
                    >
                      Edit
                    </p>
                  </div>

                  <div className={Classes.colorFontSize}>
                    <p>{customerDetails?.customer_code}</p>
                    <p>{customerDetails?.email}</p>
                    <p style={{ marginTop: "5px" }}>{customerDetails?.phone}</p>
                  </div>
                </div>

                {gstNumber?.length !== 0 && (
                  <div
                    style={{ padding: "10px 1.1rem", margin: "0" }}
                    id="createOrderPrintGstBox"
                    className={Classes.printGstBox}
                  >
                    <h6
                      style={{ margin: "5px 0px" }}
                      className={Classes.contactInformation}
                    >
                      GST Number
                    </h6>
                    <p className={Classes.colorFontSize}>{gstNumber}</p>
                  </div>
                )}
              </div>
              }
          </>
        ) : (
          <div className={Classes.containerBox} id="hidePrintCustomerBox">
            <div className="mb-4">
              <h6 style={{ margin: "auto 0px", padding: "6px" }}>Customer</h6>
            </div>
            <div>
              <div
                onClick={handleOutline}
                id="contactOutline"
                className={Classes.contactInputBox}
              >
                <div>
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className={Classes.contactSearchIcon}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <input
                    type="text"
                    id="search"
                    className={Classes.searchField}
                    placeholder="Search customers"
                    autoComplete="off"
                    onChange={(e) => handleSearchCustomer(e.target.value)}
                    onClick={() => showCustomerModal()}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div>
          <AsideModal
            estimateUserId={estimateUserId}
            showModal={showModal}
            onHideModal={hideCustomerModal}
            setDetail={setCustomerDetails}
            searchCustomer={searchCustomer}
            toggleButton={toggleButton}
            setToggleButton={setToggleButton}
            setPreviousTarget={setPreviousTarget}
            setSelectedShippingAddress={setSelectedShippingAddress}
            setSelectedBillingAddress={setSelectedBillingAddress}
            toggle={toggle}
            setToggle={setToggle}
            setAddressArray={setAddressArray}
            setAffiliateUser={setAffiliateUser}
            affiliateUser={affiliateUser}
            setSalesUser={setSalesUser}
            salesUser={salesUser}
            setPreSalesUser={setPreSalesUser}
            preSalesUser={preSalesUser}
            setGstNumber={setGstNumber}
            fetchCustomerAddresses={fetchCustomerAddresses}
          />
        </div>

        <div className={contactModal ? Classes.UpdateSalesDialog : undefined}>
          <ContactPopUp
            contactModal={contactModal}
            hideContactPop={hideContactPop}
            showCustomerProfile={
              customerDetails
            }
            setCustomerProfile={setCustomerDetails}
            displayName={
              customerDetails?.display_name || ""
            }
          />
        </div>

        <div className={shippingModal ? Classes.UpdateSalesDialog : undefined}>
          <ShippingPopUp
            shippingModal={shippingModal}
            hideShippingPop={hideShippingPop}
            pincode={pincode}
            setPincode={setPincode}
            num={num}
            setNum={setNum}
            setState={setState}
            state={state}
            showCustomerDetail={customerDetails}
            setSelectedShippingAddress={setSelectedShippingAddress}
            setSelectedBillingAddress={setSelectedBillingAddress}
            addressArray={addressArray}
            setAddressArray={setAddressArray}
            addressType={addressType}
          />
        </div>

        <div
          className={
            updateShippingModal ? Classes.UpdateSalesDialog : undefined
          }
        >
          <UpdateShippingPopUp
            hideUpdateShippingPop={hideUpdateShippingPop}
            showCustomerDetail={customerDetails}
            updateShippingModal={updateShippingModal}
            selectedShippingAddress={selectedShippingAddress}
            setSelectedShippingAddress={setSelectedShippingAddress}
            addressType={addressType}
            addressArray={addressArray}
            setAddressArray={setAddressArray}
          />
        </div>

        <div
          className={
            updateBillingAddressModal ? Classes.UpdateSalesDialog : undefined
          }
        >
          <UpdateBillingPopUp
            hideUpdateBillingPop={hideUpdateBillingPop}
            updateBillingModal={updateBillingAddressModal}
            showCustomerDetail={customerDetails}
            selectedBillingAddress={selectedBillingAddress}
            setSelectedBillingAddress={setSelectedBillingAddress}
            addressType={addressType}
            addressArray={addressArray}
            setAddressArray={setAddressArray}
          />
        </div>

        <div
          className={
            defaultAddressModal ? Classes.UpdateSalesDialog : undefined
          }
        >
          <DefaultAddressPopUp
            defaultAddressModal={defaultAddressModal}
            hideDefaultAddressPopUp={hideDefaultAddressPopUp}
            selectedShippingAddress={selectedShippingAddress}
            setSelectedShippingAddress={setSelectedShippingAddress}
            showCustomerDetail={customerDetails}
            addressArray={addressArray}
            setPreviousTarget={setPreviousTarget}
            setAddressArray={setAddressArray}
            fetchCustomerAddresses={fetchCustomerAddresses}
          />
        </div>

        <div
          className={
            defaultBillingAddressModal ? Classes.UpdateSalesDialog : undefined
          }
        >
          <DefaultBillingAdressPopUp
            defaultBillingAddressModal={defaultBillingAddressModal}
            hideDefaultBillingAddressPop={hideDefaultBillingAddressPop}
            showCustomerDetail={customerDetails}
            addressArray={addressArray}
            selectedBillingAddress={selectedBillingAddress}
            setSelectedBillingAddress={setSelectedBillingAddress}
            setPreviousTarget={setPreviousTarget}
            setAddressArray={setAddressArray}
            fetchCustomerAddresses={fetchCustomerAddresses}
          />
        </div>

        <div className={newGstNo ? Classes.UpdateSalesDialog : undefined}>
          <GSTNoPopup
            newGstNo={newGstNo}
            setNewGstNo={setNewGstNo}
            showGstNoPopUp={showGstNoPopUp}
            hideGstNoPopUp={hideGstNoPopUp}
            showCustomerDetail={customerDetails}
            setCustomerDetail={setCustomerDetails}
            setGstNumber={setGstNumber}
          />
        </div>

        <div className={tagsModal ? Classes.UpdateSalesDialog : undefined}>
          <TagsPopup
            tagsModal={tagsModal}
            setTagsModal={setTagsModal}
            showTagsPopup={showTagsPopup}
            hideTagsPopup={hideTagsPopup}
            SelectedTags={showSelectedTags}
            setSelectedTag={setSelectedTags}
          />
        </div>

        <div className={removeGstModal ? Classes.UpdateSalesDialog : undefined}>
          <RemoveGstPopup
            removeGstModal={removeGstModal}
            hideRemoveGstPopup={hideRemoveGstPopup}
            showCustomerDetail={customerDetails}
            setCustomerDetail={setCustomerDetails}
            gstNumber={gstNumber}
            setGstNumber={setGstNumber}
          />
        </div>
      </div>
      <Permission service={services?.sellers} permission={"read"}>
        <div id="removeSelectGst">
          <NewSellerDetails
            setSellerBillingAddress={setSellerBillingAddress}
            setSeller={setSeller}
            seller={seller}
            sellerBillingAddress={sellerBillingAddress}
          />
        </div>
      </Permission>
      <div className={[Classes.Box, "box"].join(" ")} id="removeSelectGst">
        {customerDetails ? (
          <div className={Classes.containerBox}>
            <div className="d-flex justify-content-between mb-2">
              <h6 style={{ margin: "auto 0px", marginLeft: 5 }}>
                Select GST No
              </h6>
              <p onClick={showGstNoPopUp} className={Classes.addNewGstNo}>
                Add New GST
              </p>
            </div>

            <div className={Classes.fontSize}>
              <select
                name="gst"
                key={
                  customerDetails?.gst_numbers?.length !== 0
                    ? customerDetails?.gst_numbers?.[0]
                    : "DEFAULT"
                }
                defaultValue={
                  customerDetails?.gst_numbers?.length !== 0
                    ? customerDetails?.gst_numbers?.[0]
                    : "DEFAULT"
                }
                id="gstNo"
                className={Classes.select}
                onChange={(e) => handleGst(e)}
              >
                <option value="DEFAULT" disabled={true}>
                  Select GSTno
                </option>

                {customerDetails?.gst_numbers?.length !== 0 && (
                  <>
                    {customerDetails?.gst_numbers?.map((gst, index) => (
                      <option value={gst} key={index}>
                        {gst}
                      </option>
                    ))}
                  </>
                )}

                <option value="None">None</option>
              </select>
            </div>

            {customerDetails?.gst_numbers?.length !== 0 && (
                <div>
                  <p
                    onClick={showRemoveGstPopup}
                    className={Classes.addNewGstNo}
                    style={{ marginTop: 10, marginLeft: 5 }}
                  >
                    Remove GST
                  </p>
                </div>
              )}
          </div>
        ) : (
          <div className={Classes.containerBox}>
            <div className="d-flex justify-content-between mb-2">
              <h6 style={{ margin: "auto 0px", marginLeft: 5 }}>
                Select Customer To Add GST No
              </h6>
            </div>
          </div>
        )}
      </div>

      {customerDetails && (
        <div
          className="gstNumber"
          style={{ display: "none" }}
          id="createOrderGstBox"
        >
          {gstNumber?.length !== 0 && (
            <div
              className={[Classes.Box, "box"].join(" ")}
              style={{
                padding: "10px 1.1rem",
                margin: "0",
              }}
            >
              <h6 style={{ margin: "5px 0px" }}>GST Number</h6>
              <p className={Classes.colorFontSize}>{gstNumber}</p>
            </div>
          )}
        </div>
      )}

      {customerDetails && (
        <div className={Classes.Box} id="createOrderAddressBox">
          {/* Either no addresses or no addresses for searched keyword but have already selected some address */}
          {addressArray?.length !== 0 || selectedShippingAddress !== null ? (
            <div
              style={{ borderBottom: "1px solid #DBDDE0" }}
              id="createOrderShippingAddressBox"
            >
              <div className={Classes.containerBox}>
                <div className={["mb-3", Classes.flexBetween].join(" ")}>
                  <p className={Classes.contactInformation}>SHIPPING ADDRESS</p>
                  {selectedShippingAddress !== null && (
                    <p
                      className={[
                        "removeAddressOnPrint",
                        Classes.contactEditBtn,
                      ].join(" ")}
                      id="shipping"
                      onClick={(e) => showUpdateShippingPop(e)}
                    >
                      Edit address
                    </p>
                  )}
                </div>

                {selectedShippingAddress !== null ? (
                  <div style={{ fontSize: "14px" }}>
                    {selectedShippingAddress?.nick_name &&
                    selectedShippingAddress?.nick_name !== "null" ? (
                      <p>{selectedShippingAddress?.nick_name}</p>
                    ) : null}

                    <div style={{ display: "flex" }}>
                      <p>
                        {selectedShippingAddress?.firstName +
                          " " +
                          (selectedShippingAddress?.lastName === undefined
                            ? ""
                            : selectedShippingAddress?.lastName)}
                      </p>
                    </div>

                    <div className={Classes.flexDirectionColumn}>
                      <p style={{ marginRight: "5px" }}>
                        {selectedShippingAddress?.addressLine1}
                      </p>
                      {(selectedShippingAddress?.addressLine2 !== null ||
                        selectedShippingAddress?.addressLine2 !== "null") && (
                        <p>{selectedShippingAddress?.addressLine2}</p>
                      )}
                    </div>

                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "5px" }}>
                        {selectedShippingAddress?.pincode}
                      </p>
                      <p style={{ marginRight: "5px" }}>
                        {selectedShippingAddress?.city},
                      </p>
                    </div>

                    <div>
                      <p>{selectedShippingAddress?.state?.toTitleCase()},</p>
                    </div>
                    <div>
                      <p>{selectedShippingAddress?.country}</p>
                    </div>
                    <div>
                      <p>{selectedShippingAddress?.phoneNumber}</p>
                    </div>
                  </div>
                ) : null}

                <div
                  className={[
                    "removeAddressOnPrint",
                    Classes.selectAddressBtn,
                  ].join(" ")}
                >
                  <p onClick={showDefaultAddressPopUp}>Select address</p>
                  <p id="shipping" onClick={showShippingPop}>
                    Add new address
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ borderBottom: "1px solid #DBDDE0" }}>
              <div style={{ padding: "1.1rem" }}>
                <p
                  className={["mb-2", Classes.shippingAddressHeader].join(" ")}
                >
                  SHIPPING ADDRESS
                </p>
                <p
                  id="shipping"
                  className={Classes.contactEditBtn}
                  onClick={(e) => showShippingPop(e)}
                >
                  Please enter shipping address
                </p>
              </div>
            </div>
          )}

          {/* Either no addresses or no addresses for searched keyword but have already selected some address */}
          {addressArray?.length !== 0 || selectedBillingAddress !== null ? (
            <div
              style={{ borderBottom: "1px solid #DBDDE0" }}
              id="createOrderBillingAddressBox"
            >
              <div className={Classes.containerBox}>
                <div className={["mb-3", Classes.flexBetween].join(" ")}>
                  <p className={Classes.contactInformation}>BILLING ADDRESS</p>
                  {selectedBillingAddress !== null && (
                    <p
                      className={[
                        "removeAddressOnPrint",
                        Classes.contactEditBtn,
                      ].join(" ")}
                      onClick={showUpdateBillingPop}
                      id="billing"
                    >
                      Edit Address
                    </p>
                  )}
                </div>

                {selectedBillingAddress !== null ? (
                  <div style={{ fontSize: "14px" }}>
                    <div style={{ fontSize: "14px" }}>
                      {selectedBillingAddress?.nick_name &&
                      selectedBillingAddress?.nick_name !== "null" ? (
                        <p>{selectedBillingAddress?.nick_name}</p>
                      ) : null}
                      <div style={{ display: "flex" }}>
                        <p style={{ marginRight: "5px" }}>
                          {selectedBillingAddress?.firstName +
                            " " +
                            (selectedBillingAddress?.lastName === undefined
                              ? ""
                              : selectedBillingAddress?.lastName)}
                        </p>
                      </div>

                      <div>
                        <p style={{ marginRight: "5px" }}>
                          {selectedBillingAddress?.addressLine1}
                        </p>
                      </div>

                      <div>
                        {(selectedBillingAddress?.addressLine2 !== null ||
                          selectedBillingAddress?.addressLine2 !== "null") && (
                          <p>{selectedBillingAddress?.addressLine2}</p>
                        )}
                      </div>

                      <div style={{ display: "flex" }}>
                        <p style={{ marginRight: "5px" }}>
                          {selectedBillingAddress?.pincode}
                        </p>
                        <p style={{ marginRight: "5px" }}>
                          {selectedBillingAddress?.city},
                        </p>
                      </div>

                      <div>
                        <p>
                          {selectedBillingAddress?.state?.toTitleCase()},
                        </p>
                      </div>

                      <div>
                        <p>{selectedBillingAddress?.country}</p>
                      </div>
                      <div>
                        <p>{selectedBillingAddress?.phoneNumber}</p>
                      </div>

                      <div className={Classes.selectAddressBtn}>
                        <p
                          className="removeAddressOnPrint"
                          onClick={showdefaultBillingAddressPop}
                        >
                          Select Address
                        </p>
                        <p id="billing" onClick={showShippingPop}>
                          Add new address
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={[
                      "removeAddressOnPrint",
                      Classes.selectAddressBtn,
                    ].join(" ")}
                  >
                    <p onClick={showdefaultBillingAddressPop}>Select address</p>
                    <p id="billing" onClick={showShippingPop}>
                      Add new address
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div style={{ borderBottom: "1px solid #DBDDE0" }}>
              <div style={{ padding: "1.1rem" }}>
                <p
                  className={["mb-3", Classes.shippingAddressHeader].join(" ")}
                >
                  BILLING ADDRESS
                </p>
                {selectedShippingAddress !== null ? (
                  <div>
                    <div>
                      <p style={{ color: "#6c757d" }}>
                        Same as shipping address
                      </p>
                    </div>

                    <div className={Classes.AddNewAddressBtn}>
                      <p id="billing" onClick={showShippingPop}>
                        Add new address
                      </p>
                    </div>
                  </div>
                ) : (
                  <p
                    id="billing"
                    className={Classes.contactEditBtn}
                    onClick={(e) => showShippingPop(e)}
                  >
                    Please enter billing address
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      <div className={Classes.Box} id="removeRolePrint">
        <div className={Classes.containerBox}>
          <div className="d-flex justify-content-between mb-2">
            <h6 style={{ margin: "auto 0px" }}>Select Role</h6>
            <div style={{ fontSize: 12, fontWeight: 600 }}>
              <p>Apply Discount Based on Role</p>
            </div>
          </div>

          <div className={Classes.fontSize}>
            <select
              name="role"
              id="selectToggle"
              defaultValue={selectedRoleForDiscount}
              value={selectedRoleForDiscount}
              className={Classes.select}
              onChange={(e) => setSelectedRoleForDiscount(e?.target?.value as CustomerRoles)}
              disabled={true}
            >
              <option value="DEFAULT" disabled={true}>
                Select role
              </option>
              {fetchCustomerRoleDB?.map((role) => (
                <option key={role?._id} value={role?.title}>
                  {capitalName(role.title)}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className={Classes.Box} id="removeNotesPrint">
        <div className={Classes.containerBox}>
          <div className="mb-3">
            <h6 className={Classes.remarkHeader}>Delivery Remark</h6>
          </div>

          <div id="deliveryRemarkContainer">
            <div className={Classes.deliveryRemark}>
              <div
                contentEditable={true}
                suppressContentEditableWarning={true}
                id="deliveryRemark"
                // value={deliveryRemark}
                onKeyDown={(e) => handleEdit(e as HandleEditEvent)}
                onBlur={(e) => handleBlur(e)}
                className={Classes.Span}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div className={Classes.Box} id="removeNotesPrint">
        <div className={Classes.containerBox}>
          <div className="mb-3">
            <h6 className={Classes.remarkHeader}>Payment Remark</h6>
          </div>

          {/* <div id='paymentRemarkContainer'>
            <div className={Classes.deliveryRemark}>
              <div
                contentEditable={true}
                suppressContentEditableWarning={true}
                id='paymentRemark'
                value={paymentRemark}
                onKeyDown={(e) => handleEdit(e)}
                onBlur={(e) => handleBlur(e)}
                className={Classes.Span}
              ></div>
            </div>
          </div> */}
          <div className={Classes.fontSize}>
            <select
              name="payment"
              id="selectPaymentToggle"
              defaultValue={paymentRemark || undefined}
              value={paymentRemark || undefined}
              className={Classes.select}
              onChange={(e) => setPaymentRemark(e?.target?.value)}
            >
              <option value="" disabled={true}>
                Select Payment Remark
              </option>
              {paymentRemarks?.map((paymentRemark, index) => (
                <option key={index} value={paymentRemark?.remark}>
                  {capitalName(paymentRemark.remark)}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {customerDetails && (
        <div
          className={Classes.Box}
          style={{ padding: "20px 18px" }}
          id="affiliateDetails"
        >
          <div
            className="mb-3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h6 className={Classes.remarkHeader}>Affiliate Details</h6>
            <div
              style={{
                color: "#2C6ECB",
                fontSize: "14px",
                cursor: "pointer",
                alignItems: "center",
              }}
            >
              <p onClick={showSalesDialog}>Create Sales User</p>
            </div>
          </div>
          <div style={{ display: "flex", margin: "8px 0px" }}>
            <Autocomplete
              disablePortal
              id="affiliate"
              options={affiliateList.map((affiliate) => ({
                label: affiliate?.display_name,
                Id: `${affiliate?.id}`,
              }))}
              value={
               affiliateUser || null
              }
              sx={{ width: 300 }}
              onChange={(event, newValue) => {
                if (newValue === null) {
                  setAffiliateUser(null);
                  return;
                }
                setAffiliateUser(newValue);
              }}
              isOptionEqualToValue={(option, value) => option.Id === value.Id}
              renderInput={(params) => (
                <TextField {...params} label="Affilate" />
              )}
            />
          </div>

          <div style={{ display: "flex", margin: "8px 0px" }}>
            <Autocomplete
              disablePortal
              id="salesUser"
              options={salesList as unknown as string[] || [] as string[]}
              key={salesUser ? salesUser?.label : ""}
              defaultValue={salesUser ? salesUser?.label : ""}
              sx={{ width: 300 }}
              onChange={(event, newValue) => {
                if (newValue === null) {
                  setSalesUser(null);
                  return;
                }
                setSalesUser(newValue as unknown as AutoCompleteOption);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Sales User" />
              )}
            />
          </div>

          <div style={{ display: "flex", margin: "8px 0px" }}>
            <Autocomplete
              disablePortal
              id="preSalesUser"
              options={salesList as unknown as string[] || [] as string[]}
              key={preSalesUser ? preSalesUser?.label : ""}
              defaultValue={
                preSalesUser ? preSalesUser?.label : ""
              }
              sx={{ width: 300 }}
              onChange={(event, newValue) => {
                if (newValue === null) {
                  setPreSalesUser(null);
                  return;
                }
                setPreSalesUser(newValue as unknown as AutoCompleteOption);
              }}
              renderInput={(params) => (
                <TextField {...params} label="PreSale User" />
              )}
            />
          </div>
        </div>
      )}

      <div className={Classes.Box} id="removeTagsPrint">
        <div className={Classes.containerBox}>
          <div className="d-flex justify-content-between mb-3">
            <h6 className={Classes.remarkHeader}>Tags</h6>
            <div>
              {showSelectedTags ? (
                <p onClick={showTagsPopup} className={Classes.TagsBtn}>
                  Change Tags
                </p>
              ) : (
                <p onClick={showTagsPopup} className={Classes.TagsBtn}>
                  Add New Tags
                </p>
              )}
            </div>
          </div>

          <div>
            <div className={Classes.TagsInput}>
              <input
                type="text"
                id="search"
                className={Classes.searchField}
                placeholder="Find or create tags"
                value={tagInputValue}
                onChange={(e) => setTagInputValue(e.target.value)}
              />
            </div>
            <div className={Classes.addTagBox}>
              <div className={Classes.addTagButton} onClick={handleAddTag}>
                Add Tag
              </div>
            </div>
          </div>
          <div>
            {showSelectedTags && (
              <div className={Classes.selectedTagsBox}>
                {showSelectedTags?.map((tag, index) => (
                  <p key={index} className={Classes.selectedTags}>
                    {tag}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className={showSalesModal ? Classes.UpdateSalesDialog : undefined}>
          <NewSalesUser
            showModal={showSalesModal}
            hideDialog={hideSalesDialog}
            userName={userName}
            setUserName={setUserName}
            name={name}
            setName={setName}
            phoneNo={phoneNo}
            setPhoneNo={setPhoneNo}
            customerType={customerType}
            setCustomerType={setCustomerType}
            password={password}
            setPassword={setPassword}
            fetchCustomers={handleSalesList}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Aside;
