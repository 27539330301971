import { useEffect } from "react";
import { fetchAffiliateList } from "../store/affiliates";
import { useAppDispatch, useAppSelector } from "./hooks";

const useAffiliate = () => {
  const dispatch = useAppDispatch(); // Type the dispatch function
  const affiliateList = useAppSelector(
    (state) => state.affiliates.affiliateList
  );

  useEffect(() => {
    dispatch(fetchAffiliateList());
  }, [dispatch]);

  return { affiliateList };
};

export { useAffiliate };
